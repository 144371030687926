import React from 'react'
import { RenderSchema } from '../../utils/FormRenderer'
import { BusinessCodeSchema  } from '../../utils/FormSchemas'
import Icon from '@mdi/react'
import { mdiDomain } from '@mdi/js'

const BusinessCodeCard = (props)  => {
    const { corporates, action } = props
	return (
        <div className='WBackground Border BorderRadius' style={{marginBottom: 48}}>
            <div className={`FlexRow GHBackground BorderBottom PaddingLeftRight`} style={{height: 48}}>
                <Icon path={mdiDomain} size={1} className='PrimaryColor HalfMarginRight'/>
                <p className='BoldFont'>Client's Information</p>
            </div>
            <div className='Padding'>
                {RenderSchema(BusinessCodeSchema({corporates, action}))}
            </div>
        </div>
	)
}

export default BusinessCodeCard