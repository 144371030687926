import { atom } from 'recoil'

export const userAtom = atom({ key: 'userAtom', default: {} })

export const clientsAtom = atom({ key: 'clientsAtom', default: [] })
export const corporatesAtom = atom({ key: 'corporatesAtom', default: [] })
export const regionsAtom = atom({ key: 'regionsAtom', default: [] })
export const parentRegionAtom = atom({ key: 'parentRegionAtom', default: [] })
export const corporateLocationAtom = atom({ key: 'corporateLocationAtom', default: [] })

export const shippingLinesAtom = atom({ key: 'shippingLinesAtom', default: [] })
export const terminalsAtom = atom({ key: 'terminalsAtom', default: [] })
export const portsAtom = atom({ key: 'portsAtom', default: [] })

export const pendingOrdersAtom = atom({ key: 'pendingOrdersAtom', default: [] })
export const unassignedOrdersAtom = atom({ key: 'unassignedOrdersAtom', default: [] })
export const allOrdersAtom = atom({ key: 'allOrdersAtom', default: [] })

export const dummyAtom = atom({ key: 'dummyAtom', default: null })
