// REACT
import React, { useState, useMemo, useEffect } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'

// DESIGN
import { Table, Layout } from 'antd'

// UTILS
import { ClientColumn, OrderDetailsColumn, PendingOrderShipmentInfoColumn } from '../utils/TableColumns'
import { pathLocation, Loader } from '../utils/Functions'
import { PageSizes } from '../utils/Enums'

// COMPONENTS
import SearchHeader from '../components/layout/SearchHeader'
import Pagination from '../components/utility/Pagination'
import StatsCard from '../components/StatsCard'

// RECOIL
import { useRecoilValue } from 'recoil'
import { corporatesAtom } from '../recoil/Atoms'
import { DynamicTableSelector } from '../recoil/Selectors'

//HOOKS
import useFetchQuery from '../hooks/useFetchQuery'

// ERROR BOUNDARY
import { ErrorBoundary } from 'react-error-boundary'
import FallBackUI from '../components/errors/FallBackUI'

const { Content, Header, Footer } = Layout

const PendingOrdersTable = (props) => {
	const {pathname} = useLocation()
	const collection = useMemo(() => pathLocation(pathname, 2), [pathname])
    const navigate = useNavigate()

	const [keyword, setKeyword] = useState('')
	const [pageInfo, setPageInfo] = useState({ pageNo: 1, pageSize: 10 })
	const [searchData, setSearchData] = useState({ data: [], collection: '' })
	const [backendPageInfo, setBackendPageInfo] = useState({ pageNo: 1, pageSize: PageSizes[pageInfo.pageSize].backend })

	const corporates = useRecoilValue(corporatesAtom)

	const { totalCount, collectionData } = useRecoilValue(
		DynamicTableSelector({
			state: 'pending',
			segment: 'Import',
			status: 'Order Pending',
			pageNo: pageInfo?.pageNo,
			pageSize: pageInfo?.pageSize,
			keyword: keyword,
			filterProperty: ['orderNumber', 'documentNumber', 'containerNumber'],
		})
	)

	useFetchQuery({ segment: 'Corporates', state: 'normal', subscription: true })
	const queryResult = useFetchQuery({
		segment: 'Import',
		backendPageInfo,
		state: 'pending',
		subscription: true,
		variables: { segment: 'Import', status: 'Order Pending' },
		subscriptionVariables: { status: ['Order Pending', 'Order Confirmed', 'Order Cancelled'], type: 'Import' },
	})

	useEffect(() => {
		setKeyword('')
	}, [collection]) // eslint-disable-line

	useEffect(() => {
		setSearchData({ data: collectionData, collection: collection })
	}, [collectionData, keyword]) // eslint-disable-line

	useEffect(() => {
		setBackendPageInfo({ pageNo: 1, pageSize: PageSizes[pageInfo.pageSize].backend })
	}, [pageInfo.pageSize]) // eslint-disable-line

	return (
		<Layout>
			<Header className='NoPadding'>
				<div className='FlexRow HalfPadding GHBackground BorderBottom'>
					{StatsCard({ key: 'pending', title: 'Pending Import Orders', collection, value: totalCount || 0 })}
				</div>
			</Header>
			<Layout className='WBackground'>
				<SearchHeader keyWord={keyword} setKeyword={setKeyword} placeholder='Search by Order, BL or Container Number' />
				{queryResult?.loading ? (
					<Content className='FullHeight FlexRowBox'>
						<Loader />
					</Content>
				) : (
					<Content>
						<ErrorBoundary
							fallbackRender={({ error, resetErrorBoundary }) => <FallBackUI component={'Table'} error={error} resetErrorBoundary={resetErrorBoundary} />}
						>
							<Table
								columns={[OrderDetailsColumn, ClientColumn({ corporates }), PendingOrderShipmentInfoColumn]}
								dataSource={searchData.data}
								rowKey={(record) => record._id}
								onRow={(record) => ({
									onClick: () =>
										navigate(`/home/orders/Import/${record._id}/edit`,
											{ state: { record: record, segment: 'Import', businessCode: record?.businessCode },
										}),
								})}
								scroll={{ y: 'calc(100% - 38px)' }}
								pagination={false}
								className='MainTable'
								size='small'
								bordered
							/>
						</ErrorBoundary>
						<Footer className='FooterPadding'>
							<Pagination pageInfo={pageInfo} setPageInfo={setPageInfo} setBackendPageInfo={setBackendPageInfo} totalCount={totalCount}></Pagination>
						</Footer>
					</Content>
				)}
                <Outlet
                    context={{
                        type: 'createOrder'
                    }}
                />
			</Layout>
		</Layout>
	)
}

export default PendingOrdersTable
