// React
import React, { useEffect, useState, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// DESIGN
import { Layout, Form, Button } from 'antd'

// Recoil
import { useRecoilValue } from 'recoil'
import { regionsAtom } from '../../recoil/Atoms'
import { CorporatesSelector } from '../../recoil/Selectors'

// Components
import BusinessCodeCard from '../../components/corporates/BusinessCodeCard'
import LocationsCard from '../../components/corporates/LocationsCard'
import ClearingAgentsCard from '../../components/corporates/ClearingAgentsCard'
import FinancialCard from '../../components/corporates/FinancialCard'

// Utils
import { formatContacts, prepareContacts } from '../../utils/Functions'

// Hooks
import useMutate from '../../hooks/useMutate'

// Services
import { mutateGraphQL } from '../../services/MutateGraphQl'
import useFetchQuery from '../../hooks/useFetchQuery'

const cleanDeep = require('clean-deep')

const { Header, Content } = Layout

const Corporate = (props) => {
    const { state } = useLocation()
	const action = useMemo(() => state?.action, [state?.action])
    const navigate = useNavigate()

	const [loading, setLoading] = useState(false)
	const [locations, setLocations] = useState([])
	const corporates = useRecoilValue(CorporatesSelector)
	const regions = useRecoilValue(regionsAtom)

	const createCorporateMutation = useMutate({ segment: 'Corporates', action: 'create' })
	const updateCorporateMutation = useMutate({ segment: 'Corporates', action: 'edit' })
	const createLocationMutation = useMutate({ segment: 'Locations', action: 'create' })
	const { regionLoading } = useFetchQuery({ segment: 'ParentRegions', state: 'normal' })

	const [form] = Form.useForm()

	useEffect(() => {
		if (action === 'edit') {
			prepareInitialValues(state?.record, regions)
			setLoading(false)
		}
	}, []) // eslint-disable-line

	const prepareInitialValues = (record, newRegions) => {
		if (record?.CorporateLocations?.length > 0) {
			const addresses = record?.CorporateLocations?.map((corpLocation) => {
				const address = newRegions.find((value) => value?._id === corpLocation?.Location?.Region?._id)

				const contacts = formatContacts(corpLocation?.contacts) || [{ name: '', phoneNumber: '' }]
				return { ...corpLocation?.Location, contacts, subRegion: address?.name, subRegionId: address?._id, parentRegion: address?.ParentRegion?.name }
			})
			setLocations(addresses)
		}
		const clearingAgents = formatContacts(record?.clearingAgents)
		form.setFieldsValue({ ...record, clearingAgents })
	}

	const onFinish = async (values) => {
		setLoading(true)
		if (action === 'add') {
			mutateGraphQL({
				mutateFunction: createCorporateMutation.mutateFunction,
				mutationInputFunction: createCorporateMutation?.mutationInputFunction,
				mutationOutputFunction: createCorporateMutation?.mutationOutputFunction,
				mutationData: { businessName: values.businessName },
			})
		} else if (action === 'edit') {
			const record = state?.record
			const cleanedObject = cleanDeep(values)
			const clearingAgents = prepareContacts(values.clearingAgents) || []
			const mappedLocations = locations?.map((location) => ({ ...location, contacts: prepareContacts(location?.contacts) }))
			const newLocations = mappedLocations?.filter((location) => !location?._id)
			const oldMappedLocations = mappedLocations
				?.filter((location) => location?._id)
				?.map((location) => ({
					LocationId: location._id,
					contacts: location?.contacts,
				}))
			let newMappedLocations = []
			if (newLocations?.length > 0) {
				for (let i = 0; i < newLocations.length; i++) {
					const location = newLocations[i]
					const LocationId = await mutateGraphQL({
						mutateFunction: createLocationMutation?.mutateFunction,
						mutationInputFunction: createLocationMutation?.mutationInputFunction,
						mutationOutputFunction: createLocationMutation?.mutationOutputFunction,
						mutationData: {
							formattedAddress: location?.formattedAddress,
							additionalDetails: location?.additionalDetails,
							coordinates: location?.coordinates,
							RegionId: location?.subRegionId,
						},
					})
					newMappedLocations?.push({
						LocationId: LocationId?._id,
						contacts: location?.contacts,
					})
				}
			}
			const apolloData = await mutateGraphQL({
				mutateFunction: updateCorporateMutation?.mutateFunction,
				mutationInputFunction: updateCorporateMutation?.mutationInputFunction,
				mutationOutputFunction: updateCorporateMutation?.mutationOutputFunction,
				mutationData: {
					_id: record._id,
					...(cleanedObject?.clearingAgents && { clearingAgents }),
					...(cleanedObject?.invoicingDetails && { invoicingDetails: values?.invoicingDetails }),
					CorporateLocations: [...oldMappedLocations, ...newMappedLocations],
				},
			})
			// console.log('apolloData', apolloData)
		}
		setLoading(false)
		navigate('/home/corporates')
	}

	return (
		<Layout>
			<Header className='FlexRowSpace PaddingLeftRight' style={{ height: 56 }}>
				{action === 'edit' ? (
					<p>
						<span className='BoldFont SecondaryShadeColor'>{state?.record?.businessName}</span>
						<span className='FontSize12'> ({state?.record?.businessCode})</span>
					</p>
				) : (
					<p className='BoldFont SecondaryShadeColor'>New Client</p>
				)}
				<Button className='Button SuccessButton' onClick={() => form.submit()} loading={loading}>
					SUBMIT
				</Button>
			</Header>
			<Content className='FullWidthHeight PaddingExceptBottom OverflowScroll' style={{ background: '#F2F3F7' }}>
				<div className='FlexColumnBox'>
					<Form
						layout='vertical'
						form={form}
						className='HalfWidth'
						onFinish={onFinish}
						// onValuesChange={changedValues => onFormValuesChange(changedValues)}
						scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
					>
						{BusinessCodeCard({ corporates, action })}
						{action === 'edit' && LocationsCard({ locations, setLocations, setLoading })}
						{action === 'edit' && ClearingAgentsCard({ form })}
						{action === 'edit' && FinancialCard()}
					</Form>
				</div>
			</Content>
		</Layout>
	)
}

export default Corporate
