import React from 'react'

import { RenderSchema } from '../../utils/FormRenderer'
import { MiscellaneousSchema } from '../../utils/FormSchemas'

const MiscellaneousCard = (props) => {
	const { segment, form } = props
	return (
		<>
			<p className='GrayColor HalfMarginBottom'>Miscellaneous</p>
			<div className='WBackground Padding Border BorderRadius' style={{ marginBottom: 48 }}>
				{RenderSchema(MiscellaneousSchema({ segment, form }))}
			</div>
		</>
	)
}

export default MiscellaneousCard
