import React from 'react'
import { RenderSchema } from '../../utils/FormRenderer'
import { FinancialSchema  } from '../../utils/FormSchemas'
import Icon from '@mdi/react'
import { mdiCurrencyUsd } from '@mdi/js'

const FinancialCard = (props)  => {
	return (
        <div className='WBackground Border BorderRadius' style={{marginBottom: 48}}>
            <div className={`FlexRow GHBackground BorderBottom PaddingLeftRight`} style={{height: 48}}>
                <Icon path={mdiCurrencyUsd} size={1} className='PrimaryColor HalfMarginRight'/>
                <p className='BoldFont'>Financial Information</p>
            </div>
            <div className='Padding'>
                {RenderSchema(FinancialSchema())}
            </div>
        </div>
	)
}

export default FinancialCard