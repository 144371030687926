import React, { useState } from 'react'
import { Button } from 'antd'
import Icon from '@mdi/react'
import { mdiMapMarker, mdiPencilOutline, mdiPlus } from '@mdi/js'

import LocationModal from './LocationModal'

const LocationsCard = (props) => {
	const { locations, setLocations, setLoading } = props
	const [modalData, setModalData] = useState({ visible: false, data: locations, setData: setLocations })

	const LocationsModalButton = ({ index, location, action }) => {
		const addClasses = { height: 48, color: '#0066CC', backgroundColor: '#E5F2FF', border: '1px solid #0066CC' }
		const editClasses = { height: 72, color: '#z33333', backgroundColor: '#F2F2F2', border: '1px solid #808080' }
		return (
			<Button
				className='FlexRow ModalButton NinetyTenWidth'
				style={location ? editClasses : addClasses}
				onClick={() => setModalData({ visible: true, data: locations, setData: setLocations, targetIndex: index, action, setLoading: setLoading })}
				block
			>
				{location ? (
					<div className='FullWidth FlexRowSpace'>
						<div className='FlexColumn FontSize12 OverflowEllipsis' style={{ alignItems: 'flex-start' }}>
							<p className='BoldFont'>{location.formattedAddress}</p>
							<p>{location.additionalDetails}</p>
						</div>
						<Icon path={mdiPencilOutline} size={1} className='HalfMarginLeft' />
					</div>
				) : (
					<div className='FullWidth FlexRowSpace'>
						<div className='BoldFont FontSize12'>Add New Location</div>
						<Icon path={mdiPlus} size={1} className='PrimaryColor' />
					</div>
				)}
			</Button>
		)
	}

	return (
		<>
			<div className='WBackground Border BorderRadius' style={{ marginBottom: 48 }}>
				<div className={`FlexRow GHBackground BorderBottom PaddingLeftRight`} style={{ height: 48 }}>
					<div className={`FlexRowBox HalfMarginRight CircleRadius PBackground`} style={{ width: 16, height: 16 }}>
						<Icon path={mdiMapMarker} size={0.5} className='WhiteColor' />
					</div>
					<p className='BoldFont'>Client Locations</p>
				</div>
				<div className='Padding'>
					{locations?.map((location, index) => LocationsModalButton({ index, location, action: 'edit' }))}
					{LocationsModalButton({ action: 'add' })}
				</div>
			</div>
			<LocationModal modalData={modalData} setModalData={setModalData} />
		</>
	)
}

export default LocationsCard
