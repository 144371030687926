import moment from 'moment'
import { mutateGraphQL } from '../services/MutateGraphQl'
import { ContainerDimensionsEnum, VehicleTypeEnumLongHaul, GraphQlEnum } from './Enums'
import { Spin } from 'antd'
import firebase from 'firebase/compat/app'

/* ============================================================================================== */
/* ===================================== UTILITY FUNCTIONS ====================================== */
/* ============================================================================================== */
export const checkArray = (props) => props?.constructor === Array
export const pathLocation = (path, index) => path.split('/')[index]
export const readableString = (string) => string?.replace(/([A-Z])/g, ' $1')
export const capitalize = (string) => string[0].toUpperCase() + string.slice(1)
export const getDate = (time) => (time ? moment(time, 'x').format('DD-MM-YYYY') : '')
export const get12HourDate = (time) => (time ? moment(time, 'x').format('DD-MM-YYYY hh:mm A') : '')
export const get24HourDate = (time) => (time ? moment(time, 'x').format('DD-MM-YYYY HH:mm') : '')
export const Loader = () => <Spin className='Spinner' tip={<p className='BoldFont FontSize16 PrimaryColor MarginTop'>Loading...</p>} size='large' />
export function range(start, end) {
	let array = new Array(end - start + 1)
	for (let i = 0; i < end - start + 1; i++) array[i] = start + i
	return array
}
export const groupByProperty = (array, property) => {
	return array.reduce((previous, current) => {
		if (!previous[current[property]]) {
			previous[current[property]] = []
		}
		previous[current[property]].push(current)
		return previous
	}, {})
}

export const groupContainers = (array) => {
	return array.reduce((previous, current) => {
		if (!previous[current[0]?.dimension]) {
			previous[current[0]?.dimension] = []
		}
		previous[current[0]?.dimension].push(current)
		return previous
	}, {})
}

export const CardHeader = (title, description) => (
	<div className='FlexColumn'>
		<p className='BoldFont FontSize14 PrimaryColor'>{title}</p>
		{description && <p className='FontSize12 GrayColor'>{description}</p>}
	</div>
)

/* ============================================================================================= */
/* ===================================== COMMON FUNCTIONS ====================================== */
/* ============================================================================================= */
export const formatContacts = (contacts, action) => {
	return contacts?.length > 0 && contacts?.map((contact) => formatContact(contact, action))
}
export const formatContact = (contact, action) => {
	return { ...(action === 'edit' && { _id: contact?._id }), name: contact?.name, phoneNumber: contact?.phoneNumber?.replace('+92', '') }
}

export const prepareContacts = (contacts) => {
	return contacts?.length > 0 && contacts?.map((contact) => prepareContact(contact))?.filter(Boolean)
}
export const prepareContact = (contact) => {
	if (contact.name && contact.phoneNumber) {
		const contactPhone = contact.phoneNumber?.replace('+92', '')
		return { name: contact.name, phoneNumber: `+92${contactPhone}` }
	}
}

export const formatAddresses = (addresses, contactRequired = true, action) => {
	return addresses?.map((address) => ({
		...address,
		contacts: formatContacts(address?.contacts, action) || (contactRequired ? [{ name: '', phoneNumber: '' }] : []),
	}))
}

/* ============================================================================================= */
/* ================================= CLIENT SUMMARY FUNCTIONS ================================== */
/* ============================================================================================= */
export const getClientSummary = (data) => {
	const { segment, corporate, values, specialRequests } = data || {}
	const loading = segment === 'Import' ? [values?.loading] : values?.loading
	const dropoff = segment === 'Export' ? [values?.dropoff] : values?.dropoff
	const grossCargoWeight = Object.values(values?.containerInfo || {})
		?.flat()
		?.reduce((prev, curr) => prev + parseInt(curr?.freightWeight), 0)
	return ['Import', 'Export'].includes(segment)
		? [
				{ key: 'clientName', title: 'Client Name', value: corporate?.businessName },
				{ key: 'docNumber', title: segment === 'Import' ? 'BL Number' : 'CRO Number', value: values?.documentNumber },
				// ...(values?.document ? [{key: 'document', title: segment==='Import' ? 'BL Document' : 'CRO Document', value: values?.document}] : []),
				{
					key: 'loading',
					title: 'Loading Point',
					type: 'multiple',
					value: loading?.map((location) => `${location.formattedAddress} (${location.additionalDetails})`),
				},
				{
					key: 'dropoff',
					title: 'Dropoff Point',
					type: 'multiple',
					value: dropoff?.map((location) => `${location.formattedAddress} (${location.additionalDetails})`),
				},
				{
					key: 'clearingAgents',
					title: 'Clearing Agents',
					type: 'multiple',
					value: values?.clearingAgents?.map((agent) => `${agent.name} (+92${agent.phoneNumber})`),
				},
				{
					key: 'pointofContact',
					title: `Point of Contact (${segment === 'Import' ? 'Dropoff' : 'Loading'})`,
					type: 'multiple',
					value: values?.[segment === 'Import' ? 'dropoff' : 'loading']?.contacts?.map((agent) => `${agent.contactName} (+92${agent.contactPhone})`),
				},
				{ key: 'freightType', title: 'Cargo Type', value: values?.freightType },
				{ key: 'grossFreightWeight', title: 'Gross Cargo Weight (Tons)', value: grossCargoWeight },
				{
					key: 'containerInfo',
					title: 'Containers Info',
					type: 'multiple',
					value: getContainersSummary(values?.specialRequests?.reefer, values?.specialRequests?.orderCategory, values?.containerInfo, segment),
				},
				{ key: 'flatbeds', title: 'Flatbeds', type: 'multiple', value: values?.flatbedInfo ? getFlatbedsSummary(values?.flatbedInfo) : [] },
		  ]
		: [
				{ key: 'clientName', title: 'Client Name', value: corporate?.businessName },
				{
					key: 'loading',
					title: 'Loading Point',
					type: 'multiple',
					value: loading?.map((location) => `${location.formattedAddress} (${location.additionalDetails})`),
				},
				{
					key: 'dropoff',
					title: 'Dropoff Point',
					type: 'multiple',
					value: dropoff?.map((location) => `${location.formattedAddress} (${location.additionalDetails})`),
				},
				{ key: 'freightType', title: 'Cargo Type', value: values?.shipmentInfo?.freightType },
				{ key: 'freightWeight', title: 'Cargo Weight', value: `${values?.shipmentInfo?.freightWeight} Tons` },
				{
					key: 'vehicle',
					title: 'Vehicle Information',
					value: `${VehicleTypeEnumLongHaul[values?.vehicleInfo?.vehicleType]?.text}`,
				},
				{
					key: 'rate',
					title: 'Rate',
					value: `PKR ${values?.pricingInfo?.finalizedRate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} (per ${
						values?.pricingInfo?.finalizedRateUnit || (specialRequests?.lotOrder ? 'Tonne' : 'Vehicle')
					})`,
				},
		  ]
}

export const getContainersSummary = (reefer, orderCategory, containerInfo, segment) => {
	const summary = []
	const containerType = reefer ? ' Reefer' : ''
	if (!['Shifting', 'Destuffing'].includes(orderCategory)) {
		Object.entries(containerInfo)?.forEach(([dimension, containerInfo]) => {
			Object.entries(groupByProperty(containerInfo, 'finalizedRate'))?.forEach(([finalizedRate, slabContainers]) => {
				const sortedSlab = slabContainers?.sort((a, b) => (a.freightWeight || 0) - (b.freightWeight || 0))
				const minWeight = parseInt(sortedSlab?.[0]?.freightWeight) || 0
				const maxWeight = parseInt(sortedSlab.slice(-1)[0]?.freightWeight) || 0
				summary.push({
					count: slabContainers.length,
					dimension: ContainerDimensionsEnum(segment)[dimension]?.text,
					finalizedRate: parseInt(finalizedRate),
					minWeight,
					maxWeight,
				})
			})
		})
		const ratesSummary = []
		summary?.forEach((slab) => {
			const { count, dimension, finalizedRate, maxWeight, minWeight } = slab
			const finalizedRateText = finalizedRate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			if (finalizedRate === 1) {
				ratesSummary.push(`${count} X ${dimension}${containerType}`)
			} else if (minWeight !== 0 && maxWeight !== 0 && minWeight !== maxWeight) {
				ratesSummary.push(`${count} X ${dimension}${containerType} (${minWeight}-${maxWeight} Tons) - @ PKR ${finalizedRateText}`)
			} else if (minWeight !== 0 && maxWeight !== 0 && minWeight === maxWeight) {
				ratesSummary.push(`${count} X ${dimension}${containerType} (${maxWeight} Tons) - @ PKR ${finalizedRateText}`)
			} else {
				ratesSummary.push(`${count} X ${dimension}${containerType} - @ PKR ${finalizedRateText}`)
			}
		})
		return ratesSummary
	} else {
		return Object.entries(containerInfo)?.map(
			([dimension, containerInfo]) => `${containerInfo.length} X ${ContainerDimensionsEnum(segment)[dimension]?.text}`
		)
	}
}

export const getFlatbedsSummary = (flatbedsInfo) => {
	const summary = []
	Object.entries(groupByProperty(flatbedsInfo, 'finalizedRate'))?.forEach(([finalizedRate, slabContainers]) => {
		const sortedSlab = slabContainers?.sort((a, b) => (a.freightWeight || 0) - (b.freightWeight || 0))
		const minWeight = parseInt(sortedSlab?.[0]?.freightWeight) || 0
		const maxWeight = parseInt(sortedSlab.slice(-1)[0]?.freightWeight) || 0
		summary.push({ count: slabContainers.length, finalizedRate: parseInt(finalizedRate), minWeight, maxWeight })
	})
	const ratesSummary = []
	summary?.forEach((slab) => {
		const { count, finalizedRate, maxWeight, minWeight } = slab
		const flatbedText = count > 1 ? 'Flatbeds' : 'Flatbed'
		const finalizedRateText = finalizedRate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		if (minWeight !== 0 && maxWeight !== 0 && minWeight !== maxWeight) {
			ratesSummary.push(`${count} ${flatbedText} (${minWeight}-${maxWeight} Tons) - @ PKR ${finalizedRateText}`)
		} else if (minWeight !== 0 && maxWeight !== 0 && minWeight === maxWeight) {
			ratesSummary.push(`${count} ${flatbedText} (${maxWeight} Tons) - @ PKR ${finalizedRateText}`)
		} else {
			ratesSummary.push(`${count} ${flatbedText} - @ PKR ${finalizedRateText}`)
		}
	})
	return ratesSummary
}

/* ============================================================================================= */
/* ================================== CREATE ORDER FUNCTIONS =================================== */
/* ============================================================================================= */

export const createOrder = async (props) => {
	const { orderMutation, segment } = props
	const order = getBaseOrder(props)
	const resultData = await mutateGraphQL({
		mutationData: order,
		mutateFunction: orderMutation?.mutateFunction,
		mutationInputFunction: orderMutation?.mutationInputFunction,
		mutationOutputFunction: orderMutation?.mutationOutputFunction,
	})
	return resultData?.ParentOrder?.[GraphQlEnum[segment]?.parentType]?.orderNumber
}
export const cancelOrder = async (props) => {
	const { values, cancelMutation } = props
	const cancelResult = await mutateGraphQL({
		mutationData: {
			_id: values?._id,
			type: values?.segment,
		},
		mutateFunction: cancelMutation?.mutateFunction,
		mutationInputFunction: cancelMutation?.mutationInputFunction,
		mutationOutputFunction: cancelMutation?.mutationOutputFunction,
	})
	return cancelResult?.ParentOrder?.[`Parent${values?.segment}`]?.orderNumber
}

const getBaseOrder = ({ clientEmail, segment, action, values, corporate, ports }) => {
	const specialRequests = values?.specialRequests
	const loading = segment === 'Import' ? [values?.loading] : values?.loading
	const dropoff = segment === 'Export' ? [values?.dropoff] : values?.dropoff
	const loadingDT = values?.loadingDT && moment(values?.loadingDT)?.format('x')
	const freeDays = values?.terminal?.freeDays && moment(values?.loadingDT)?.add(values?.terminal?.freeDays, 'days')?.format('x')
	const emptyContainer = [values?.terminal]?.flat()
	const isShiftingOrder = ['Shifting', 'Destuffing'].includes(values?.specialRequests?.orderCategory)

	const orderLocations = prepareOrderLocations({
		loading,
		dropoff,
		emptyContainer,
		segment,
		action,
		ports,
		orderCategory: specialRequests?.orderCategory,
	})

	const vehicleInfos = isShiftingOrder
		? values?.vehicleDetails?.map((vehicle) => ({
				...(action === 'edit' && {
					_id: vehicle?._id,
				}),
				quantity: vehicle?.quantity,
				quotedRate: vehicle?.quotedRate,
				vehicleType: vehicle?.vehicleType,
				quotedRateUnit: vehicle?.quotedRateUnit,
				weightRange: vehicle?.minWeight && vehicle?.maxWeight ? `${vehicle?.minWeight}-${vehicle?.maxWeight}` : null,
		  }))
		: segment !== 'LongHaul' && prepareVehicleDetails({ containersSpecifics: values?.containersSpecifics, specialRequests: values?.specialRequests })

	const containerInfo = prepareContainerInfo({
		action,
		segment,
		values,
		loadingDT: loadingDT,
		freeDays: freeDays,
		updatedLocations: orderLocations,
	}).flat()

	const totalContainers = segment === 'LongHaul' ? 0 : containerInfo?.length
	const totalVehicles = segment === 'LongHaul' ? 0 : vehicleInfos?.reduce((prev, curr) => prev + parseInt(curr?.quantity), 0)

	const order =
		action === 'create'
			? // TODO: Add Production ID for client
			  {
					...(segment !== 'LongHaul'
						? {
								clearingAgents: values?.clearingAgents,
								containerInfos: containerInfo,
								VehicleInfos: vehicleInfos,
								shippingLine: values?.shipmentInfo?.shippingLine,
								documentNumber: values?.shipmentInfo?.documentNumber,
								...(values?.shipmentInfo?.document && values?.shipmentInfo?.document?.[0]?.url && { document: values?.shipmentInfo?.document?.[0]?.url }),
								...(segment === 'Export' && values?.vesselCutoffDT && { vesselCutoffDT: moment(values?.vesselCutoffDT).format('x') }),
								...(segment === 'Export' &&
									values?.clientCommittedDeliveryDate && { clientCommittedDeliveryDate: moment(values?.clientCommittedDeliveryDate).format('x') }),
								...(segment === 'Import' && values?.terminal?.freeDays && { freeDays: freeDays }),
								...(segment === 'Import' && values?.flatbedInfo && { flatBedInfos: values?.flatbedInfo }),
								...(segment === 'Import' &&
									values?.clientCommittedReturnDeadline && { committedReturnDeadline: moment(values?.clientCommittedReturnDeadline).format('x') }),
								...(segment === 'Import' && values?.eirSubmissionDeadline && { eir: { submissionDeadline: moment(values?.eirSubmissionDeadline).format('x') } }),
						  }
						: {
								VehicleInfos: {
									vehicleType: !values?.specialRequests?.lotOrder ? `Trolly-${values?.vehicleInfo?.vehicleType}` : values?.vehicleInfo?.vehicleType,
									quantity: values?.specialRequests?.numberVehicles?.toString() || null,
									quotedRate: values?.pricingInfo?.vehicleCost,
									quotedRateUnit: values?.specialRequests?.lotOrder ? 'Tonne' : 'Vehicle',
								},
								expectedMovementDeadline: moment(values?.specialRequests?.movementCutoffDT).format('x'),
								...(values?.pricingInfo?.finalizedRate && {
									finalizedRate: values?.pricingInfo?.finalizedRate,
									finalizedRateUnit: values?.specialRequests?.lotOrder ? 'Tonne' : 'Vehicle',
								}),
								vehicleType: values?.vehicleInfo?.vehicleType,
								vehicleSubType: values?.vehicleInfo?.vehicleSubtype,
								shipmentInfo: {
									...values?.shipmentInfo,
									freightWeight: values?.specialRequests?.lotOrder
										? values?.shipmentInfo?.freightWeight
										: (values?.shipmentInfo?.freightWeight * values?.specialRequests?.numberVehicles)?.toString(),
								},
						  }),
					...(values.supplyQuote?.amount &&
						values?.supplyQuote?.source && {
							supplyQuote: values?.supplyQuote,
						}),
					businessCode: corporate.businessCode,
					ClientId:
						values?.clientId || (process.env.REACT_APP_PROJECT_ID === 'bridgelinx-dev' ? '151cb54d-9b5f-4562-839b-774dbd89f11d' : '9Rgk8Kwn3Kv3x2b710jl'),
					clientEmails: clientEmail,
					OrderLocations: orderLocations,
					createdThrough: { userPlatform: 'Web - Intake Portal' },
					...(values?.loadingDT && { loadingDT: loadingDT }),
					preferences: preparePreferences({ segment, totalContainers, totalVehicles, specialRequests: values?.specialRequests }),
			  }
			: action === 'edit' && {
					_id: values._id,
					...(['Import', 'Export'].includes(segment) && {
						VehicleInfos: isShiftingOrder
							? vehicleInfos
							: updateVehicleDetails({ vehicleInfos: values?.vehicleInfos, containersSpecifics: values?.containersSpecifics }),
						shippingLine: values?.shipmentInfo?.shippingLine,
						documentNumber: values?.shipmentInfo?.documentNumber,
						...(values?.shipmentInfo?.document && values?.shipmentInfo?.document?.[0]?.url
							? { document: values?.shipmentInfo?.document?.[0]?.url }
							: { document: '' }),
						clearingAgents: values?.clearingAgents.map((value) => ({ _id: value?._id, name: value?.name, phoneNumber: value?.phoneNumber })),
						...(segment === 'Export' && values?.vesselCutoffDT && { vesselCutoffDT: moment(values?.vesselCutoffDT).format('x') }),
						...(segment === 'Import' && values?.flatbedInfo && { flatBedInfos: values?.flatbedInfo }),
					}),
					...(segment === 'LongHaul' && {
						expectedMovementDeadline: moment(values?.specialRequests?.movementCutoffDT).format('x'),
						VehicleInfos: values?.vehicleInfos?.map((vehicle) => ({
							...vehicle,
							vehicleType: !values?.specialRequests?.lotOrder ? `Trolly-${values?.vehicleInfo?.vehicleType}` : values?.vehicleInfo?.vehicleType,
							quotedRate: values?.pricingInfo?.vehicleCost,
						})),
						shipmentInfo: {
							freightType: values?.shipmentInfo?.freightType,
							freightWeight: !values?.shipmentInfo?.lotOrder
								? values?.vehicleSpecifics
										?.map((vehicle) => vehicle?.freightWeight)
										?.reduce((prev, curr) => parseFloat(curr) + parseFloat(prev), 0)
										?.toString()
								: values?.shipmentInfo?.freightWeight?.toString(),
						},
					}),
					...(values.supplyQuote?.amount && values?.supplyQuote?.source && { supplyQuote: values?.supplyQuote }),
					...(specialRequests?.refNumber && {
						preferences: {
							refNumber: specialRequests?.refNumber,
						},
					}),
					[`Child${segment}s`]: containerInfo,
			  }
	return order
}

const prepareContainerInfo = (props) => {
	const { segment, action, values, loadingDT, freeDays, updatedLocations } = props
	const {
		containerInfo,
		subOrders = [],
		vehicleSpecifics = {},
		containersSpecifics = {},
		pricingInfo,
		specialRequests,
		clientCommittedDeliveryDate,
		clientCommittedReturnDeadline,
		eirSubmissionDeadline,
	} = values

	const shiftingOrder = ['Shifting', 'Destuffing'].includes(specialRequests?.orderCategory)
	const allContainerEntries = ['Import', 'Export']?.includes(segment) ? Object.values(containerInfo).flat() : vehicleSpecifics

	let doubleTwentyContainers = containersSpecifics?.['xl2020ft']?.quantity * 2
	return Object.values(allContainerEntries)?.map((container, index) => {
		const orderLocations = subOrders[index]?.OrderLocations
		// console.log('orderLocations', orderLocations)
		const newLocations = orderLocations
			?.map((oldLocation) => {
				const updatedLocation = updatedLocations.find(
					(newLocation) => newLocation?.locationType === oldLocation?.locationType && newLocation?.steps === oldLocation?.steps
				)

				return updatedLocation?.LocationId
					? {
							_id: oldLocation?._id,
							LocationId: updatedLocation?.LocationId || null,
							...(updatedLocation?.contacts && { contacts: updatedLocation?.contacts }),
					  }
					: null
			})
			?.filter((value) => value)

		if (specialRequests?.doubleTwenty && container?.dimension === 'xl20ft') {
			doubleTwentyContainers = doubleTwentyContainers - 1
		}
		const containerDetails = {
			...(action === 'edit' && {
				_id: container?._id,
				...(newLocations?.length > 0 && { OrderLocations: newLocations }),
				...(loadingDT && { loadingDT: loadingDT }),
				...(segment === 'Import' && freeDays && { freeDays: freeDays }),
				...(segment === 'LongHaul' && {
					finalizedRate: specialRequests?.lotOrder ? container?.finalizedRate : pricingInfo?.finalizedRate,
					finalizedRateUnit: specialRequests?.lotOrder ? 'Tonne' : 'Vehicle',
					childShipmentInfo: {
						freightType: container?.freightType,
						freightWeight: container?.freightWeight ? container?.freightWeight : '0',
					},
				}),
			}),
			...(['Import', 'Export']?.includes(segment) && {
				...(container?.containerNumber && { containerNumber: container?.containerNumber }),
				dimension: container?.dimension,
				freightType: container?.freightType,
				...(segment === 'Import' ? { numberOfUnits: container?.numberOfUnits || null } : {}),
				freightWeight: container?.freightWeight ? container?.freightWeight.toString() : '0',
				finalizedRate: shiftingOrder
					? pricingInfo?.finalizedRate
					: action === 'create'
					? containersSpecifics?.[doubleTwentyContainers >= 0 ? 'xl2020ft' : container?.dimension]?.finalizedRate
					: container?.finalizedRate,
				quotedDoCost: containersSpecifics[container?.dimension]?.doCost,
				quotedLabourCost: containersSpecifics[container?.dimension]?.labourCost,
				quotedShiftingCost: containersSpecifics[container?.dimension]?.shiftingCost,
				finalizedRateUnit: shiftingOrder ? pricingInfo?.finalizedRateUnit : 'Container',
				...(clientCommittedReturnDeadline &&
					action === 'edit' && {
						committedReturnDeadline: moment(clientCommittedReturnDeadline).format('x'),
					}),
				...(eirSubmissionDeadline &&
					action === 'edit' && {
						eir: {
							submissionDeadline: moment(eirSubmissionDeadline).format('x'),
						},
					}),
				...(clientCommittedDeliveryDate &&
					action === 'edit' && {
						clientCommittedDeliveryDate: moment(clientCommittedDeliveryDate).format('x'),
					}),
			}),
		}
		return [containerDetails]
	})
}

const updateVehicleDetails = (props) => {
	const { vehicleInfos, containersSpecifics } = props
	return vehicleInfos?.map((vehicle) => ({ ...vehicle, quotedRate: containersSpecifics[vehicle?.vehicleType?.split('-')?.[1]]?.vehicleCost }))
}
const prepareVehicleDetails = (props) => {
	const { containersSpecifics = null, specialRequests = null } = props
	const vehicleDetails = []
	Object.keys(containersSpecifics)?.forEach((dimension) => {
		if (containersSpecifics[dimension]?.quantity > 0) {
			const containerQuantity =
				dimension === 'xl20ft' && specialRequests?.doubleTwenty
					? containersSpecifics[dimension]?.quantity - containersSpecifics['xl2020ft']?.quantity * 2
					: containersSpecifics[dimension]?.quantity
			const vehicle = {
				vehicleType: `Trolly-${dimension}`,
				quantity: containerQuantity?.toString(),
				quotedRate: containersSpecifics[dimension]?.vehicleCost,
				quotedRateUnit: 'Container',
			}
			vehicleDetails?.push(vehicle)
		}
	})
	return vehicleDetails
}

const preparePreferences = (props) => {
	const { segment, specialRequests, totalContainers, totalVehicles } = props
	return {
		gstInclusive: specialRequests?.gstInclusive,
		...(segment === 'Import' && { doIssuance: specialRequests?.doIssuance }),
		...(specialRequests?.refNumber && { refNumber: specialRequests?.refNumber }),
		...(specialRequests?.partialMatch && { partialMatch: specialRequests?.partialMatch }),
		...(['Shifting', 'Destuffing'].includes(specialRequests?.orderCategory) && {
			numberFlatbeds: specialRequests?.orderCategory === 'Shifting' ? totalContainers : totalVehicles,
		}),
		...(specialRequests?.numberVehicles && { numberVehicles: specialRequests?.numberVehicles?.toString() }),
		...(['LongHaul'].includes(segment) && { lotOrder: specialRequests?.lotOrder }),
		...(['Import', 'Export'].includes(segment) && {
			movementType: specialRequests?.reefer
				? 'Reefer'
				: ['Shifting', 'Destuffing'].includes(specialRequests?.orderCategory)
				? 'ImportShifting'
				: specialRequests?.doubleTwenty
				? 'DoubleTwenty'
				: specialRequests?.orderCategory
				? specialRequests?.orderCategory
				: segment,
		}),
	}
}

const prepareOrderLocations = (props) => {
	let { loading, dropoff, emptyContainer, segment, action, ports, orderCategory } = props
	let index = 0

	loading = segment === 'Import' ? loading?.map((loading) => ({ ...loading, _id: ports[loading?.additionalDetails]?._id })) : loading
	dropoff = segment === 'Export' ? dropoff?.map((dropoff) => ({ ...dropoff, _id: ports[dropoff?.additionalDetails]?._id })) : dropoff

	return [
		...(segment === 'Export'
			? emptyContainer?.map((location) => ({
					LocationId: location?._id,
					locationType: 'Empty Container Pickup',
					contacts: location?.contacts,
					steps: ++index,
			  }))
			: []),
		...loading?.map((location) => {
			return {
				LocationId: location?._id,
				locationType: 'loading',
				contacts: location?.contacts,
				steps: ++index,
			}
		}),
		...(['Shifting', 'Destuffing'].includes(orderCategory) && action === 'edit'
			? [
					{
						LocationId: null,
						locationType: 'Yard',
						steps: ++index,
					},
			  ]
			: []),
		...dropoff?.map((location) => ({
			LocationId: location?._id,
			locationType: 'dropoff',
			contacts: location?.contacts,
			steps: ++index,
		})),
		...(segment === 'Import'
			? [
					{
						LocationId: emptyContainer?.[0]?._id,
						locationType: 'Empty Container Dropoff',
						steps: ++index,
					},
			  ]
			: []),
	]
}

/* ============================================================================================== */
/* ==================================== CORPORATE LOCATIONS ===================================== */
/* ============================================================================================== */
export function getZoomLevel(bounds) {
	var WORLD_DIM = { height: 256, width: 256 }
	var ZOOM_MAX = 21

	function latRad(lat) {
		var sin = Math.sin((lat * Math.PI) / 180)
		var radX2 = Math.log((1 + sin) / (1 - sin)) / 2
		return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
	}

	function zoom(mapPx, worldPx, fraction) {
		return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
	}

	var ne = bounds.getNorthEast()
	var sw = bounds.getSouthWest()

	var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI

	var lngDiff = ne.lng() - sw.lng()
	var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

	var latZoom = zoom(450, WORLD_DIM.height, latFraction)
	var lngZoom = zoom(450, WORLD_DIM.width, lngFraction)

	return Math.min(latZoom, lngZoom, ZOOM_MAX)
}

export const coordinatesDistance = (c1, c2) => {
	const deg2rad = (deg) => deg * (Math.PI / 180)
	let R = 6371000
	let dLat = deg2rad(c2.lat - c1.lat)
	let dLng = deg2rad(c1.lng - c2.lng)
	let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(c1.lat)) * Math.cos(deg2rad(c2.lat)) * Math.sin(dLng / 2) * Math.sin(dLng / 2)
	let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
	let d = R * c
	return d
}

export const getVicinityRegions = (regions, place) => {
	let foundComponent = {}
	const subRegions = regions?.filter((region) => region?.ParentRegion?._id)
	// console.log('subRegions', subRegions)
	for (let h = 0; h < place?.address_components?.length; h++) {
		let component = place?.address_components[h]
		for (let i = 0; i < subRegions?.length; i++) {
			// console.log('address_components', place)
			if ((component?.long_name).includes(subRegions?.[i]?.name)) {
				foundComponent = subRegions?.[i]
				// console.log('foundComponent', foundComponent)
				return foundComponent
			}
		}
	}
}

export const setLocationInfo = (form, coordinates) => {
	// const coordinates = inputMethod === 'drag' ? place?.geometry?.location : { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
	// const formattedAddress = formatted_address
	// const selectedVicinity = getVicinityRegions(regions, place)

	// console.log('coordinates', coordinates)
	form?.setFieldsValue({
		coordinates,
		// formattedAddress,
		// subRegion: selectedVicinity?.name,
		// subRegionId: selectedVicinity?._id,
		// parentRegion: selectedVicinity?.ParentRegion?.name,
	})
}

/* ============================================================================================== */
/* ================================== Search Functions ========================================== */
/* ============================================================================================== */

export const searchFields = (data, keyword, filterProperty) => {
	if (keyword.length > 0) {
		if (typeof filterProperty === 'object') {
			/* eslint-disable */
			const AllPropertiesData = filterProperty.map((prop) => {
				if (typeof prop === 'string') {
					return data.filter((singleObj) => {
						if (singleObj[prop]) {
							return (singleObj[prop] + '').toLowerCase().includes(keyword.toLowerCase())
						}
					})
				} else if (typeof prop === 'object') {
					return data.filter((singleObj) => filterObject(singleObj, prop, 0))
				}
			})
			let FilteredData = []
			for (let i = 0; i < AllPropertiesData.length; i++) {
				FilteredData = FilteredData.concat(AllPropertiesData[i])
			}
			const UniqueFilteredData = [...new Set(FilteredData)]
			return UniqueFilteredData
		} else {
			const FilteredData = data.filter((singleObj) => {
				if (singleObj[filterProperty]) {
					return (singleObj[filterProperty] + '').toLowerCase().includes(keyword.toLowerCase())
				}
			})
			return FilteredData
		}
	} else {
		return data
	}
}

export const filterObject = (singleObj, prop, index, keyword) => {
	let flag = false
	if (singleObj[prop[index]] !== null && singleObj[prop[index]] !== undefined) {
		if (typeof singleObj[prop[index]] === 'object') {
			flag = filterObject(singleObj[prop[index]], prop, index + 1)
		} else if (typeof singleObj[prop[index]] === 'string' || typeof singleObj[prop[index]] === 'number') {
			return (singleObj[prop[index]] + '').toLowerCase().includes(keyword.toLowerCase())
		}
	}
	return flag
}

/* ============================================================================================== */
/* ================================== Subscriptipon Function ==================================== */
/* ============================================================================================== */

export const updateData = (prev, subscriptionData, segment, atomName, setAtom) => {
	// console.log('subscriptionData', subscriptionData)
	const segmentQuery = GraphQlEnum[segment]?.fetch.queryOutput
	const segmentSubscription = GraphQlEnum[segment]?.subscriptionOutput
	const order = subscriptionData?.data?.[segmentSubscription]
	let orders = []
	let existing = {}

	Object.assign(existing, prev)
	Object.assign(orders, prev[segmentQuery])

	// console.log('order', order)
	const index = existing[segmentQuery]?.findIndex((value) => value?._id === order?._id)
	// console.log('index', index)
	// console.log('totalOrdersBefore', orders?.length)
	if (
		(atomName === 'pendingOrdersAtom' && order?.ParentOrder?.[GraphQlEnum[segment]?.parentType]?.status === 'Order Pending') ||
		('unassignedOrdersAtom' === atomName && order?.ParentOrder?.[GraphQlEnum[segment]?.parentType]?.status === 'Order Confirmed') ||
		atomName === 'corporatesAtom' ||
		atomName === 'allOrdersAtom' ||
		order?.ParentOrder?.[GraphQlEnum[segment]?.parentType]?.status !== 'Order Cancelled'
	) {
		if (index !== -1) {
			orders[index] = order
			// console.log('overwriteOrder', existing)
			// console.log('totalOrdersAfter', orders?.length)
		} else {
			orders.push(order)
			// console.log('addedOrder', existing)
			// console.log('totalOrdersAfter', orders?.length)
		}
	} else if (
		(atomName === 'pendingOrdersAtom' && order?.ParentOrder?.[GraphQlEnum[segment]?.parentType]?.status === 'Order Confirmed') ||
		('unassignedOrdersAtom' === atomName && order?.ParentOrder?.[GraphQlEnum[segment]?.parentType]?.status === 'Order Ongoing') ||
		order?.ParentOrder?.[GraphQlEnum[segment]?.parentType]?.status === 'Order Cancelled'
	) {
		if (index !== -1) {
			orders?.splice(index, 1)
			// console.log('removedOrder', existing)
			// console.log('totalOrdersAfter', orders?.length)
		}
	}
	existing[segmentQuery] = orders
	setAtom(existing[segmentQuery])
	return existing
}

export const retryCatch = async ({ delay = 0, maxRetries = 0, executionFunction }) => {
	let count = 0
	while (true) {
		try {
			const token = JSON.parse(localStorage.getItem('token'))?.token
			const response = await executionFunction(token)
			if (response?.status === 200 || response?.status === 201) {
				return Promise.resolve(response)
			} else {
				throw Error(response)
			}
		} catch (error) {
			count += 1
			if (count === maxRetries) {
				return Promise.reject(error)
			} else {
				const token = await firebase.auth().currentUser?.getIdToken()
				localStorage.setItem('token', JSON.stringify({ token }))
				await new Promise((r) => setTimeout(r, delay))
				continue
			}
		}
	}
}
