import React from 'react'
import { Divider } from 'antd'
import { RenderSchema } from '../../utils/FormRenderer'
import { ContainerSchema } from '../../utils/FormSchemas'
import { ContainerDimensionsEnum } from '../../utils/Enums'

const ContainerDetailsCard = (props) => {
	const { segment, action, form, containers, containerCounter, SDorder } = props
	const containerInfo = form?.getFieldValue(['containerInfo'])
	return (
		<>
			<p className='GrayColor HalfMarginBottom'>Add Container Details</p>
			<div className='WBackground Border BorderRadius' style={{ marginBottom: 48 }}>
				{Object.values(ContainerDimensionsEnum(segment))
					?.filter((container) => containerCounter[container.key] > 0)
					?.map((container) => (
						<div key={container?.key}>
							<div className='FlexRow GHBackground BorderRadiusTop BorderBottom PaddingLeftRight' style={{ height: 48 }}>
								<p className='BoldFont BlackColor'>
									{container?.long}
									<span className='RegularFont'>Container Details</span>
								</p>
							</div>
							{[...Array(containerCounter[container.key])].map((_, index, array) => (
								<div className='PaddingExceptBottom'>
									{action === 'edit' && <div className='BoldFont FontSize16 QuarterPaddingBottom'>{containerInfo?.[container.key]?.[index]?.subOrderNumber}</div>}
									{RenderSchema(ContainerSchema({ segment, form, action, containers, dimension: container?.key, index, SDorder }))}
									{array.length > 0 && index + 1 < array.length && <Divider className='NoMargin' dashed />}
								</div>
							))}
						</div>
					))}
			</div>
		</>
	)
}

export default ContainerDetailsCard
