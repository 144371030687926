import React from 'react'
import { Form, Input, Select, DatePicker, Upload, Divider, Radio, Cascader, Checkbox, Button } from 'antd'
import { PaperClipOutlined, CaretDownOutlined, PlusCircleTwoTone, MinusCircleTwoTone } from '@ant-design/icons'
import { storage } from '../firebase/firebaseConfig'
import { checkArray, setLocationInfo } from '../utils/Functions'
import Icon from '@mdi/react'
import { Autocomplete } from '@react-google-maps/api'
import moment from 'moment'

const normFile = (e) => {
	if (Array.isArray(e)) {
		return e
	}
	return e && e.fileList
}

export const FormRender = (field) => {
	const DocumentUpload = async ({ file, onProgress, onSuccess, onError }) => {
		field.setLoading && field.setLoading(true)
		const response = storage.ref().child(`public/images/${field.collection}/${field.uploadLink}/${field.name}/${file.name}`).put(file)
		response.on(
			'state_changed',
			(snapshot) => onProgress({ percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100 }),
			(error) => onError(error),
			() => onSuccess(null, response.metadata_)
		)
	}

	const ChangeFileList = async ({ fileList }) => {
		if (fileList.length > 0) {
			fileList.forEach((file, index) => {
				if (!file.url && file.status === 'done') {
					const response = storage.ref().child(`public/images/${field.collection}/${field.uploadLink}/${field.name}/${file.name}`)
					response.getDownloadURL().then((result) => {
						fileList[index].url = result
						field.setLoading && field.setLoading(false)
					})
				}
			})
		} else {
			field.setLoading && field.setLoading(false)
		}
	}

	if (field.type === 'input') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				className={field.itemClassName}
				name={field.name}
				rules={[
					{
						required: field.required,

						...(field.inputType === 'email'
							? {
									validator: (_, value = '') => {
										if (value?.toString().length > 0) {
											if (/^[A-Za-z0-9]*$/.test(value?.toString())) {
												return Promise.resolve()
											} else {
												return Promise.reject(new Error(`Email format is invalid.`))
											}
										} else if (!field.required) {
											return Promise.resolve(value)
										} else {
											return Promise.reject(new Error(field.message))
										}
									},
							  }
							: undefined),
						...(field.inputType !== 'email' && { message: field.message }),
					},
				]}
				tooltip={field.tooltip}
				initialValue={field.initialValue}
				hidden={field.hidden}
			>
				{field.inputType === 'textArea' ? (
					<Input.TextArea className='InputField TextAreaField' rows={field.rows} />
				) : (
					<Input
						className='InputField AddOn'
						type={field.inputType !== 'email' ? field.inputType : undefined}
						placeholder={field.placeholder}
						disabled={field.action === 'view' || field.disabled === true ? true : false}
						addonBefore={field.addonBefore}
						addonAfter={field.addonAfter}
					/>
				)}
			</Form.Item>
		)
	} else if (field.type === 'number') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				rules={[
					{
						required: field.required,
						validator: (_, value = '') => {
							if (value?.toString().length > 0) {
								if (value < 0) {
									return Promise.reject(new Error(`This value cannot be negative.`))
								} else if (parseInt(value) < field.minValue) {
									return Promise.reject(new Error(`This value cannot be less than ${field.minValue}`))
								} else if (parseInt(value) > field.maxValue) {
									return Promise.reject(new Error(`This value cannot be greater than ${field.maxValue}`))
								} else {
									return Promise.resolve()
								}
							} else if (!field.required) {
								return Promise.resolve(value)
							} else {
								return Promise.reject(new Error(field.message))
							}
						},
					},
				]}
				tooltip={field.tooltip}
				initialValue={field.initialValue}
				shouldUpdate={field.shouldUpdate || false}
			>
				<Input
					className={field.addonBefore || field.addonAfter ? `InputField ${field.className}` : ` InputField ${field.className}`}
					type='number'
					disabled={field.action === 'view' || field.disabled === true ? true : false}
					addonBefore={field.addonBefore}
					addonAfter={field.addonAfter}
					onWheel={(event) => event.currentTarget.blur()}
				/>
			</Form.Item>
		)
	} else if (field.type === 'password') {
		return (
			<Form.Item
				key={field.name}
				name={field.name}
				label={field.label}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
				initialValue={field.initialValue}
			>
				<Input.Password
					className='InputField'
					addonAfter={field.addonAfter}
					addonBefore={field.addonBefore}
					disabled={field.disabled}
					placeholder={field.placeholder}
					type={field.inputType}
				/>
			</Form.Item>
		)
	} else if (field.type === 'phoneNumber') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				rules={[
					{
						required: field.required,
						validator: (rule, value = '') => {
							if (value.toString().length > 0) {
								if (value < 0) {
									return Promise.reject(new Error(`Phone Number cannot be negative.`))
								} else if (value.toString().length !== 10) {
									return Promise.reject(new Error(`Contact must be of 10 digits.`))
								} else {
									return Promise.resolve()
								}
							} else if (!field.required) {
								return Promise.resolve(value)
							} else {
								return Promise.reject(new Error(field.message))
							}
						},
					},
				]}
				tooltip={field.tooltip}
			>
				<Input
					className='InputField AddOn'
					// type='number'
					onInput={(e) => (e.target.value = e.target.value.replace(/[^\d]/g, '').slice(0, 10))}
					disabled={field.action === 'view' || field.disabled === true ? true : false}
					addonBefore={field.addonBefore}
					addonAfter={field.addonAfter}
				/>
			</Form.Item>
		)
	} else if (field.type === 'upload') {
		const UploadProps = {
			listType: 'picture',
			maxCount: field.maxCount,
			multiple: field.multiple,
			accept: 'application/pdf, image/jpg, image/png, image/jpeg',
			className: 'FullWidth FlexColumnBox',
			customRequest: DocumentUpload,
			onChange: ChangeFileList,
			onPreview: (file) => file?.url && window.open(file?.url),
		}
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
				valuePropName='fileList'
				getValueFromEvent={normFile}
			>
				{field.uploadType === 'button' ? (
					<Upload {...UploadProps} style={{ width: 'auto' }}>
						<Button className='Button PHButton' style={{ height: 48 }} block>
							<div className='FlexRow'>
								{field?.icon ? (
									<Icon path={field?.icon} size={1} className='PrimaryColor' />
								) : (
									<p className='NoMargin'>
										<PaperClipOutlined style={{ fontSize: 24, color: '#0066CC' }} />
									</p>
								)}
								<p className='BoldFont FontSize12 PrimaryColor' style={{ marginLeft: 12 }}>
									UPLOAD
								</p>
							</div>
						</Button>
					</Upload>
				) : (
					<Upload.Dragger {...UploadProps}>
						<p className='NoMargin'>
							<PaperClipOutlined style={{ fontSize: 36, color: '#0066CC' }} />
						</p>
						<p className='SectionTitle'>Upload Document</p>
					</Upload.Dragger>
				)}
			</Form.Item>
		)
	} else if (field.type === 'radioGroup') {
		return (
			<Form.Item
				key={field.label}
				name={field.name}
				label={field.label}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
				initialValue={field.initialValue}
			>
				{field.button === true ? (
					<Radio.Group
						disabled={field.disabled}
						onChange={(e) => {
							if (field.clearData) {
								field.formReference.setFieldsValue(field.clearData)
							}
						}}
						buttonStyle='solid'
					>
						{field.options?.map((option, index) =>
							field.valueProperty && field.displayProperty ? (
								<Radio.Button key={`radio-${field.name}-${index}`} value={option[field.valueProperty]}>
									{option[field.displayProperty]}
								</Radio.Button>
							) : (
								<Radio.Button key={option} value={option}>
									{option}
								</Radio.Button>
							)
						)}
					</Radio.Group>
				) : (
					<Radio.Group disabled={field.disabled}>
						{field.options.map((option) => (
							<Radio key={option} value={option.key}>
								{option.label}
							</Radio>
						))}
					</Radio.Group>
				)}
			</Form.Item>
		)
	} else if (field.type === 'dateTime') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
				dependencies={field.dependencies}
				initialValue={field.initialValue}
			>
				<DatePicker
					className='InputField'
					disabled={field.disabled === true ? true : false}
					disabledDate={field.disabledDate}
					disabledTime={field.disabledTime}
					format={field.format}
					showNow={field.showNow}
					defaultPickerValue={field.defaultPickerValue}
					showTime={field.showTime !== false ? { defaultValue: field.defaultTimeValue } : false}
				/>
			</Form.Item>
		)
	} else if (field.type === 'dateRange') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				className={field.itemClassName}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
				noStyle={field.noStyle}
			>
				<DatePicker.RangePicker className='TypeField' disabledDate={(current) => (field.disableFutureDates ? current > moment.now() : false)} />
			</Form.Item>
		)
	} else if (field.type === 'select') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				className={field.itemClassName}
				rules={[{ required: field.required, message: field.message }]}
				initialValue={field.initialValue}
				tooltip={field.tooltip}
				noStyle={field.noStyle}
			>
				<Select
					className='SelectField'
					suffixIcon={() => <CaretDownOutlined />}
					mode={field.mode}
					disabled={field.action === 'view' || field.disabled === true}
					showArrow={field.showArrow}
					filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					showSearch={field.showSearch}
					placeholder={field.placeholder}
					maxTagCount='responsive'
					tokenSeparators={field.tokenSeparators}
					allowClear={field.allowClear}
				>
					{field.options?.map((option, index) =>
						field.valueProperty && field.displayProperty ? (
							<Select.Option key={`select-${field.name}-${index}`} value={option[field.valueProperty]}>
								{option[field.displayProperty]}
							</Select.Option>
						) : (
							<Select.Option key={option} value={option}>
								{option}
							</Select.Option>
						)
					)}
				</Select>
			</Form.Item>
		)
	} else if (field.type === 'recentSelect') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
			>
				<Select
					className='SelectField'
					suffixIcon={() => <CaretDownOutlined />}
					filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					showSearch={field.showSearch}
					placeholder={field.placeholder}
					onChange={(event) => {
						if ([...field?.name].includes('terminal')) {
							const setField = field.setField
							const parentField = field.form?.getFieldValue(setField[0])
							parentField[setField[1]] = { _id: event }
							field.form?.resetFields([setField[0]])
							field.form?.setFieldsValue({ [setField[0]]: parentField })
						} else if (field.displayProperty === 'formattedAddress') {
							const setField = field.setField
							const parentField = field.form?.getFieldValue(setField[0])
							parentField[setField[1]] = JSON.parse(event)
							// parentField[setField[1]] = {...parentField[setField[1]], ...JSON.parse(event)}
							field.form?.resetFields([setField[0]])
							field.form?.setFieldsValue({ [setField[0]]: parentField })
						} else if (field.valueProperty !== 'containerNumber') {
							field.form?.setFieldsValue({ [field.setField]: { ...JSON.parse(event) } })
						}
					}}
					allowClear={field.allowClear}
				>
					{field.options?.map((option, index) =>
						[...field?.name].includes('terminal') ? (
							<Select.Option key={index} value={option[field.valueProperty]}>
								{option[field.displayProperty]}
							</Select.Option>
						) : (
							<Select.Option key={index} value={JSON.stringify(option)}>
								{option[field.displayProperty]}
							</Select.Option>
						)
					)}
				</Select>
			</Form.Item>
		)
	} else if (field.type === 'emails') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				tooltip={field.tooltip}
				rules={[
					{
						required: true,
						validator: (_, value) => {
							const expression = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) //eslint-disable-line
							if (value) {
								if (expression.test(value[value.length - 1])) {
									return Promise.resolve()
								} else {
									return Promise.reject(new Error('Please enter valid emails.'))
								}
							} else {
								return Promise.reject(new Error(field.message))
							}
						},
					},
				]}
				initialValue={field.initialValue}
				style={{ margin: 0 }}
			>
				<Select
					className='EmailsField'
					mode={field.mode}
					disabled={field.disabled}
					showSearch={field.showSearch}
					placeholder={field.placeholder}
					maxTagCount='responsive'
					tokenSeparators={field.tokenSeparators}
					onChange={(value) => {
						const expression = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) //eslint-disable-line
						if (value && !expression.test(value[value.length - 1])) {
							value.pop()
						}
					}}
				>
					{field.options?.map((option) => (
						<Select.Option key={option} value={option}>
							{option}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
		)
	} else if (field.type === 'cascader') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
			>
				<Cascader className='CascaderField' options={field.options} expandTrigger='hover' />
			</Form.Item>
		)
	} else if (field.type === 'checkbox') {
		return (
			<Form.Item
				key={field.label}
				name={field.name}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
				initialValue={field.initialValue}
				className={field.className || 'NoMargin'}
				valuePropName='checked'
			>
				<Checkbox
					onChange={(e) => {
						if (field.fillData && Object.values(field.fillData).length > 0) {
							if (e.target.checked) {
								field.formReference.setFieldsValue(field.fillData)
							} else {
								field.formReference.setFieldsValue(field.clearData)
							}
						}
					}}
					defaultChecked={field.defaultChecked}
					disabled={field.disabled}
				>
					{field.label}
				</Checkbox>
			</Form.Item>
		)
	} else if (field.type === 'dynamicFields') {
		return (
			<Form.List key={field.label} name={field.name} label={field.label} initialValue={field.initialValue}>
				{(fields, { add, remove }) => (
					<>
						{fields.map((singleEntryField, singleEntryFieldIndex) =>
							field.groups ? (
								<div key={singleEntryField.name}>
									<div className='FlexRow MarginBottom'>
										<Divider className='SectionTitle' orientation='left' plain>
											{field.label} # {singleEntryFieldIndex + 1}
										</Divider>
										{fields.length > field.minEntries && (
											<Button
												className='EHButton MarginLeft'
												onClick={() => {
													remove(singleEntryField.name)
												}}
											>
												Delete Entry
											</Button>
										)}
									</div>
									{field.groups.map((groupEntry) => (
										<div className='DisplayFlex'>
											{groupEntry?.map((item, index) => (
												<div className={`FullWidth ${index !== 0 ? 'MarginLeft' : ''}`}>{FormRender({ ...item, name: [singleEntryField.name, item.name] })}</div>
											))}
										</div>
									))}
								</div>
							) : (
								<div key={singleEntryField.key} className='FlexRow'>
									{field.fields?.map((item, index) => (
										<div className={item.hidden ? '' : `FullWidth ${index !== 0 ? 'MarginLeft' : ''}`}>
											{FormRender({ ...item, name: [singleEntryField.name, item.name] })}
										</div>
									))}
									{fields.length > field.minEntries && (
										<MinusCircleTwoTone
											twoToneColor='#FF0000'
											className='MarginLeft'
											onClick={() => {
												remove(singleEntryField.name)
											}}
										/>
									)}
								</div>
							)
						)}
						<Form.Item noStyle>
							<Button icon={<PlusCircleTwoTone />} className='Button DynamicButton' onClick={() => add()} block>
								{fields.length === 0 ? 'Add ' : 'Add Another'} {field.label}
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
		)
	} else if (field.type === 'dependency') {
		return (
			<Form.Item
				key={field.name + 'dependency'}
				shouldUpdate={(prevValues, currentValues) => {
					if (Array.isArray(field.independent)) {
						return prevValues?.[field.independent[0]]?.[field.independent[1]] !== currentValues?.[field.independent[0]]?.[field.independent[1]]
					} else return prevValues[field.independent] !== currentValues[field.independent]
				}}
				noStyle
			>
				{({ getFieldValue }) => {
					if (Array.isArray(field.independent)) {
						const fieldValue = getFieldValue(field.independent[0])
						return field.condition.includes(fieldValue?.[field.independent[1]]) ? RenderSchema(field.successSchema) : RenderSchema(field.failureSchema)
					} else return field.condition.includes(getFieldValue(field.independent)) ? RenderSchema(field.successSchema) : RenderSchema(field.failureSchema)
				}}
			</Form.Item>
		)
	} else if (field.type === 'autocomplete') {
		return (
			field.isLoaded && (
				<Autocomplete
					key={field.name}
					onLoad={(auto) => {
						window.autocomplete = auto
					}}
					options={{ componentRestrictions: { country: 'pk' } }}
					onPlaceChanged={() => {
						if (window.autocomplete !== undefined) {
							const place = window.autocomplete.getPlace()
							const coordinates = place?.geometry && { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
							if (place) {
								setLocationInfo(field.formReference, place, field.regions, 'autocomplete')
								field.setCoordinates(coordinates)
							}
						}
					}}
				>
					<Form.Item
						key={field.name}
						label={field.label}
						name={field.name}
						rules={[{ required: field.required, message: field.message }]}
						tooltip={field.tooltip}
					>
						<Input type='text' placeholder='Search place or enter a new one' className='InputField' style={{ paddingLeft: 12 }} />
					</Form.Item>
				</Autocomplete>
			)
		)
	} else if (field.type === 'restrictedInput') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
				initialValue={field.initialValue}
				hidden={field.hidden}
			>
				<Input
					className='InputField AddOn'
					type={field.inputType}
					onInput={(e) => {
						if (field.toUpperCase) {
							e.target.value = e.target.value.toUpperCase()
						}
						if (field.maxLength) {
							e.target.value = e.target.value.slice(0, field.maxLength)
						}
						if (field.charactersOnly) {
							e.target.value = e.target.value.replace(/[^a-zA-Z]/g, '')
						}
					}}
					placeholder={field.placeholder}
					disabled={field.action === 'view' || field.disabled === true ? true : false}
					addonBefore={field.addonBefore}
					addonAfter={field.addonAfter}
				/>
			</Form.Item>
		)
	} else if (field.type === 'divider') {
		return <Divider key={'divider'} dashed />
	}
}

export const RenderSchema = (schemaJSON) =>
	schemaJSON.map((schema, index) => (
		<div key={index}>
			{schema.fields ? (
				<div key={'RenderSchema'}>
					<Divider dashed />
					<div className='HalfMarginBottom'>
						<p className='BoldFont GrayColor MarginTop'>{schema.title}</p>
						{schema.description && <p className='FontSize12 GrayColor'>{schema.description}</p>}
					</div>
					{schema.fields.map((field) => (checkArray(field) ? RowRenderer(field, index) : FormRender(field)))}
				</div>
			) : checkArray(schema) ? (
				RowRenderer(schema, index)
			) : (
				FormRender(schema)
			)}
		</div>
	))

export const RowRenderer = (fields, index) => {
	const visibleFields = fields?.filter((field) => !field.hidden)
	const hiddenFields = fields?.filter((field) => field.hidden)
	return (
		<div key={`RowRenderer${index}`} className='DisplayFlex'>
			{visibleFields.map((field, index) => (
				<div key={field.name} className={`${index > 0 && 'MarginLeft'}`} style={{ width: `${100 / visibleFields.length}%` }}>
					{FormRender(field)}
				</div>
			))}
			{hiddenFields.map((field) => (
				<div key={field.name} className='DisplayNone'>
					{FormRender(field)}
				</div>
			))}
		</div>
	)
}
