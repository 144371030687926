// REACT
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// DESIGN
import { Modal, Form, Button, notification } from 'antd'

// UTILS
import { RenderSchema } from '../utils/FormRenderer'
import { EmailSchema } from '../utils/FormSchemas'
import { CardHeader, getClientSummary, createOrder } from '../utils/Functions'
import ClientEmail from './ClientEmail'

// GRAPHQL
import useMutate from '../hooks/useMutate'

// RECOIL
import { useRecoilValue } from 'recoil'
import { PortSelector } from '../recoil/Selectors'

const OrderSummary = (props) => {
	const { visible, data } = props.modalData
    const navigate = useNavigate()
	const [summary, setSummary] = useState()
	const ports = useRecoilValue(PortSelector)
	const createOrderMutation = useMutate({ segment: data?.segment || 'Import', action: data?.action || 'create' })
	const updateOrderLocations = useMutate({ segment: 'OrderLocations', action: 'edit' })
	const [form] = Form.useForm()

	useEffect(() => {
		data?.values && setSummary(getClientSummary(data) || [])
	}, [data])

	const onFinish = async (values) => {
		const orderNumber = await createOrder({
			ports,
			clientEmail: values.clientEmail,
			...data,
			orderMutation: createOrderMutation,
			locationMutation: updateOrderLocations,
		})
		notification[orderNumber ? 'success' : 'error']({
			duration: 5,
			message: orderNumber ? `Order No. ${orderNumber} Created` : 'Unsuccessful',
			description: orderNumber
				? 'Orders created successfully. Please check orders table and verify.'
				: 'Problem in creating order. Please contact product team.',
		})
        navigate('/home/unassignedOrders/import')
	}

	const onCancel = () => {
		form.resetFields()
		props.setModalData((prevState) => ({ ...prevState, visible: false }))
	}

	const ModalFooter = () => (
		<div className='FullWidth'>
			<Button
				className='Button SecondaryButton'
				style={{ height: 40 }}
				onClick={onCancel}
				disabled={createOrderMutation?.loading || updateOrderLocations?.loading}
			>
				CANCEL
			</Button>
			<Button
				className='Button SuccessButton'
				style={{ height: 40 }}
				onClick={() => form.submit()}
				loading={createOrderMutation?.loading || updateOrderLocations?.loading}
			>
				CONFIRM
			</Button>
		</div>
	)

	return (
		visible && (
			<Modal
				visible={visible}
				title={CardHeader('Review Order Summary')}
				closable={false}
				className={'OrderSummaryModal'}
				footer={<ModalFooter />}
				centered={true}
			>
				<Form key='ModalForm' layout='vertical' form={form} onFinish={onFinish} scrollToFirstError>
					<div className='WBackground' style={{ padding: '12px 24px 24px' }}>
						<p className='BoldFont FontSize12'>Email Addresses</p>
						<p className='GrayColor FontSize10'>Order updates will be sent to client on these emails.</p>
						<div className='QuarterMarginTop'>{RenderSchema(EmailSchema({ clientEmail: data?.corporate?.creationEmail || [] }))}</div>
					</div>
					<div className='flex flex-col p-6'>
						<p className='GrayColor text-xs mb-3'>Following email will be sent to client:</p>
						{data?.values && ClientEmail({ segment: data?.segment, summary, orderCategory: data?.values?.specialRequests?.orderCategory })}
					</div>
				</Form>
			</Modal>
		)
	)
}

export default OrderSummary
