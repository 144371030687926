import React, { useEffect, useMemo } from 'react'
import ContainerDetails from './ContainerDetails'

const ContainerQuantity = (props) => {
	const { form, segment, action, container, containerCounter, setContainerCounter } = props
	const count = useMemo(() => containerCounter[container?.key], [containerCounter]) // eslint-disable-line

	useEffect(() => {
		const totalNoContainers = Object?.values(containerCounter)?.reduce((prev, curr) => prev + curr, 0)
		form.setFieldsValue({
			containersSpecifics: {
				...form.getFieldValue('containersSpecifics'),
				[container?.key]: {
					...form.getFieldValue('containersSpecifics')?.[container?.key],
					quantity: containerCounter[container?.key],
				},
			},
			numberOfContainers: totalNoContainers,
		})
	}, [containerCounter]) // eslint-disable-line

	return (
		<div className={count > 0 ? 'h-42 my-2 rounded-md bg-primary-50 py-6 pr-4' : 'my-2 h-24 rounded-md py-6 pr-4'}>
			<ContainerDetails form={form} count={count} action={action} segment={segment} container={container} setContainerCounter={setContainerCounter} />
		</div>
	)
}

export default ContainerQuantity
