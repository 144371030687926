import React from 'react'
import { SegmentsEnum, OrderCategoryEnum } from '../utils/Enums'

const OrderSummary = (props) => {
	const { segment, summary, orderCategory } = props || {}

	const getTableRow = (row, rowIndex) => {
		if (row.type === 'multiple') {
			return row.value?.map((value, index) => (
				<tr style={{ backgroundColor: rowIndex % 2 === 0 ? '#EDF7FF' : '#FFFFFF' }}>
					<td style={{ padding: '12px' }}>{index === 0 ? row.title : ''}</td>
					<td style={{ padding: '12px' }}>{value}</td>
				</tr>
			))
		} else {
			return (
				<tr style={{ backgroundColor: rowIndex % 2 === 0 ? '#EDF7FF' : '#FFFFFF' }}>
					<td style={{ padding: '12px' }}>{row.title}</td>
					<td style={{ padding: '12px' }}>{row.value}</td>
				</tr>
			)
		}
	}

	return (
		<div id='ClientSummary'>
			<table style={{ width: '100%', backgroundColor: '#0066CC' }}>
				<tbody>
					<tr>
						<div style={{ width: '100%', textAlign: 'center' }}>
							<img
								height='48px'
								style={{ margin: 24, maxHeight: 48, display: 'inline-block' }}
								src='https://firebasestorage.googleapis.com/v0/b/bridgelinx-client.appspot.com/o/public%2Fimages%2FBridgelinx%20Logo.png?alt=media&token=55ec23fd-848c-459b-8171-0eef30b015ac'
								alt=''
							/>
						</div>
					</tr>
					<tr>
						<p style={{ color: '#FFFFFF', textAlign: 'center' }}>Thank you for placing your order with Bridgelinx Technologies</p>
					</tr>
					<tr style={{ width: '100%' }}>
						<table style={{ margin: 24, backgroundColor: '#FFFFFF', borderRadius: '5px' }}>
							<tr>
								<td style={{ fontWeight: 'bold', padding: '12px', color: '#808080', whiteSpace: 'nowrap' }}>Please check your order details</td>
								<td style={{ width: '100%', fontWeight: 'bold', padding: '12px', color: '#0066CC', whiteSpace: 'nowrap' }}>{`${SegmentsEnum[segment]?.text} Order ${
									orderCategory ? `(${OrderCategoryEnum[orderCategory]?.category})` : ''
								}`}</td>
							</tr>
							{summary?.filter((row) => row.value)?.map((row, index) => getTableRow(row, index))}
						</table>
					</tr>
				</tbody>
			</table>
		</div>
	)
}

export default OrderSummary
