import { Button, Layout, List } from 'antd'
import SearchHeader from '../components/layout/SearchHeader'
import { useState, useEffect } from 'react'
import Icon from '@mdi/react'
import { mdiChevronRight, mdiPlus } from '@mdi/js'
import useFetchQuery from '../hooks/useFetchQuery'
import { useRecoilValue } from 'recoil'
import { ParentRegionSelector } from '../recoil/Selectors'
import { Outlet, useNavigate } from 'react-router-dom'

const ColumnHeader = ({ title, actionTitle, createFunction, showSearch, keyword, setKeyword, className }) => {
    const navigate = useNavigate()

	return (
		<Layout.Header className={`FlexColumn NoPadding ${className || ''}`} style={{ position: '' }}>
			<div className='GHBackground Height48 GrayShadeColor FontSize16 FlexRow PaddingLeft'>{title}</div>

			<Button className='FlexRow HalfPaddingLeft HalfPaddingRight PaddingTop PaddingBottom FullWidthButton ' onClick={() => { createFunction?.(); navigate('/home/regions/create') } }>
				<Icon path={mdiPlus} size={1} className='PrimaryColor HalfMarginRight' />
				<p className='FontBold PrimaryColor'>{actionTitle}</p>
			</Button>
			{showSearch && (
				<SearchHeader inputClassName='FullWidth' keyword={keyword} setKeyword={setKeyword} collection='regions' placeholder={`Search ${title}`} />
			)}
		</Layout.Header>
	)
}
const checkDisabled = ({ formWatch, type, data }) => {
	const { parentRegions, childRegions } = data
	const { name } = formWatch || {}
	let disabled
	if (type === 'createParentRegion') {
		disabled = parentRegions?.filter((region) => region.name?.toLowerCase() === name?.toLowerCase())?.length > 0
	} else {
		disabled = childRegions?.filter((region) => region.name?.toLowerCase() === name?.toLowerCase())?.length > 0
	}
	return disabled
}
const Regions = (props) => {
	const { loading } = useFetchQuery({ segment: 'ParentRegions', state: 'normal' })
	const [keyword, setKeyword] = useState('')
	const [childKeyword, setChildKeyword] = useState('')
	const [currParentRegion, setCurrentParentRegion] = useState()
	const [modalData, setModalData] = useState({ type: null })

	const [parentRegions = [], childRegions = []] = useRecoilValue(ParentRegionSelector({ currParentRegion, keyword, childKeyword }))
	useEffect(() => {
		if (currParentRegion && parentRegions?.filter((region) => region?._id === currParentRegion?._id)?.length === 0) {
			setCurrentParentRegion(null)
		}
	}, [keyword]) // eslint-disable-line

	return (
		<>
			<Layout className='Padding'>
				<Layout className='BorderRadiusMed WBackground'>
					<div className='FlexColumn FullHeight'>
						<SearchHeader
							className='BorderRadiusTopSmall MarginRight'
							keyword={keyword}
							setKeyword={setKeyword}
							collection='regions'
							placeholder='Search Parent Regions'
						/>
						<Layout.Content className='FlexRow FullWidth BorderRadiusBottomMedium'>
							<div className=' HalfWidth FullHeight BorderRight FlexColumn'>
								<ColumnHeader
									title='Parent Regions'
									actionTitle='Add Parent Region'
									createFunction={() => setModalData({ type: 'createParentRegion' })}
								/>
								<div className='OverflowScroll'>
									<List
										loading={loading}
										dataSource={parentRegions}
										renderItem={(item) => (
											<div
												key={item._id}
												className={`HalfPadding ListButton CursorPointer FlexRowBetween ${item._id === currParentRegion?._id ? 'TBackground' : ''}`}
												onClick={(e) => setCurrentParentRegion(item)}
											>
												<p className=''>{item.name}</p>
												<Icon path={mdiChevronRight} size={1} className=' HalfMarginRight' />
											</div>
										)}
									/>
								</div>
							</div>
							<div className=' HalfWidth FullHeight FlexColumn'>
								<ColumnHeader
									title={!currParentRegion ? `Select a Parent Region to View Child Regions` : `Child Regions Of ${currParentRegion?.name}`}
									actionTitle='Add Child Region'
									showSearch={true}
									createFunction={() => setModalData({ type: 'createChildRegion' })}
									keyword={childKeyword}
									setKeyword={setChildKeyword}
									className='BorderTop'
								/>
								<div className='OverflowScroll'>
									<List
										loading={loading}
										dataSource={childRegions}
										renderItem={(item) => (
											<div key={item._id} className='HalfPadding '>
												<p className=''>{item.name}</p>
											</div>
										)}
									/>
								</div>
							</div>
						</Layout.Content>
					</div>
				</Layout>
			</Layout>
            <Outlet
                context={{
                    type: modalData.type,
                    checkDisabled: checkDisabled,
                    data: { currParentRegion, parentRegions, childRegions },
                }}
            />
		</>
	)
}

export default Regions
