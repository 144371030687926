import { Layout, Tabs } from 'antd'
import { SegmentsEnum } from '../../utils/Enums'
import { useNavigate } from 'react-router-dom'

const TabHeader = (props) => {
	const { collection,  selectedTab } = props

    const navigate = useNavigate()

	return (
		<Layout.Header className='NoPadding'>
			<Tabs
				type='card'
                activeKey={selectedTab}
				defaultActiveKey='Import'
				onChange={(key) => {
					navigate(`/home/${collection}/${key.toLowerCase()}`)
				}}
				items={Object.values(SegmentsEnum)?.map((segment) => ({
					label: <div className='p-2 text-sm font-bold'>{segment?.text}</div>,
					key: segment?.key,
				}))}
			/>
		</Layout.Header>
	)
}

export default TabHeader
