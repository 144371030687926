// REACT
import React, { useMemo } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'

// ANTD
import { Layout } from 'antd'

// COMPONENTS
import TabHeader from '../components/layout/TabHeader'

// UTILS
import { pathLocation } from '../utils/Functions'
import { SegmentNameEnum } from '../utils/Enums'

const AllOrders = (props) => {
    const {pathname} = useLocation()
    const { segment } = useParams()
	const collection = useMemo(() => pathLocation(pathname, 2), [pathname])
	const selectedTab = useMemo(() => SegmentNameEnum?.[`${segment}`]?.name, [segment])

	return (
		<Layout className='WBackground'>
			<TabHeader collection={collection} selectedTab={selectedTab} />
            <Outlet
                context={{
                    selectedTab,
                    collection,
                }}
            />
		</Layout>
	)
}

export default AllOrders
