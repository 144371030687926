// Firebase
import { firebase } from '../firebase/firebaseConfig'
import { retryCatch } from '../utils/Functions'

export const loginWithEmailPassword = async (email, values, navigate, setUserAtom, setAlert, setAuthorized, getProfile) => {
	return firebase
		.auth()
		.signInWithEmailAndPassword(email, values.password)
		.then(async (userCredential) => {
			const token = await firebase.auth().currentUser.getIdToken()
			localStorage.setItem('token', JSON.stringify({ token: token }))
			const apolloData = await getProfile({
				variables: {
					where: {
						_id: userCredential.user.uid,
					},
				},
			})
			const userProfile = apolloData?.data?.users?.[0]
			if (userProfile && userProfile?.UserType?.some((UserType) => ['SuperAdmin', 'Admin', 'ImportExportOps']?.includes(UserType?.userType))) {
				setUserAtom(userProfile)
				localStorage.setItem('user', JSON.stringify(userProfile))
			} else {
				setAuthorized(false)
			}
		})
		.catch((error) => {
			if (error.code === 'auth/invalid-email' || error.code === 'auth/wrong-password') {
				setAlert({ type: 'error', message: 'Email address or password is incorrect.' })
			} else if (error.code === 'auth/user-not-found') {
				setAlert({ type: 'error', message: 'No user record for this email address.' })
			} else {
                console.log(error)
				setAlert({ type: 'error', message: 'Please check your internet connection.' })
			}
		})
}

export const sendPasswordResetLink = async (email, setAlert) => {
	return firebase
		.auth()
		.sendPasswordResetEmail(email)
		.then(() => {
			setAlert({ type: 'success', message: 'Reset link has been sent to your email.' })
		})
		.catch((error) => {
			if (error.code === 'auth/invalid-email') {
				setAlert({ type: 'error', message: 'Email address is not valid.' })
			} else if (error.code === 'auth/user-not-found') {
				setAlert({ type: 'error', message: 'No user record for this email address.' })
			} else {
				setAlert({ type: 'error', message: 'Please check your internet connection.' })
			}
		})
}

export const logout = () => {
    
}

export const createSheetDataRequest = (values) => {
	const { email, range, segment, type, columns } = values
	const [from, to] = range
	const fromDate = from?.startOf('day')?.format()
	const toDate = to?.endOf('day')?.format()
	return retryCatch({
		maxRetries: 3,
		delay: 500,
		executionFunction: (token) =>
			fetch(`https://asia-south1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/graphqlMetrics`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ segment, fromDate, toDate, type, email, columns }),
			}),
	})
}
