import { SegmentsEnum } from '../utils/Enums'

export const OrderDetailsColumn = {
	title: 'Order',
	key: 'order',
	defaultSortOrder: 'descend',
	sortDirections: ['ascend', 'descend', 'ascend'],
	sorter: (a, b) =>
		(a?.subOrderNumber || a?.orderNumber?.toString())?.localeCompare(b?.subOrderNumber || b?.orderNumber?.toString(), 'en', { numeric: true }),
	render: (_, record) => {
		const { createdAt, orderNumber, specialRequests, type } = record
		return (
			<>
				<div className='BoldFont PrimaryColor CursorPointer FlexRow'>
					<span className='QuarterPaddingTopRight'>
						{SegmentsEnum[type]?.initial}-{orderNumber}
					</span>
					{['Shifting', 'Destuffing'].includes(specialRequests?.orderCategory) ? (
						<span className={`FontSize12 Capitalize QuarterPaddingTopRight ${specialRequests?.orderCategory === 'Destuffing' ? `YellowColor` : `SuccessColor`}`}>
							{' '}
							{specialRequests?.orderCategory}
						</span>
					) : (
						specialRequests?.doubleTwenty && <span className={'FontSize12 Capitalize PrimaryTintColor QuarterPaddingTopRight'}> Double Twenty</span>
					)}
					{specialRequests?.lotOrder && <span className={'FontSize12 Capitalize YellowColor QuarterPaddingTopRight'}> Lot Order</span>}
				</div>
				<div className='FontSize10'>
					<span className='BoldFont'>Created At: </span>
					{createdAt}
				</div>
			</>
		)
	},
}

export const ClientColumn = (props) => {
	return {
		title: 'Client',
		key: 'client',
		// filters: props?.corporates?.map(corporate=> ({text: `${corporate?.businessName} (${corporate?.businessCode})`, value: corporate?.businessCode})),
		onFilter: (value, record) => record?.businessCode === value,
		render: (_, record) => (
			<>
				<p className='FontSize12'>{record?.businessName}</p>
				<p className='FontSize12 PrimaryColor'>{record?.businessCode}</p>
			</>
		),
	}
}

export const ShipmentInfoColumn = {
	title: 'Shipment Information',
	key: 'shipmentInfo',
	render: (_, record) => {
		return (
			<>
				{record.document ? (
					<a href={record?.document} target='_blank' rel='noreferrer'>
						<p className='BoldFont FontSize12 PrimaryColor Link'>{record?.documentNumber}</p>
					</a>
				) : (
					<p className='FontSize12'>{record?.documentNumber}</p>
				)}
			</>
		)
	},
}

export const RevenueColumn = {
	title: 'Revenue',
	key: 'revenue',
	render: (_, record) => {
		return <>{!!record?.finalizedRate && <p className='FontSize12'>{record?.finalizedRate}</p>}</>
	},
}

export const PendingOrderShipmentInfoColumn = {
	title: 'Shipment Information',
	key: 'shipmentInfo',
	render: (_, record) => {
		return (
			<>
				{record.document ? (
					<a href={record?.document} target='_blank' rel='noreferrer'>
						<p className='BoldFont FontSize12 PrimaryColor Link'>{record?.documentNumber}</p>
					</a>
				) : (
					<p className='FontSize12'>{record?.documentNumber}</p>
				)}
			</>
		)
	},
}

export const ClientNameColumn = {
	title: 'Client Name',
	key: 'clientName',
	render: (_, record) => <p className='FontSize12 CursorPointer'>{record?.businessName}</p>,
}

export const ClientCodeColumn = {
	title: 'Business Code',
	key: 'businessCode',
	render: (_, record) => <p className='FontSize12'>{record?.businessCode}</p>,
}
