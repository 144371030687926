import { Button } from 'antd'
import { mdiMapMarkerPlusOutline } from '@mdi/js'
import { FormRender, RenderSchema } from '../../utils/FormRenderer'
import { MinusCircleTwoTone } from '@ant-design/icons'
import Icon from '@mdi/react'
import { VehicleTypeEnum } from '../../utils/Enums'
import { VehiclesCardUnitSchema } from '../../utils/FormSchemas'

const SelectVehiclesCard = (props) => {
	const { form, watcher, action, vehicleCounter, setVehicleCounter } = props

	const containersSpecifics = Object.values(watcher?.containersSpecifics)?.filter((container) => container?.quantity)
	const totalContainers = containersSpecifics?.reduce((prev, curr) => prev + curr?.quantity, 0)
	const totalVehicles = watcher?.vehicleDetails?.reduce((prev, curr) => prev + parseInt(curr?.quantity), 0)

	const removeVehicleType = (type, index) => {
		const vehicleDetails = form.getFieldValue(type)
		vehicleDetails?.splice(index, 1)
		setVehicleCounter((prevState) => prevState - 1)
		form.setFieldsValue({ [type]: vehicleDetails })
	}

	return (
		<>
			<div className='mb-3 text-base font-medium leading-5 text-gray-500'>Select Vehicles</div>
			<div className='mb-12 rounded-lg border bg-white p-6'>
				<div className='flex flex-col'>
					{RenderSchema(VehiclesCardUnitSchema())}
					{[...Array(vehicleCounter === 0 ? 1 : vehicleCounter)]?.map((_, index) => {
						const currentVehicle = parseInt(watcher?.vehicleDetails?.[index]?.quantity)
						return (
							<div className='h-15 flex flex-row justify-items-start'>
								<div className='w-36'>
									{FormRender({
										type: 'input',
										name: ['vehicleDetails', index, '_id'],
										hidden: true,
									})}

									{FormRender({
										type: 'select',
										...(index === 0 && { label: 'Vehicle Type' }),
										name: ['vehicleDetails', index, 'vehicleType'],
										options: Object.values(VehicleTypeEnum),
										showSearch: true,
										required: true,
										valueProperty: 'key',
										displayProperty: 'text',
										message: 'Vehicle Type is required.',
										placeholder: 'Select Vehicle Type',
									})}
								</div>
								<div div className='ml-5 w-24'>
									{FormRender({
										type: 'number',
										...(index === 0 && { label: 'Min Weight' }),
										name: ['vehicleDetails', index, 'minWeight'],
										inputType: 'number',
										maxValue: parseInt(watcher?.vehicleDetails?.[index]?.maxWeight) - 1,
										minValue: 1,
									})}
								</div>
								<div div className='ml-5 w-24'>
									{FormRender({
										type: 'number',
										...(index === 0 && { label: 'Max Weight' }),
										name: ['vehicleDetails', index, 'maxWeight'],
										inputType: 'number',
										minValue: parseInt(watcher?.vehicleDetails?.[index]?.minWeight) + 1,
									})}
								</div>
								<div div className='ml-5 w-36'>
									{FormRender({
										type: 'number',
										...(index === 0 && { label: 'No. of Vehicles' }),
										name: ['vehicleDetails', index, 'quantity'],
										inputType: 'number',
										required: true,
										// disabled: action === 'edit',
										maxValue:
											watcher?.specialRequests?.orderCategory === 'Shifting'
												? totalContainers - totalVehicles + currentVehicle
												: totalContainers - totalVehicles + currentVehicle - 1,
										message: 'Number of vehicles is required.',
										minValue: 1,
									})}
								</div>
								<div div className='ml-5 w-52'>
									{FormRender({
										type: 'number',
										...(index === 0 && { label: 'Rate per Vehicle' }),
										name: ['vehicleDetails', index, 'quotedRate'],
										inputType: 'number',
										required: true,
										message: 'Quoted Vehicle Rate is required.',
										minValue: 1,
									})}
									{FormRender({
										type: 'input',
										name: ['vehicleDetails', index, 'quotedRateUnit'],
										hidden: true,
									})}
								</div>
								{index > 0 && (
									<MinusCircleTwoTone
										twoToneColor='#FF0000'
										className='ml-6 mt-5'
										onClick={() => {
											removeVehicleType('vehicleDetails', index)
										}}
									/>
								)}
							</div>
						)
					})}
					{
						<div className='flex w-full items-center justify-end px-6 pb-6'>
							<Button className='btn-dashed py-4' onClick={() => setVehicleCounter((prevState) => prevState + 1)}>
								<Icon path={mdiMapMarkerPlusOutline} size={0.8} className='mr-2' />
								ADD VEHICLE TYPE
							</Button>
						</div>
					}
				</div>
			</div>
		</>
	)
}

export default SelectVehiclesCard
