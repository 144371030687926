// REACT
import React, { useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

// ANTD
import { Card, Layout, Button, Divider, Form, notification, Transfer } from 'antd'
import { DatePicker } from 'antd'

// UTILS
import { pathLocation } from '../utils/Functions'

// COMPONENTS
import { FormRender, RenderSchema } from '../utils/FormRenderer'

// ERROR BOUNDARY
import { CustomHeaders, MetricsEnums } from '../utils/Enums'
import { createSheetDataRequest } from '../services/FirebaseServices'

import moment from 'moment'

const { Content } = Layout

const CardTitleHeader = (Title, Description) => (
	<div>
		<p className='NoMargin' style={{ color: '#1C75BC' }}>
			{Title}
		</p>
		<p className='CardTitleDescription NoMargin TextWrap'>{Description}</p>
	</div>
)

export const MetricsCard = (props) => {
	const { type, className } = props
	const { title = '', description = '', key = '', segment } = MetricsEnums[type] || {}
	const [loading, setLoading] = useState(false)
	const [disabled, setDisabled] = useState(false)
	const [form] = Form.useForm()

	return (
		<Card
			title={CardTitleHeader(title, description)}
			headStyle={{ fontSize: '16px', backgroundColor: '#EDF7FF', color: '#1C75BC' }}
			bodyStyle={{ padding: '24px' }}
			className={className || 'HalfWidth MarginRight'}
		>
			<Form
				form={form}
				onFinish={(values) => {
					setLoading(true)
					createSheetDataRequest({ ...values, type: key, segment, email: values.email + '@bridgelinxpk.com' }).then((response) => {
						setLoading(false)
						notification[[200, 201].includes(response.status) ? 'success' : 'error']({
							duration: 5,
							message: response.status === 200 ? `Files Sent Succesfully` : response.status === 201 ? 'No Data Found' : 'Unsuccessful',
							description:
								response.status === 200
									? `Your requested metrics have been sent to ${values.email + '@bridgelinxpk.com'}. Kindly check your inbox`
									: response.status === 201
									? `No orders were placed between ${values.range?.[0]?.format('Do MMM yyyy')} and ${values.range?.[1]?.format('Do MMM yyyy')}`
									: 'Problem in creating order. Please contact product team.',
						})
					})
				}}
			>
				<div className='FlexColumn'>
					<Form.Item key={'range'} name={['range']} rules={[{ required: true, message: 'Enter Date Range' }]}>
						<DatePicker.RangePicker className='FullWidth TypeField' disabledDate={(current) => current > moment.now()} />
					</Form.Item>
					<div className='FlexRowStart'>
						{FormRender({
							type: 'input',
							name: 'email',
							inputType: 'email',
							required: true,
							message: 'Email is required.',
							addonAfter: '@bridgelinxpk.com',
						})}
						<Button className='DownloadButton MarginLeft' loading={loading} htmlType='submit'>
							Generate Report
						</Button>
					</div>
				</div>
			</Form>
		</Card>
	)
}

const CustomMetricsCard = (props) => {
	const [form] = Form.useForm()
	const { className } = props
	const [loading, setLoading] = useState(false)
	const [targetKeys, setTargetKeys] = useState([])
	const { title = '', description = '', key = '' } = MetricsEnums.CustomHeaderSheet || {}

	return (
		<Card
			title={CardTitleHeader(title, description)}
			headStyle={{ fontSize: '16px', backgroundColor: '#EDF7FF', color: '#1C75BC' }}
			bodyStyle={{ padding: '24px' }}
			className={className || 'FullWidth'}
		>
			<Form
				form={form}
				onFinish={(values) => {
					setLoading(true)
					createSheetDataRequest({
						...values,
						segment: values?.segment === 'Bulk' ? 'LongHaul' : values?.segment,
						columns: targetKeys,
						type: key,
						email: values.email + '@bridgelinxpk.com',
					}).then((response) => {
						setLoading(false)
						notification[[200, 201].includes(response.status) ? 'success' : 'error']({
							duration: 5,
							message: response.status === 200 ? `Files Sent Succesfully` : response.status === 201 ? 'No Data Found' : 'Unsuccessful',
							description:
								response.status === 200
									? `Your requested metrics have been sent to ${values.email + '@bridgelinxpk.com'}. Kindly check your inbox`
									: response.status === 201
									? `No orders were placed between ${values.range?.[0]?.format('Do MMM yyyy')} and ${values.range?.[1]?.format('Do MMM yyyy')}`
									: 'Problem in creating order. Please contact product team.',
						})
					})
				}}
			>
				<div className='FlexRowBetween FullWidth'>
					<div className='FlexRow'>
						{RenderSchema([
							{
								type: 'select',
								itemClassName: 'TwoHundredWidth MarginRight',
								placeholder: 'Select Segment',
								name: 'segment',
								options: ['Import', 'Export', 'Bulk'],
								required: true,
							},
							{
								type: 'dateRange',
								itemClassName: 'FourHundredWidth MarginRight',
								disableFutureDates: true,
								name: 'range',
								required: true,
								message: 'Enter Date Range',
							},

							{
								type: 'input',
								name: 'email',
								itemClassName: 'MarginRight',
								inputType: 'email',
								required: true,
								message: 'Email is required.',
								addonAfter: '@bridgelinxpk.com',
							},
						])}
					</div>

					<Button className='DownloadButton MarginLeft ThirtySeventyWidth' loading={loading} htmlType='submit'>
						Generate Report
					</Button>
				</div>
				<Transfer
					dataSource={CustomHeaders}
					className='FullWidth'
					titles={['All Headers', 'Selected Headers']}
					targetKeys={targetKeys}
					onChange={(nextTargetKeys) => setTargetKeys(nextTargetKeys)}
					filterOption={(input, option) => option.title.toLowerCase().includes(input.toLowerCase())}
					render={(item) => item.title}
					showSearch
				/>
			</Form>
		</Card>
	)
}

const Metrics = (props) => {
	const {pathname} = useLocation()
	const collection = useMemo(() => pathLocation(pathname, 2), [pathname])

	return (
		<Layout className='WBackground'>
			<Content className='OverflowScroll'>
				<div className=' FlexRowBox Padding '>
					<div className='FlexColumn FullWidth Padding'>
						<CustomMetricsCard />
						<div className='FlexRowStart FullWidth'>
							<MetricsCard type='ImportDriverTracking' />
							<MetricsCard type='ExportDriverTracking' />
						</div>
						<Divider />
						<div className='FlexRowStart FullWidth'>
							<MetricsCard type='LongHaulDriverTracking' />
							<MetricsCard type='EirTrackingSheet' />
						</div>
						<Divider />
						<div className='FlexRowStart FullWidth'>
							<MetricsCard type='InvoiceSheet' />
							<MetricsCard type='BiltySheet' />
						</div>
						<Divider />
						<div className='FlexRowStart FullWidth'>
							<MetricsCard type='ConsolidatedSheet' />
						</div>
						<Divider />
					</div>
				</div>
			</Content>
		</Layout>
	)
}

export default Metrics
