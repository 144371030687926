import React, { useEffect, useState, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api'

import { RenderSchema } from '../../utils/FormRenderer'
import { AddClientLocationSchema } from '../../utils/FormSchemas'
import { CardHeader, getZoomLevel, setLocationInfo } from '../../utils/Functions'

import { useRecoilValue } from 'recoil'

import Icon from '@mdi/react'
import { mdiTrashCanOutline } from '@mdi/js'
import { Modal, Form, Button } from 'antd'
import useMutate from '../../hooks/useMutate'
import { mutateGraphQL } from '../../services/MutateGraphQl'
import { RegionSelector } from '../../recoil/Selectors'

const LocationModal = (props) => {
	const { visible, data, setData, targetIndex, action, setLoading } = props.modalData

	// const [loading, setLoading] = useState(false)
	const [form] = Form.useForm()
	const formWatch = Form.useWatch(undefined, form)
	const [coordinates, setCoordinates] = useState({ lat: 31.5041631, lng: 74.4135532 })
	const [boundsCenter, setBoundsCenter] = useState({ lat: 31.5041631, lng: 74.4135532, zoomlevel: 16 })
	const libraries = useMemo(() => ['geometry', 'drawing', 'places', 'visualization'], [])
	const { isLoaded } = useJsApiLoader({ googleMapsApiKey: process.env.REACT_APP_API_KEY, libraries })

	const [parentRegions, childRegions, allChildRegions] = useRecoilValue(RegionSelector({ currChildRegion: formWatch?.subRegion }))

	const mutateLocation = useMutate({ segment: 'Locations', action: 'edit' })

	let bounds = useMemo(() => isLoaded && new window.google.maps.LatLngBounds(), [isLoaded])
	useEffect(() => {
		const record = data[targetIndex]
		if (record?.Region?.ParentRegion && formWatch?.subRegion === record?.Region?.name) {
			form.setFieldValue('parentRegion', record?.Region?.ParentRegion?._id)
			// console.log(formWatch)
		} else {
			form.setFieldValue('parentRegion', null)
		}
	}, [formWatch?.subRegion]) // eslint-disable-line
	useEffect(() => {
		if (visible) {
			const record = data[targetIndex]
			// console.log(record)
			record?.coordinates && setCoordinates(record?.coordinates)
			form.setFieldsValue({ ...record, subRegion: record?.Region?.name, parentRegion: record?.Region?.ParentRegion?._id })
		} else {
			form.resetFields()
			setCoordinates({ lat: 31.5041631, lng: 74.4135532 })
		}
	}, [visible]) // eslint-disable-line

	useEffect(() => {
		if (isLoaded && coordinates?.lat && coordinates?.lng) {
			bounds = new window.google.maps.LatLngBounds({ lat: parseFloat(coordinates.lat), lng: parseFloat(coordinates.lng) }) // eslint-disable-line
			setBoundsCenter({ lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng(), zoomlevel: getZoomLevel(bounds) - 3 })
		}
	}, [coordinates]) // eslint-disable-line

	const onDragEnd = async (latLng) => {
		const pinCoordinates = { lat: latLng.lat(), lng: latLng.lng() }
		setCoordinates(pinCoordinates)
		setLocationInfo(form, pinCoordinates)
		// if (pinCoordinates?.lat && pinCoordinates?.lng) {
		// 	const response = await fetch(
		// 		`https://maps.googleapis.com/maps/api/geocode/json?latlng=${pinCoordinates.lat},${pinCoordinates.lng}&key=${process.env.REACT_APP_API_KEY}`
		// 	)
		// 	const result = await response.json()
		// 	const formattedAddress = result?.results?.[0]?.formatted_address
		// 	if (formattedAddress.includes(', Pakistan')) {
		// 		setLocationInfo(form, result?.results?.[0], regions, 'drag')
		// 		setCoordinates(pinCoordinates)
		// 	} else {
		// 		const oldCoordinates = { lat: form.getFieldValue('lat') || 31.5041631, lng: form.getFieldValue('lng') || 74.4135532 }
		// 		setCoordinates(oldCoordinates)
		// 	}
		// }
	}

	const onDelete = () => {
		// setLoading(true)
		const newLocations = [...data]
		newLocations.splice(targetIndex, 1)
		setData(newLocations)
		// setLoading(false)
		onCancel()
	}

	const onFinish = async (values) => {
		setLoading(true)
		const selectedParent = parentRegions?.find((region) => region?._id === formWatch?.parentRegion)

		if (action === 'add') {
			setData([
				...data,
				{
					...values,
					subRegionId: allChildRegions?.find(
						(region) => values?.subRegion === region?.name && selectedParent?.AssociatedRegions?.find((subRegion) => subRegion?._id === region?._id)
					)?._id,
				},
			])
		} else if (action === 'edit') {
			await mutateGraphQL({
				mutateFunction: mutateLocation?.mutateFunction,
				mutationInputFunction: mutateLocation?.mutationInputFunction,
				mutationOutputFunction: mutateLocation?.mutationOutputFunction,
				mutationData: {
					_id: data?.[targetIndex]?._id,
					formattedAddress: values?.formattedAddress,
					additionalDetails: values?.additionalDetails,
					coordinates: values?.coordinates,
					RegionId: allChildRegions?.find(
						(region) => values?.subRegion === region?.name && selectedParent?.AssociatedRegions?.find((subRegion) => subRegion?._id === region?._id)
					)?._id,
				},
			})
			const newLocations = [...data]
			newLocations.splice(targetIndex, 1, { ...data[targetIndex], ...values })
			setData(newLocations)
		}
		setLoading(false)
		onCancel()
	}

	const onCancel = () => {
		props.setModalData((prevState) => ({ ...prevState, visible: false }))
	}

	const ModalFooter = () => (
		<div className='FullWidth FlexRow' style={{ justifyContent: action === 'edit' ? 'space-between' : 'flex-end' }}>
			{action === 'edit' && (
				<Button
					className='FullWidthHeight Button EHBackground FlexRowBox NoPadding'
					style={{ height: 40, width: 40, backgroundColor: '#FFEBEE' }}
					icon={<Icon path={mdiTrashCanOutline} size={1} className='ErrorColor' />}
					onClick={onDelete}
				></Button>
			)}
			<div>
				<Button className='Button SecondaryButton' style={{ height: 40 }} onClick={onCancel}>
					CANCEL
				</Button>
				<Button className='Button SuccessButton' style={{ height: 40 }} onClick={() => form.submit()} loading={mutateLocation?.loading}>
					SUBMIT
				</Button>
			</div>
		</div>
	)

	return (
		visible && (
			<Modal
				visible={visible}
				title={CardHeader('Add/Edit Client Location')}
				closable={false}
				className={'MapModal'}
				footer={<ModalFooter />}
				centered={true}
			>
				<div className={'DisplayFlex FullHeight'}>
					<div className={'HalfWidth FullHeight Padding OverflowScroll'}>
						<Form key={`Form_{}`} layout={'vertical'} form={form} onFinish={onFinish} scrollToFirstError>
							{RenderSchema(AddClientLocationSchema({ form, coordinates, setCoordinates, isLoaded, parentRegions, childRegions, action }))}
						</Form>
					</div>
					<div className='PositionRelative FullHeight HalfWidth'>
						{isLoaded && (
							<GoogleMap
								mapContainerClassName='PositionAbsolute FullWidthHeight '
								bounds={bounds}
								zoom={boundsCenter && boundsCenter.zoomlevel}
								center={boundsCenter && { lat: boundsCenter.lat, lng: boundsCenter.lng }}
								// onClick= { (e) => onDragEnd(e?.latLng)}
							>
								{coordinates && (
									<Marker
										key={`Marker`}
										draggable={true}
										position={{ lat: parseFloat(coordinates.lat), lng: parseFloat(coordinates.lng) }}
										onDragEnd={(e) => onDragEnd(e?.latLng)}
									/>
								)}
							</GoogleMap>
						)}
					</div>
				</div>
			</Modal>
		)
	)
}

export default LocationModal
