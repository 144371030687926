import { selector, selectorFamily } from 'recoil'
import * as AllAtoms from './Atoms'
import { GraphQlEnum } from '../utils/Enums'
import { groupByProperty, searchFields } from '../utils/Functions'
import moment from 'moment'

/* ============================================================================================== */
/* ======================================= AUTHENTICATION ======================================= */
/* ============================================================================================== */
export const AuthSelector = selectorFamily({
    key: 'AuthSelector',
    get: () => ({ get }) => {
        const user = get(AllAtoms.userAtom)
        return Object.keys(user).length === 0 ? false : true
    },
})

/* ============================================================================================== */
/* ======================================= SEGMENT ORDERS ======================================= */
/* ============================================================================================== */
export const DynamicTableSelector = selectorFamily({
	key: 'DynamicTableSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, pageNo, pageSize, state, status, keyword, filterProperty } = props
			let collectionData = []
			const atomName = AllAtoms[GraphQlEnum[segment].atoms[state]]

			if (segment === 'Corporates') {
				collectionData = get(atomName)
			} else {
				const segmentOrders = get(atomName)
				collectionData = get(PrepareOrderSelector({ segmentOrders, segment, status }))
				collectionData = [...collectionData]?.sort?.((a, b) => b?.orderNumber - a?.orderNumber)
			}

			if (collectionData?.length > 0 && keyword && filterProperty) {
				collectionData = searchFields(collectionData, keyword, filterProperty)
			}
			return {
				totalCount: collectionData?.length,
				collectionData: segment === 'Corporates' ? collectionData : collectionData?.slice(pageNo * pageSize - pageSize, pageNo * pageSize),
			}
		},
})

export const PrepareOrderSelector = selectorFamily({
	key: 'PrepareOrderSelector',
	get:
		(props) =>
		({ get }) => {
			const { segmentOrders, segment, status } = props
			const filteredOrders = segmentOrders?.filter((order) => status?.includes(order?.ParentOrder?.[GraphQlEnum[segment]?.parentType]?.status))
			// console.log({ orders: filteredOrders })
			const orders = filteredOrders?.map((order) => {
				const parentOrder = order?.ParentOrder?.[GraphQlEnum[segment]?.parentType]
				let subOrders = [...parentOrder?.[GraphQlEnum[segment]?.childrenType]]?.sort((a, b) => a?.subOrderNumber.localeCompare(b?.subOrderNumber))
				subOrders = parentOrder?.preferences?.lotOrder
					? subOrders?.map((subOrder, index) => ({
							...subOrder,
							subOrderNumber: index === 0 ? 'Remaining' : subOrder?.subOrderNumber,
					  }))
					: subOrders
				const finalizedRate = subOrders
					?.map((subOrder) =>
						subOrder?.subOrderNumber !== 'Remaining' && (subOrder?.finalizedRateUnit === 'Container' || subOrder?.finalizedRateUnit === 'Vehicle')
							? subOrder?.finalizedRate
							: subOrder?.finalizedRateUnit === 'Tonne' && segment === 'LongHaul'
							? subOrder?.finalizedRate * subOrder?.shipmentInfo?.freightWeight || 0
							: subOrder?.finalizedRateUnit === 'Tonne' && segment !== 'LongHaul' && subOrder?.finalizedRate * subOrder?.freightWeight
					)
					?.reduce((partialSum, finalizedRate) => parseFloat(partialSum) + parseFloat(finalizedRate), 0)
				// console.log('finalizedRate', finalizedRate)
				return {
					_id: order?._id,
					type: order?.type,
					subOrders,
					finalizedRate: finalizedRate,
					accruedRevenue: order?.accruedRevenue,
					supplyQuote: order?.supplyQuote,
					document: parentOrder?.document,
					orderNumber: parentOrder?.orderNumber,
					freeDays: subOrders?.[0]?.freeDays,
					loadingDT: subOrders?.[0]?.loadingDT,
					loading: subOrders?.[0]?.OrderLocations?.filter((value) => value.locationType === 'loading'),
					dropoff: subOrders?.[0]?.OrderLocations?.filter((value) => value.locationType === 'dropoff'),
					containerNumber: [...new Set(subOrders?.map((childOrder) => childOrder?.containerNumber))],
					clearingAgents: parentOrder?.clearingAgents,
					documentNumber: parentOrder?.documentNumber,
					createdAt: moment(order?.createdAt).format('DD-MM-YYYY'),
					businessCode: order?.businessCode,
					businessName: order?.Corporate?.businessName,
					vehicleInfos: order?.VehicleInfos?.map((vehicle) => {
						let vehicleClone = { ...vehicle }
						delete vehicleClone.__typename
						return vehicleClone
					}),
					...(segment === 'Import' && { flatBedInfos: parentOrder?.flatBedInfos }),
					...(segment === 'Export' && { vesselCutoffDT: parentOrder?.vesselCutoffDT }),
					...(segment === 'LongHaul' && {
						lotAssignedWeight: subOrders
							?.filter((value) => !value?.subOrderNumber?.includes('Remaining'))
							?.map((value) => value?.shipmentInfo?.freightWeight)
							?.reduce((partialSum, freightWeight) => parseFloat(partialSum) + parseFloat(freightWeight), 0),
						expectedMovementDeadline: parentOrder?.expectedMovementDeadline,
						vehicleInfo: { vehicleType: subOrders?.[0]?.vehicleType, vehicleSubtype: subOrders?.[0]?.vehicleSubType },
					}),
					shipmentInfo: {
						...(['Import', 'Export']?.includes(segment) && { shippingLine: parentOrder?.shippingLine }),
						...(segment === 'LongHaul' && { freightType: parentOrder?.shipmentInfo?.freightType, freightWeight: parentOrder?.shipmentInfo?.freightWeight }),
					},
					specialRequests: {
						...parentOrder?.preferences,
						doubleTwenty: 'DoubleTwenty' === parentOrder?.preferences?.movementType ? true : false,
						orderCategory:
							'ImportShifting' === parentOrder?.preferences?.movementType
								? subOrders?.length === parentOrder?.preferences?.numberFlatbeds
									? 'Shifting'
									: 'Destuffing'
								: parentOrder?.preferences?.movementType === 'DoubleTwenty'
								? 'Import'
								: parentOrder?.preferences?.movementType,
					},
				}
			})
			return orders
		},
})

/* ============================================================================================== */
/* ========================================= CORPORATES ========================================= */
/* ============================================================================================== */

export const CorporatesSelector = selector({
	key: 'CorporatesSelector',
	get: ({ get }) => {
		const mappedCorporates = get(AllAtoms.corporatesAtom)?.map((corporate) => ({
			...corporate,
			businessNameCode: `${corporate.businessName} (${corporate.businessCode})`,
		}))
		return mappedCorporates
	},
})

/* ============================================================================================== */
/* ============================= EMPTY CONTAINER TERMINALS ====================================== */
/* ============================================================================================== */

export const EmptyContainerSelector = selector({
	key: 'EmptyContainerSelector',
	get: ({ get }) => {
		const mappedTerminals = [...get(AllAtoms.terminalsAtom)]?.sort((a, b) => {
			return a?.additionalDetails?.localeCompare(b?.additionalDetails)
		})
		return mappedTerminals
	},
})

/* ============================================================================================== */
/* ===================================== PORTS SELECTOR ========================================= */
/* ============================================================================================== */

export const PortSelector = selector({
	key: 'PortSelector',
	get: ({ get }) => {
		const allPorts = get(AllAtoms.portsAtom)
		const mappedPorts = {}
		allPorts?.forEach((portAddress) => {
			mappedPorts[portAddress?.additionalDetails] = { ...portAddress }
		})
		return mappedPorts
	},
})

/* ============================================================================================== */
/* ================================= PARENT REGION SELECTOR ===================================== */
/* ============================================================================================== */

export const ParentRegionSelector = selectorFamily({
	key: 'ParentRegionSelector',
	get:
		(props) =>
		({ get }) => {
			const { currParentRegion, keyword, childKeyword } = props
			const parentRegions = get(AllAtoms.parentRegionAtom)?.filter((region) => region?.name?.toLowerCase()?.includes(keyword?.toLowerCase()))
			const selectedRegion = parentRegions?.find((region) => region?._id === currParentRegion?._id)
			const childRegions = selectedRegion?.AssociatedRegions?.filter((region) => region?.name?.toLowerCase()?.includes(childKeyword?.toLowerCase()))

			return [parentRegions, childRegions]
		},
})

export const RegionSelector = selectorFamily({
	key: 'ParentRegionSelector',
	get:
		(props) =>
		({ get }) => {
			const { currChildRegion } = props
			const parentRegions = get(AllAtoms.parentRegionAtom)
			// const corporateLocations = get(AllAtoms.corporateLocationAtom)
			const regionParents = parentRegions?.filter((region) => region?.AssociatedRegions?.some((subRegion) => subRegion?.name === currChildRegion))
			const allChildRegions = parentRegions?.map((region) => region?.AssociatedRegions)?.flat()
			const uniqueChildRegions = [...new Map(allChildRegions.map((obj) => [obj.name, obj.name])).values()]
			return [regionParents, uniqueChildRegions, allChildRegions]
		},
})

/* ============================================================================================== */
/* ======================================== CLIENTS SELECTOR ==================================== */
/* ============================================================================================== */

export const ClientsSelector = selectorFamily({
	key: 'ClientsSelector',
	get:
		(props) =>
		({ get }) => {
			const { currentClient, keyword, corporateKeyword } = props
			const clients = get(AllAtoms.clientsAtom)
				?.filter((client) => client?.User?.email?.includes(keyword?.toLowerCase()))
				?.map((client) => ({ ...client, email: client?.User?.email }))
			const selectedClient = clients?.find((client) => client?._id === currentClient?._id)
			const selectedClientCorporates = selectedClient?.Corporate?.filter((corporate) =>
				corporate?.businessCode?.toLowerCase()?.includes(corporateKeyword?.toLowerCase())
			)

			return { clients, selectedClientCorporates }
		},
})

/* ============================================================================================== */
/* ============================= FORECASTED REVENUE TABLE AND CHART ============================= */
/* ============================================================================================== */
export const ForecastedRevenueSelector = selectorFamily({
	key: 'ForecastedRevenueSelector',
	get:
		(props) =>
		({ get }) => {
			const revenueMonth = props.revenueMonth

			const getMappedRevenueObject = (corporate, segment) => ({
				...corporate?.forecastedRevenue?.[revenueMonth]?.[segment],
				_id: corporate._id,
				segment: segment,
				key: `${corporate._id}-${revenueMonth}-${segment}`,
				businessCode: corporate.businessCode,
				businessName: corporate.businessName,
				businessNameCode: `${corporate.businessName} (${corporate.businessCode})`,
				month: revenueMonth,
			})

			const forecastedRevenue = get(CorporatesSelector)
				?.map((corporate) => {
					const corporateRevenues = []
					corporateRevenues.push(getMappedRevenueObject(corporate, 'Import'))
					corporateRevenues.push(getMappedRevenueObject(corporate, 'Export'))
					corporateRevenues.push(getMappedRevenueObject(corporate, 'LongHaul'))
					return corporateRevenues
				})
				?.flat()

			return forecastedRevenue
		},
})

export const ForecastedRevenueChartSelector = selectorFamily({
	key: 'ForecastedRevenueChartSelector',
	get:
		(props) =>
		({ get }) => {
			const revenueMonth = props.revenueMonth

			const getMappedRevenueObject = (corporate, segment) =>
				corporate?.forecastedRevenue?.[revenueMonth]?.[segment] && {
					...corporate?.forecastedRevenue?.[revenueMonth]?.[segment],
					segment: segment,
				}

			const forecastedRevenue = get(CorporatesSelector)
				?.map((corporate) => {
					const corporateRevenues = []
					corporateRevenues.push(getMappedRevenueObject(corporate, 'Import'))
					corporateRevenues.push(getMappedRevenueObject(corporate, 'Export'))
					corporateRevenues.push(getMappedRevenueObject(corporate, 'LongHaul'))
					return corporateRevenues
				})
				?.flat()
				?.filter(Boolean)

			const groupedByKAM = groupByProperty(forecastedRevenue, 'keyAccountManager')

			const KAMRevenue = Object.values(groupedByKAM)?.map((managerRevenues) => {
				const groupedBySegment = groupByProperty(managerRevenues, 'segment')
				const importRevenue = groupedBySegment?.Import?.reduce((total, value) => total + parseFloat(value.forecastedRevenue), 0) || 0
				const exportRevenue = groupedBySegment?.Export?.reduce((total, value) => total + parseFloat(value.forecastedRevenue), 0) || 0
				const longHaulRevenue = groupedBySegment?.LongHaul?.reduce((total, value) => total + parseFloat(value.forecastedRevenue), 0) || 0
				return {
					keyAccountManager: managerRevenues?.[0]?.keyAccountManager,
					importRevenue,
					exportRevenue,
					longHaulRevenue,
				}
			})

			return KAMRevenue
		},
})
