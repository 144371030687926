import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GraphQlEnum } from '../utils/Enums'
import { updateData } from '../utils/Functions'

import * as AllAtoms from '../recoil/Atoms'
import { useSetRecoilState } from 'recoil'

const useFetchQuery = (props) => {
	const { segment, backendPageInfo, variables, subscriptionVariables, state, subscription } = props
	const atomName = GraphQlEnum[segment]?.atoms[state]
	const segmentAtom = AllAtoms[atomName]
	const query = GraphQlEnum[segment]?.fetch?.queryName
	const getDataOutput = GraphQlEnum[segment]?.fetch?.queryOutput
	const setOrdersAtom = useSetRecoilState(segmentAtom)

	const { where, options, conditions } = variables ? GraphQlEnum[segment]?.fetch?.getVariables({ ...variables }) : {}
	const paginatedOptions = backendPageInfo
		? {
				where,
				...conditions,
				options: {
					...options,
					limit: backendPageInfo.pageSize,
					offset: (backendPageInfo.pageNo - 1) * backendPageInfo?.pageSize,
				},
		  }
		: null

	const { data, loading, error, fetchMore, subscribeToMore } = useQuery(query, {
		variables: paginatedOptions || { where, options, ...conditions },
		// notifyOnNetworkStatusChange: true,
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'cache-and-network',
	})

	useEffect(() => {
		if (subscription) {
			return subscribeToMore({
				document: GraphQlEnum[segment].subscriptionName,
				...(subscriptionVariables && { variables: { ...subscriptionVariables, ...conditions } }),
				updateQuery: (prev, { subscriptionData }) => {
					return updateData(prev, subscriptionData, segment, atomName, setOrdersAtom)
				},
			})
		}
	}, [subscription, segment]) //eslint-disable-line

	useEffect(() => {
		fetchMore({ paginatedOptions })
	}, [backendPageInfo]) //eslint-disable-line

	useEffect(() => {
		if (error) {
			throw error
		}
		// console.log('apolloData', data)
		data && setOrdersAtom(data?.[getDataOutput])
	}, [data, error]) //eslint-disable-line

	return { loading, data }
}

export default useFetchQuery
