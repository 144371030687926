import React, { useMemo } from 'react'
import { Button } from 'antd'
import { RenderSchema } from '../../utils/FormRenderer'
import { PortsSchema, LocationsSchema } from '../../utils/FormSchemas'
import { formatAddresses } from '../../utils/Functions'
import Icon from '@mdi/react'
import { mdiMapMarker, mdiMapMarkerPlusOutline } from '@mdi/js'

const LocationsCard = (props) => {
	const { segment, action, form, corporate, terminals, locationsCounter, setLocationsCounter } = props
	const locationPropsEnum = useMemo(
		() => ({
			Import: {
				loading: { title: 'Port Information', multiple: false, type: 'loading', text: 'Loading', schema: PortsSchema },
				dropoff: { title: 'Dropoff Information', multiple: true, type: 'dropoff', text: 'Dropoff', schema: LocationsSchema },
			},
			Export: {
				terminal: { title: 'Release Terminal', multiple: false, type: 'terminal', text: 'Terminal', schema: LocationsSchema },
				loading: { title: 'Loading Information', multiple: true, type: 'loading', text: 'Loading', schema: LocationsSchema },
				dropoff: { title: 'Port Information', multiple: false, type: 'dropoff', text: 'Dropoff', schema: PortsSchema },
			},
			LongHaul: {
				loading: { title: 'Loading Information', multiple: false, type: 'loading', text: 'Loading', schema: LocationsSchema },
				dropoff: { title: 'Dropoff Information', multiple: true, type: 'dropoff', text: 'Dropoff', schema: LocationsSchema },
			},
		}),
		[]
	)

	const removeLocation = (type, index) => {
		const locations = form.getFieldValue(type)
		locations.splice(index, 1)
		setLocationsCounter((prevState) => prevState - 1)
		form.setFieldsValue({ [type]: locations })
	}

	const HeaderBar = ({ title, type, index, multiple }) => {
		const PinColor = type === 'loading' ? 'PBackground' : type === 'dropoff' ? 'EBackground' : 'GBackground'
		return (
			<div className={`FlexRowSpace GHBackground BorderBottom PaddingLeftRight`} style={{ height: 48 }}>
				<div className='FlexRow'>
					<div className={`FlexRowBox HalfMarginRight CircleRadius ${PinColor}`} style={{ width: 16, height: 16 }}>
						<Icon path={mdiMapMarker} size={0.5} className='WhiteColor' />
					</div>
					<p className='BoldFont'>{multiple && locationsCounter > 1 ? `${title} # ${index + 1}` : title}</p>
				</div>
				{multiple && locationsCounter > 1 && (
					<Button className='Button EHButton' onClick={() => removeLocation(type, index)}>
						Remove
					</Button>
				)}
			</div>
		)
	}

	const LocationCard = (type, action) => {
		const locationProps = locationPropsEnum[segment][type]
		const options = formatAddresses(
			corporate?.CorporateLocations?.map((corpLocation) => ({ ...corpLocation?.Location, contacts: corpLocation.contacts })), action
		)
		const containerTerminals = terminals?.map((terminal) => ({ formattedAddress: terminal?.formattedAddress, _id: terminal?._id }))

		return locationProps?.multiple ? (
			<>
				{[...Array(locationsCounter)].map((_, index) => (
					<div key={index}>
						{HeaderBar({ ...locationProps, index })}
						<div className='Padding'>{RenderSchema(locationProps.schema({ form, type, index, options, action }))}</div>
					</div>
				))}
				{locationProps.multiple && action === 'create' && (
					<div className='FullWidth FlexRow' style={{ justifyContent: 'flex-end', padding: '0px 24px 24px' }}>
						<Button className='Button DynamicButton2 FlexRow' onClick={() => setLocationsCounter((prevState) => prevState + 1)} block>
							<Icon path={mdiMapMarkerPlusOutline} size={0.8} className='QuarterMarginRight' />
							Add Another {locationProps?.text} Location
						</Button>
					</div>
				)}
			</>
		) : (
			<>
				{HeaderBar(locationProps)}
				<div className='Padding'>{RenderSchema(locationProps.schema({ form, type, index: 0, options, terminals: containerTerminals, action }))}</div>
			</>
		)
	}

	return (
		<>
			<p className='GrayColor HalfMarginBottom'>Specify Pickup and Drop-off locations</p>
			<div className='WBackground Border BorderRadius' style={{ marginBottom: 48 }}>
				{segment === 'Export' && LocationCard('terminal', action)}
				{LocationCard('loading', action)}
				{LocationCard('dropoff', action)}
			</div>
		</>
	)
}

export default LocationsCard
