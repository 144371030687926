import { FormRender, RenderSchema } from '../../utils/FormRenderer'
import { ShiftingRateCalculationSchema } from '../../utils/FormSchemas'

export const ShiftingRateCalculationCard = (props) => {
	const { form } = props
	const quotedRateUnit = form?.getFieldValue(['pricingInfo', 'quotedRateUnit'])
	const finalizedRateUnit = form?.getFieldValue(['pricingInfo', 'finalizedRateUnit'])
	const vehicleDetails = form?.getFieldValue(['vehicleDetails'])
	const totalWeight = form?.getFieldValue('grossFreightWeight')
	const containersSpecifics = Object.values(form?.getFieldValue(['containersSpecifics']))?.filter((container) => container?.quantity)
	const containerCosts = containersSpecifics?.reduce(
		(prev, curr) => prev + curr?.quantity * (parseFloat(curr?.labourCost || 0) + parseFloat(curr?.shiftingCost || 0) + parseFloat(curr?.doCost || 0)),
		0
	)
	// const totalWeight = containersSpecifics?.reduce((prev, curr) => prev + curr?.freightWeight * curr?.quantity, 0)
	const totalContainers = containersSpecifics?.reduce((prev, curr) => prev + curr?.quantity, 0)
	const totalVehicles = vehicleDetails?.reduce((prev, curr) => prev + parseInt(curr?.quantity), 0)
	const vehicleCosts = vehicleDetails?.reduce((prev, curr) => prev + curr?.quantity * curr?.quotedRate, 0)
	const quotedRatePerUnit =
		(vehicleCosts + containerCosts) / (finalizedRateUnit === 'Tonne' ? totalWeight : finalizedRateUnit === 'Container' ? totalContainers : totalVehicles)
	const percentageMargin = ((form?.getFieldValue(['pricingInfo', 'finalizedRate']) - quotedRatePerUnit) * 100) / quotedRatePerUnit
	return (
		<>
			<div className='mb-3 text-base font-medium leading-5 text-gray-500'>Rate Calculation</div>
			<div className='mb-12 rounded-lg border bg-white'>
				<div className='m-6 flex flex-col justify-items-center'>
					{RenderSchema(ShiftingRateCalculationSchema())}
					<div className='flex flex-row'>
						<div className='ml-4  mr-8 flex flex-col'>
							<div className='mt-1 mb-3 text-sm font-medium text-gray-500'>Supply Quoted Rate</div>
							{quotedRatePerUnit && quotedRateUnit !== 'Tonne' ? (
								<div>
									<span className='mr-2 text-xl font-bold'>{parseFloat(quotedRatePerUnit)?.toFixed(1)}</span>
									<span className='text-xs font-semibold text-gray-500'>PKR / {finalizedRateUnit}</span>
								</div>
							) : (
								<div className='mr-2 self-center text-sm font-semibold'>---</div>
							)}
						</div>
						<div className='slef-center'>
							{FormRender({
								type: 'number',
								label: 'Enter Revenue',
								name: ['pricingInfo', 'finalizedRate'],
								inputType: 'number',
								required: true,
								message: 'Revenue is required.',
								minValue: 1,
								addonAfter: `PKR / ${finalizedRateUnit}`,
							})}
						</div>
						<div className='self-center'>
							<div className='mx-2 mb-1 self-end font-medium'>@</div>
						</div>
						<div className='self-center'>
							<div
								className={`mx-2 rounded-lg border py-[1px] px-2 font-medium ${
									percentageMargin > 0
										? 'border-green-500 bg-green-200 text-green-700'
										: percentageMargin < 0
										? 'border-red-500 bg-red-200 text-red-700'
										: 'border-yellow-500 bg-yellow-200 text-yellow-700'
								}`}
							>
								{percentageMargin ? parseFloat(percentageMargin)?.toFixed(1) : 0}%
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ShiftingRateCalculationCard
