/* ============================================================================================== */
/* ====================================== GRAPHQL mutation ====================================== */
/* ============================================================================================== */

export const mutateGraphQL = async (props) => {
	const { mutationData, mutationData2, mutateFunction, mutationInputFunction, mutationInputFunction2, mutationOutputFunction } = props
	const apolloData = await mutateFunction({
		variables: {
			[mutationInputFunction]: mutationData,
			...(mutationInputFunction2 && { [mutationInputFunction2]: mutationData2 }),
		},
	})
	return apolloData?.data?.[mutationOutputFunction]
}
