import React from 'react'
import { RenderSchema } from '../../utils/FormRenderer'
import { ClearingAgentsSchema  } from '../../utils/FormSchemas'
import Icon from '@mdi/react'
import { mdiAccountGroup } from '@mdi/js'

const ClearingAgentsCard = (props)  => {
	return (
        <>
            <div className='WBackground Border BorderRadius' style={{marginBottom: 48}}>
                <div className={`FlexRow GHBackground BorderBottom PaddingLeftRight`} style={{height: 48}}>
                    <Icon path={mdiAccountGroup} size={1} className='PrimaryColor HalfMarginRight'/>
                    <p className='BoldFont'>Clearing Agents</p>
                </div>
                <div className='Padding'>
                    {RenderSchema(ClearingAgentsSchema())}
                </div>
            </div>
        </>
	)
}

export default ClearingAgentsCard