import React from 'react'
import { Form, Divider } from 'antd'
import { RenderSchema } from '../../utils/FormRenderer'
import { OrderTypeSchema, CargoInfoSchema } from '../../utils/FormSchemas'
import { ContainerDimensionsEnum } from '../../utils/Enums'
import ContainerQuantity from './ContainerQuantity'

const ContainerInfo = (props) => {
	const { segment, action, form, containerCounter, setContainerCounter } = props
	return (
		<>
			<p className='GrayColor HalfMarginBottom'>Select Order type and Container Dimensions</p>
			<div className='WBackground Border BorderRadius' style={{ marginBottom: 48 }}>
				<div className='px-6'>
					{segment === 'Import' && (
						<>
							<div className='mt-6'>{RenderSchema(OrderTypeSchema({ action, form }))}</div>
							<Divider dashed />
						</>
					)}
					<div className='mt-6 font-bold GrayColor'>Select Container Dimensions</div>
					<Form.Item
						key='containersSpecifics'
						name='containersSpecifics'
						rules={[
							{
								validator: async () => {
									if (Object.values(containerCounter).some((count) => count > 0)) {
										Promise.resolve()
									} else throw new Error('Container specifics are required.')
								},
							},
						]}
						className='NoMargin'
					>
						<Form.Item shouldUpdate={(prevValues, currentValues) => prevValues?.specialRequests?.reefer !== currentValues?.specialRequests?.reefer} noStyle>
							{({ getFieldValue }) => {
								const dimensions = Object.values(ContainerDimensionsEnum(segment))
								return dimensions?.map((container) => (
									<ContainerQuantity
										key={container.key}
										segment={segment}
										form={form}
										action={action}
										container={container}
										containerCounter={containerCounter}
										setContainerCounter={setContainerCounter}
									/>
								))
							}}
						</Form.Item>
					</Form.Item>
					<div>
						<Divider dashed />
						{RenderSchema(CargoInfoSchema({ segment, form }))}
					</div>
				</div>
			</div>
		</>
	)
}

export default ContainerInfo
