// REACT
import React, { useState, useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

// DESIGN
import { Modal, Form, Button, Tooltip, notification } from 'antd'

// UTILS
import { RenderSchema } from '../utils/FormRenderer'
import { ConnectClientsCorporatesSchema, CreateOrderSchema, CreateRegionSchema } from '../utils/FormSchemas'
import { CardHeader } from '../utils/Functions'

// RECOIL
import { CorporatesSelector } from '../recoil/Selectors'
import { useRecoilValue } from 'recoil'

// GRAPHQL
import useMutate from '../hooks/useMutate'
import { mutateGraphQL } from '../services/MutateGraphQl'

const DynamicModal = () => {
    const { type, checkDisabled, data } = useOutletContext()
	const [loading, setLoading] = useState(false)
	const [disableButton, setDisableButton] = useState(false)

    const navigate = useNavigate()

	const mutateRegions = useMutate({ segment: 'ParentRegions', action: 'create', segmentQuery: 'ParentRegions', state: 'normal' })
	const mutateConnectClient = useMutate({ segment: 'Clients', action: 'connect', segmentQuery: 'Clients', state: 'normal' })

	const [form] = Form.useForm()
	const formWatch = Form.useWatch(undefined, form)
	const corporates = useRecoilValue(CorporatesSelector)

	useEffect(() => {
		if (type === 'createParentRegion' || type === 'createChildRegion') {
			setDisableButton(checkDisabled({ formWatch, type, data: data }))
		}
	}, [formWatch]) // eslint-disable-line

	const CreateNewRegion = async ({ regionType, values }) => {
		setLoading(true)
		const region = await mutateGraphQL({
			mutateFunction: mutateRegions?.mutateFunction,
			mutationInputFunction: mutateRegions?.mutationInputFunction,
			mutationOutputFunction: mutateRegions?.mutationOutputFunction,
			mutationData: {
				name: values?.name,
				type: regionType === 'parent' ? 'Parent' : 'Child',
				...(regionType === 'parent' ? {} : { ParentRegionId: values?.parentRegion }),
			},
		})
		setLoading(false)
		notification[region ? 'success' : 'error']({
			message: region ? `New Region Created Succesfully` : 'Failed To Create Region',
			description: region
				? regionType === 'parent'
					? `${region.name} was added as a new Parent Region. Kindly Refresh Portal to view`
					: `${region.name} was added as a child of ${region.ParentRegion?.name}. Kindly Refresh Portal to view`
				: 'Failed to create Region. Kindly contact the Product team ',
			duration: '5',
		})
		onCancel()
	}

	const ConnectClientCorporate = async ({ values }) => {
		setLoading(true)
		const client = await mutateGraphQL({
			mutateFunction: mutateConnectClient?.mutateFunction,
			mutationInputFunction: mutateConnectClient?.mutationInputFunction,
			mutationOutputFunction: mutateConnectClient?.mutationOutputFunction,
			mutationData: { ClientId: values?.client, CorporateId: values?.businessCode },
		})
		setLoading(false)
		notification[client ? 'success' : 'error']({
			message: client ? `Business Code Added Sucessfully` : 'Failed To Add Business Code',
			description: client
				? `Business Code was Added for ${client?.User?.firstName + ' ' + client?.User?.lastName}`
				: 'Failed to Add Business Code. Kindly contact the Product team ',
			duration: '5',
		})
		onCancel()
	}

	const SaveImportCreateInfo = (values) => {
		setLoading(false)
		form.resetFields()
        navigate(`/home/orders/${values.segment}/create`, {
            state: values,
        })
	}

	const onCancel = () => {
		form.resetFields()
		navigate(-1)
	}

	const ModalFooter = () => (
		<div className='FullWidth FlexRowEnd'>
			<Button className='Button SecondaryButton' style={{ height: 40 }} onClick={onCancel}>
				CANCEL
			</Button>
			{type === 'createParentRegion' || type === 'createChildRegion' ? (
				<div style={{ paddingLeft: '16px' }}>
					<Tooltip title={disableButton ? 'Another Region Exists With The Same Name' : null}>
						<Button className='TooltipButton SuccessButton' style={{ height: 40 }} onClick={() => form.submit()} loading={loading} disabled={disableButton}>
							SUBMIT
						</Button>
					</Tooltip>
				</div>
			) : (
				<Button className='Button SuccessButton' style={{ height: 40 }} onClick={() => form.submit()} loading={loading}>
					SUBMIT
				</Button>
			)}
		</div>
	)

	const ModalTypes = {
		createOrder: {
			modalSize: 'SquareModal',
			title: 'Create Order',
			schema: RenderSchema(CreateOrderSchema({ corporates: corporates })),
			finishFunction: SaveImportCreateInfo,
		},
		createParentRegion: {
			modalSize: 'SquareModal',
			title: 'Create Parent Region',
			schema: RenderSchema(CreateRegionSchema({ regionType: 'parent' })),
			finishFunction: (values) => CreateNewRegion({ values, regionType: 'parent' }),
		},
		createChildRegion: {
			modalSize: 'SquareModal',
			title: 'Create Child Region',
			schema: RenderSchema(CreateRegionSchema({ regionType: 'child', parentRegion: data?.currParentRegion, allParentRegions: data?.parentRegions })),
			finishFunction: (values) => CreateNewRegion({ values, regionType: 'child' }),
		},
		connectClientCorporates: {
			modalSize: 'SquareModal',
			title: 'Connect Corporate to Client',
			schema: RenderSchema(ConnectClientsCorporatesSchema({ form, client: data?.currentClient, allClients: data?.clients, allCorporates: corporates })),
			finishFunction: (values) => ConnectClientCorporate({ values }),
		},
	}

	return (
        <Modal
            open={true}
            title={CardHeader(ModalTypes[type]?.title, ModalTypes[type]?.description)}
            closable={false}
            className={ModalTypes[type]?.modalSize}
            footer={<ModalFooter />}
            centered={true}
        >
            <Form key='ModalForm' layout='vertical' form={form} onFinish={ModalTypes[type] ? ModalTypes[type]?.finishFunction : onCancel} scrollToFirstError>
                {ModalTypes[type]?.schema}
            </Form>
        </Modal>
	)
}

export default DynamicModal
