import { gql } from '@apollo/client'
import { CORPORATE_FIELDS, CONTACT_FIELDS, INVOICING_DETAILS, CORPORATE_LOCATIONS_FIELDS, ORDER_FIELDS } from './fragments'

export const SUBSCRIBE_ALL_CORPORATES = gql`
	${CORPORATE_FIELDS}
	${CONTACT_FIELDS}
	${INVOICING_DETAILS}
	${CORPORATE_LOCATIONS_FIELDS}
	subscription CorporateUpdated {
		corporateUpdated {
			...CorporateFields
			invoicingDetails {
				...InvoicingDetailsFields
			}
			clearingAgents {
				...ContactFields
			}
			CorporateLocations {
				...CorporateLocationsFields
			}
		}
	}
`
export const SUBSCRIBE_ORDERS = gql`
	${ORDER_FIELDS}
	subscription OrderUpdatedByStatusAndTypeAndBusinessCode(
		$status: [String!]
		$type: String
		$Import: Boolean!
		$Export: Boolean!
		$LongHaul: Boolean!
	) {
		OrderUpdatedByStatusAndTypeAndBusinessCode(status: $status, type: $type) {
			...OrderFields
		}
	}
`
