// REACT
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import { Form, Layout, Button, notification, Modal } from 'antd'

// RECOIL
import { useRecoilState, useRecoilValue } from 'recoil'
import { userAtom, corporatesAtom, shippingLinesAtom } from '../recoil/Atoms'
import { EmptyContainerSelector, PortSelector } from '../recoil/Selectors'

// UTILS
import { cancelOrder, createOrder } from '../utils/Functions'
import { getNewOrderInitialValues, getPendingOrderInitialValues } from '../utils/PrepareInitialValues'

//COMPONENTS
import LocationsCard from '../components/newOrder/LocationsCard'
import ShipmentDetails from '../components/newOrder/ShipmentDetails'
import ContainerInfoCard from '../components/newOrder/ContainerInfoCard'
import ContainerDetailsCard from '../components/newOrder/ContainerDetailsCard'
import FreightDetails from '../components/newOrder/FreightDetails'
import VehicleInfoCard from '../components/newOrder/VehicleInfoCard'
import MiscellaneousCard from '../components/newOrder/MiscellaneousCard'
import OrderSummary from '../components/OrderSummary'
import BulkSubOrderDetails from '../components/newOrder/BulkSubOrderDetails'
import SelectVehiclesCard from '../components/newOrder/SelectVehiclesCard'
import ShiftingRateCalculationCard from '../components/newOrder/ShiftingRateCalculationCard'
import LotRateCalculationCard from '../components/newOrder/LotRateCalculationCard'

// FIREBASE
import { firestore } from '../firebase/firebaseConfig'

// HOOKS
import useMutate from '../hooks/useMutate'
import useFetchQuery from '../hooks/useFetchQuery'

import { v4 as uuidv4 } from 'uuid'

// ERROR BOUNDARY
import FallBackUI from '../components/errors/FallBackUI'

const { Content } = Layout

const NewOrder = (props) => {
	const naviagte = useNavigate()
	const { pathname, state } = useLocation()
	const segment = useMemo(() => pathname?.split('/')?.[3], [pathname])
	const action = useMemo(() => {
		const split = pathname?.split('/')
		return split?.[split.length - 1]
	}, [pathname])
	const uuid = useMemo(() => uuidv4(), [])
	const user = useRecoilValue(userAtom)
	const corporates = useRecoilValue(corporatesAtom)
	const terminals = useRecoilValue(EmptyContainerSelector)
	const ports = useRecoilValue(PortSelector)
	const [shippingLines, setShippingLinesAtom] = useRecoilState(shippingLinesAtom)

	const [order, setOrder] = useState()
	const [loading, setLoading] = useState(false)
	const [corporate, setCorporate] = useState({})
	const [containers, setContainers] = useState([])
	const [lotOrder, setLotOrder] = useState(true)
	const [lotSelectedVehicle, setLotSelectedVehicle] = useState('')
	const [vehicleCounter, setVehicleCounter] = useState(1)
	const [lotAssignedWeight, setLotAssignedWeight] = useState(0)
	const [SDorder, setSDorder] = useState(false)
	const [destuffingOrder, setDestuffingOrder] = useState(false)
	const [locationsCounter, setLocationsCounter] = useState(1)
	const [modalData, setModalData] = useState({ visible: false })
	const initialCounterState = { xl20ft: 0, xl40ft: 0, xl40ftHC: 0, xl40ftOT: 0, other: 0 }
	const [containerCounter, setContainerCounter] = useState(initialCounterState)
	const [allowExecution, setAllowExecution] = useState({})
	const resolveExecution = useCallback(() => allowExecution?.resolve(), [allowExecution])
	const rejectExecution = useCallback(() => allowExecution?.reject('hideWarning'), [allowExecution])
	const [showWarning, setShowWarning] = useState(false)

	const editOrderMutation = useMutate({ segment, action })
	const cancelOrderMutation = useMutate({ segment, action: 'cancel' })
	useFetchQuery({ segment: 'Corporates', state: 'normal', subscription: true })
	useFetchQuery({ segment: 'EmptyTerminals', state: 'normal', variables: { type: 'Terminal' } })
	useFetchQuery({ segment: 'Ports', state: 'normal', variables: { type: 'Port' } })

	const [form] = Form.useForm()
	const { useWatch } = Form
	const watcher = useWatch(undefined, form)

	const warningButtons = [
		{
			type: 'custom',
			title: 'CANCEL',
			className: 'Button EHButton',
			function: rejectExecution,
		},
		{ type: 'custom', title: 'CONTINUE', className: 'Button PrimaryButton', function: resolveExecution },
	]
	const WarningFooter = () => (
		<div className='w-full items-center justify-end flex flex-row' key='footer'>
			{warningButtons.map((button, index) => {
				return (
					<Button className={button?.className} style={{ height: 40 }} onClick={() => button.function()} key={'warningButton' + index}>
						{button?.title}
					</Button>
				)
			})}
		</div>
	)

	const WarningModal = () => (
		<Modal
			title='Order Cancellation Warning'
			key={'warningModal'}
			className='WarningModal'
			visible={showWarning}
			onCancel={() => setShowWarning(false)}
			footer={<WarningFooter />}
			centered
			width='400px'
			bodyStyle={{ backgroundColor: '#FFFFFF' }}
		>
			<Layout className='WBackground'>
				<Content>
					<div>Are You Sure You Want to Cancel This Order</div>
				</Content>
			</Layout>
		</Modal>
	)

	useEffect(() => {
		if (watcher && watcher?.containersSpecifics) {
			const totals = []
			Object.keys(watcher?.containersSpecifics)?.forEach((idx) => {
				totals.push(watcher?.containersSpecifics[idx]?.quantity * parseFloat(watcher?.containersSpecifics[idx]?.freightWeight))
			})
			const grossTotals = totals?.filter((value) => value)?.reduce((prev, curr) => (curr = curr + prev), 0)
			action === 'create' && form?.setFieldsValue({ grossFreightWeight: parseFloat(grossTotals)?.toFixed(2) })
		}
		if (watcher && ['Shifting', 'Destuffing']?.includes(watcher?.specialRequests?.orderCategory)) {
			setSDorder(true)
		}
		if (watcher && !['Shifting', 'Destuffing']?.includes(watcher?.specialRequests?.orderCategory)) {
			setSDorder(false)
		}
		if (watcher && ['Shifting', 'Destuffing', 'ImportShortHaul']?.includes(watcher?.specialRequests?.orderCategory)) {
			form?.setFieldsValue({
				specialRequests: {
					doubleTwenty: false,
				},
				containersSpecifics: {
					...watcher?.containersSpecifics,
					xl2020ft: undefined,
				},
			})
		}
		if (watcher && ['shifting', 'destuffing']?.includes(watcher?.specialRequests?.orderCategory) && watcher?.pricingInfo?.quotedRateUnit) {
			let vehicleDetails = watcher?.vehicleDetails
			Object?.keys(vehicleDetails)?.forEach(
				(index) => (vehicleDetails[index] = { ...vehicleDetails[index], quotedRateUnit: watcher?.pricingInfo?.quotedRateUnit })
			)
			form.setFieldsValue({
				vehicleDetails: vehicleDetails,
			})
		}
		if (watcher && watcher?.containersSpecifics?.['xl20ft']?.quantity < 2) {
			form?.setFieldsValue({
				specialRequests: {
					...watcher?.specialRequests,
					doubleTwenty: false,
				},
				containersSpecifics: {
					...watcher?.containersSpecifics,
					xl2020ft: undefined,
				},
			})
		}
		if (watcher && watcher?.specialRequests?.doubleTwenty === false) {
			form?.setFieldsValue({
				containersSpecifics: {
					...watcher?.containersSpecifics,
					xl2020ft: undefined,
				},
			})
		}
	}, [watcher]) // eslint-disable-line

	useEffect(() => {
		const id = state?.record?._id
		setOrder({ _id: id, ...(state?.record || {}) })
		getShippingLinesAndTerminals()
	}, []) // eslint-disable-line

	useEffect(() => {
		const businessCode = state?.businessCode
		const corporateData = corporates?.find((corporate) => corporate.businessCode === businessCode)
		if (corporateData) {
			setInitialValues(corporateData, state?.record, ports)
			setCorporate(corporateData)
		}
	}, [corporates, ports]) // eslint-disable-line

	const getShippingLinesAndTerminals = async () => {
		const query = await firestore.collection('config').doc('portVicinities').get()
		const sortedShippingLines = query?.data()?.shippingLines?.sort((a, b) => a?.localeCompare(b))
		query?.data()?.shippingLines && setShippingLinesAtom([...sortedShippingLines, 'Other'])
	}
	const setInitialValues = (corporate, order, ports) => {
		const initialValues = order ? getPendingOrderInitialValues(order, action) : getNewOrderInitialValues(corporate, segment, ports, action)
		form.setFieldsValue(initialValues)
		setVehicleCounter(initialValues?.vehicleDetails?.length || 1)
		setLotSelectedVehicle(initialValues?.vehicleInfo?.vehicleType)
		setLotOrder(initialValues?.specialRequests?.lotOrder === undefined ? true : initialValues?.specialRequests?.lotOrder)
		setLotAssignedWeight(initialValues?.lotAssignedWeight)
		setContainerCounter(initialValues.containerCounter)
		setDestuffingOrder(order?.specialRequests?.orderCategory === 'Destuffing')
		setContainers(order?.scraper?.containers || [])
		setLocationsCounter(order ? (segment === 'Export' ? initialValues?.loading?.length : initialValues?.dropoff?.length || 1) : 1)
	}

	const onFormValuesChange = (changedValues) => {
		if (changedValues.specialRequests?.lotOrder !== undefined) {
			setLotOrder(changedValues.specialRequests?.lotOrder)
		}
		if (changedValues?.freightType) {
			let containerInfo = JSON.parse(JSON.stringify(form.getFieldValue('containerInfo') || {}))
			Object.entries(containerInfo).forEach(([key, values]) => {
				containerInfo[key] = values?.map((value) => ({ ...value, freightType: changedValues.freightType }))
			})
			containerInfo && form.setFieldsValue({ containerInfo: containerInfo })
			if (destuffingOrder) {
				const flatbedInfo = form?.getFieldsValue()?.flatbedInfo
				const newflatbedInfo = flatbedInfo?.map((flatbed) => ({ ...flatbed, freightType: changedValues.freightType }))
				flatbedInfo && form.setFieldsValue({ flatbedInfo: newflatbedInfo })
			}
		} else if (changedValues?.containersSpecifics) {
			const changes = Object?.entries(changedValues?.containersSpecifics)?.flat()
			let containerInfo = JSON.parse(JSON.stringify(form.getFieldValue('containerInfo') || {}))
			containerInfo[changes[0]] = containerInfo?.[changes[0]]?.map((entry) => ({ ...entry, ...changes[1] }))
			containerInfo && form.setFieldsValue({ containerInfo })
		} else if (changedValues.specialRequests?.orderCategory) {
			let flatbedsCount = null
			if (['Destuffing'].includes(changedValues.specialRequests?.orderCategory)) {
				flatbedsCount = Math.floor(Object.values(containerCounter).reduce((a, b) => a + b, 0) / 2) || 0
				setDestuffingOrder(true)
			} else {
				setDestuffingOrder(false)
			}
			form.setFieldsValue({ specialRequests: { ...changedValues.specialRequests, ...(flatbedsCount !== null && { numberFlatbeds: flatbedsCount }) } })
		} else if (changedValues.specialRequests?.doIssuance !== undefined && changedValues.specialRequests?.doIssuance === false) {
			let containersSpecifics = form?.getFieldValue(['containersSpecifics'])
			Object?.keys(containersSpecifics)?.forEach(
				(containerKey) => (containersSpecifics[containerKey] = { ...containersSpecifics[containerKey], doCost: null })
			)
			form.setFieldsValue({
				containersSpecifics: containersSpecifics,
			})
		} else if (changedValues.ratePerFlatbed) {
			const flatbedInfo = form?.getFieldsValue()?.flatbedInfo
			const newflatbedInfo = flatbedInfo?.map((flatbed) => ({ ...flatbed, finalizedRate: changedValues.ratePerFlatbed }))
			flatbedInfo && form.setFieldsValue({ flatbedInfo: newflatbedInfo })
		} else if (changedValues.containerInfo && state?.record?.scraper?.containers) {
			const changedContainerType = Object.keys(changedValues.containerInfo)?.[0]
			const changedObjectIndex = changedValues?.containerInfo?.[changedContainerType]?.length - 1
			const changedValueKey = Object.keys(changedValues.containerInfo?.[changedContainerType]?.[changedObjectIndex])?.[0]
			const scraperContainers = state?.record?.scraper?.containers
			const containerInfo = JSON.parse(JSON.stringify(form.getFieldValue('containerInfo')))

			if (changedValueKey === 'containerNumber') {
				containerInfo[changedContainerType][changedObjectIndex] = {
					...containerInfo[changedContainerType][changedObjectIndex],
					...JSON.parse(containerInfo[changedContainerType][changedObjectIndex].containerNumber),
				}
			} else if (changedValueKey === 'containerNumber2') {
				const fetchedInfo = JSON.parse(containerInfo[changedContainerType][changedObjectIndex].containerNumber2)
				containerInfo[changedContainerType][changedObjectIndex] = {
					...containerInfo[changedContainerType][changedObjectIndex],
					containerNumber2: fetchedInfo?.containerNumber,
					freightWeight2: fetchedInfo?.freightWeight,
				}
			}
			form.setFieldsValue({ containerInfo })
			const flatContainerInfo = Object.values(containerInfo)?.flat()
			const usedContainerNumbers = []
			flatContainerInfo?.forEach((container) => {
				container.containerNumber && usedContainerNumbers.push(container.containerNumber)
				container.containerNumber2 && usedContainerNumbers.push(container.containerNumber2)
			})
			const remainingContainerNumbers = scraperContainers?.filter((container) => !usedContainerNumbers.includes(container.containerNumber))
			setContainers(remainingContainerNumbers)
		} else if (action === 'edit') {
			if (changedValues?.shipmentInfo?.freightType) {
				const vehicleSpecifics = form
					?.getFieldValue(['vehicleSpecifics'])
					?.map((vehicle) => ({ ...vehicle, freightType: changedValues?.shipmentInfo?.freightType }))
				form?.setFieldsValue({ vehicleSpecifics: vehicleSpecifics })
			} else if (changedValues?.shipmentInfo?.freightWeight) {
				const vehicleSpecifics = form?.getFieldValue(['vehicleSpecifics'])?.map((vehicle) => ({
					...vehicle,
					freightWeight:
						vehicle?.subOrderNumber === 'Remaining' ? (changedValues?.shipmentInfo?.freightWeight - lotAssignedWeight)?.toString() : vehicle?.freightWeight,
				}))
				form?.setFieldsValue({ vehicleSpecifics: vehicleSpecifics })
			} else if (changedValues?.pricingInfo?.finalizedRate) {
				const vehicleSpecifics = form?.getFieldValue(['vehicleSpecifics'])?.map((vehicle) => ({
					...vehicle,
					finalizedRate: vehicle?.subOrderNumber === 'Remaining' ? changedValues?.pricingInfo?.finalizedRate : vehicle.finalizedRate,
				}))
				form?.setFieldsValue({ vehicleSpecifics: vehicleSpecifics })
			}
		}
	}

	const onFinish = async (values) => {
		if (action === 'create') {
			setModalData({ visible: true, data: { segment, action, values: { ...order, ...values }, user, corporate } })
		} else if (action === 'edit') {
			const orderNumber = await createOrder({
				ports,
				clientEmail: order?.clientEmail,
				segment,
				action,
				values: { ...order, ...values },
				user,
				corporate,
				orderMutation: editOrderMutation,
			})
			notification[orderNumber ? 'success' : 'error']({
				duration: 5,
				message: orderNumber ? `Order No. ${orderNumber} Updated` : 'Unsuccessful',
				description: orderNumber ? 'Orders Updated successfully.' : 'Problem in updating order. Please contact product team.',
			})
			naviagte('/home/pending')
		}
	}

	return (
		<Layout className='FullWidth'>
			<Layout.Header className='FlexRowSpace PaddingLeftRight' style={{ height: 56 }}>
				<div className='BoldFont'>{`${action === 'create' ? 'New' : 'Update'} ${segment === 'LongHaul' ? 'Bulk' : segment} Order`}</div>
				<div className='FlexRowEnd'>
					{action === 'edit' && (
						<Button
							className='Button EHButton MarginRight'
							style={{ fontSize: 14, height: 40 }}
							onClick={() =>
								new Promise((resolve, reject) => {
									setShowWarning(true)
									setAllowExecution({ resolve, reject })
								})
									.then(() => {
										setShowWarning(false)
										return cancelOrder({
											cancelMutation: cancelOrderMutation,
											values: {
												_id: order?._id,
												segment,
											},
										})
									})
									.then((orderNumber) => {
										notification[orderNumber ? 'success' : 'error']({
											duration: 5,
											message: orderNumber ? `Order No. ${orderNumber} Cancelled` : 'Unsuccessful',
											description: orderNumber ? 'Orders Cancelled successfully.' : 'Problem in updating order. Please contact product team.',
										})
										naviagte('/home/allOrders/import')
									})
									.catch((e) => {
										setShowWarning(false)
										console.log(e)
									})
							}
							loading={cancelOrderMutation?.loading || loading}
						>
							Cancel Order
						</Button>
					)}
					<Button
						className='Button SuccessButton'
						style={{ fontSize: 14, height: 40 }}
						onClick={() => form.submit()}
						loading={editOrderMutation?.loading || loading}
					>
						{' '}
						{action === 'create' ? 'Create Order' : action === 'edit' && 'Update Order'}
					</Button>
				</div>
			</Layout.Header>
			<Layout.Content className='FullWidthHeight PaddingExceptBottom OverflowScroll' style={{ background: '#F2F3F7' }}>
				<ErrorBoundary
					fallbackRender={({ error, resetErrorBoundary }) => <FallBackUI component={'Form'} error={error} resetErrorBoundary={resetErrorBoundary} />}
				>
					<div className='FlexColumnBox'>
						<Form
							layout='vertical'
							form={form}
							className={'SixtyFourtyWidth'}
							onFinish={onFinish}
							onValuesChange={(changedValues) => onFormValuesChange(changedValues)}
							scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
						>
							{LocationsCard({ segment, action, form, corporate, terminals, locationsCounter, setLocationsCounter })}
							{['Import', 'Export'].includes(segment) && ShipmentDetails({ segment, documentId: uuid, shippingLines, terminals, action, setLoading, form })}
							{['Import', 'Export'].includes(segment) && ContainerInfoCard({ segment, action, form, containerCounter, setContainerCounter })}
							{['Import', 'Export'].includes(segment) &&
								Object.values(containerCounter)?.some(Boolean) &&
								ContainerDetailsCard({ segment, action, form, containers, containerCounter, SDorder })}
							{SDorder && SelectVehiclesCard({ form, action, watcher, vehicleCounter, setVehicleCounter })}
							{SDorder && ShiftingRateCalculationCard({ form })}
							{['LongHaul'].includes(segment) &&
								VehicleInfoCard({
									form,
									segment,
									action,
									lotSelectedVehicle,
									setLotSelectedVehicle,
									containerCounter,
									setContainerCounter,
									initialCounterState,
									lotOrder,
								})}
							{['LongHaul'].includes(segment) && FreightDetails({ segment, action, lotOrder, lotAssignedWeight, form })}
							{segment === 'LongHaul' && lotOrder && LotRateCalculationCard({ form })}
							{['LongHaul'].includes(segment) && action === 'edit' && BulkSubOrderDetails({ action, form, lotOrder })}
							{MiscellaneousCard({ segment, form })}
						</Form>
					</div>
				</ErrorBoundary>
			</Layout.Content>
			<OrderSummary {...state} modalData={modalData} setModalData={setModalData} />
			<WarningModal />
		</Layout>
	)
}

export default NewOrder
