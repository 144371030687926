import {
	GET_CLIENTS,
	GET_CORPORATES,
	GET_ALL_REGIONS,
	GET_ALL_LOCATIONS_BY_TYPE,
	GET_ORDERS,
	GET_ALL_PARENT_REGIONS,
	GET_ALL_CORPORATE_LOCATIONS,
} from '../graphql/queries'
import { SUBSCRIBE_ORDERS, SUBSCRIBE_ALL_CORPORATES } from '../graphql/subscriptions'
import {
	CREATE_IMPORT_ORDER,
	CREATE_EXPORT_ORDER,
	CREATE_BULK_ORDER,
	UPDATE_IMPORT_ORDER,
	UPDATE_EXPORT_ORDER,
	UPDATE_LONGHAUL_ORDER,
	CREATE_CORPORATE,
	UPDATE_CORPORATE,
	CREATE_LOCATION,
	UPDATE_LOCATION,
	UPDATE_ORDER_LOCATIONS,
	CREATE_REGION,
	CANCEL_ORDER,
	CONNECT_CLIENT_CORPORATES,
} from '../graphql/mutations'

/* ============================================================================================== */
/* =========================================== ENUMS ============================================ */
/* ============================================================================================== */
export const ProjectID = process.env.REACT_APP_PROJECT_ID
export const PagesEnum = {
	allOrders: { key: 'allOrders', url: 'allOrders/import', icon: '#AllOrders', long: 'All Orders' },
	pending: { key: 'pending', url: 'pending/import', icon: '#PendingOrders', long: 'Pending Orders' },
	unassignedOrders: { key: 'unassignedOrders', url: 'unassignedOrders/import', icon: '#UnassignedOrders', long: 'Unassigned Orders' },
	corporates: { key: 'corporates', url: 'corporates', icon: '#Clients', long: 'Clients' },
	connectClientsCorporates: { key: 'connectClientsCorporates', url: 'connectClientsCorporates', icon: '#connectClientsCorporates', long: 'Connect Clients' },
	metrics: { key: 'metrics', url: 'metrics', icon: '#Metrics', long: 'Metrics' },
}

export const GraphQlEnum = {
	Import: {
		key: 'Import',
		parentType: 'ParentImport',
		childrenType: 'SubImports',
		atoms: {
			pending: 'pendingOrdersAtom',
			unassigned: 'unassignedOrdersAtom',
			all: 'allOrdersAtom',
		},
		fetch: {
			queryName: GET_ORDERS,
			queryOutput: 'orders',
			getVariables: ({ segment, status }) => ({
				options: { sort: [{ createdAt: 'DESC' }] },
				where: {
					type: segment,
					...(status && {
						ParentOrder: {
							[GraphQlEnum[segment]?.parentType]: {
								status: status,
							},
						},
					}),
				},
				conditions: {
					Import: segment === 'Import',
					Export: segment === 'Export',
					LongHaul: segment === 'LongHaul',
				},
			}),
		},
		subscriptionName: SUBSCRIBE_ORDERS,
		subscriptionOutput: 'OrderUpdatedByStatusAndTypeAndBusinessCode',
		create: {
			function: CREATE_IMPORT_ORDER,
			functionInput: 'createImportOrderInput',
			functionOutput: 'createImportOrder',
		},
		edit: {
			function: UPDATE_IMPORT_ORDER,
			functionInput: 'updateImportOrderInput',
			functionOutput: 'updateImportOrder',
		},
		cancel: {
			function: CANCEL_ORDER,
			functionInput: 'cancelOrderInput',
			functionOutput: 'cancelOrderApi',
		},
	},
	Export: {
		key: 'Export',
		parentType: 'ParentExport',
		childrenType: 'SubExports',
		atoms: {
			unassigned: 'unassignedOrdersAtom',
			all: 'allOrdersAtom',
		},
		fetch: {
			queryName: GET_ORDERS,
			queryOutput: 'orders',
			getVariables: ({ segment, status }) => ({
				options: { sort: [{ createdAt: 'DESC' }] },
				where: {
					type: segment,
					...(status && {
						ParentOrder: {
							[GraphQlEnum[segment]?.parentType]: {
								status: status,
							},
						},
					}),
				},
				conditions: {
					Import: segment === 'Import',
					Export: segment === 'Export',
					LongHaul: segment === 'LongHaul',
				},
			}),
		},
		subscriptionName: SUBSCRIBE_ORDERS,
		subscriptionOutput: 'OrderUpdatedByStatusAndTypeAndBusinessCode',
		create: {
			function: CREATE_EXPORT_ORDER,
			functionInput: 'createExportOrderInput',
			functionOutput: 'createExportOrder',
		},
		edit: {
			function: UPDATE_EXPORT_ORDER,
			functionInput: 'updateExportOrderInput',
			functionOutput: 'updateExportOrder',
		},
		cancel: {
			function: CANCEL_ORDER,
			functionInput: 'cancelOrderInput',
			functionOutput: 'cancelOrderApi',
		},
	},
	LongHaul: {
		key: 'LongHaul',
		parentType: 'ParentLongHaul',
		childrenType: 'SubLongHauls',
		atoms: {
			unassigned: 'unassignedOrdersAtom',
			all: 'allOrdersAtom',
		},
		fetch: {
			queryName: GET_ORDERS,
			queryOutput: 'orders',
			getVariables: ({ segment, status }) => ({
				options: { sort: [{ createdAt: 'DESC' }] },
				where: {
					type: segment,
					...(status && {
						ParentOrder: {
							[GraphQlEnum[segment]?.parentType]: {
								status: status,
							},
						},
					}),
				},
				conditions: {
					Import: segment === 'Import',
					Export: segment === 'Export',
					LongHaul: segment === 'LongHaul',
				},
			}),
		},
		subscriptionName: SUBSCRIBE_ORDERS,
		subscriptionOutput: 'OrderUpdatedByStatusAndTypeAndBusinessCode',
		create: {
			function: CREATE_BULK_ORDER,
			functionInput: 'createLongHaulOrderInput',
			functionOutput: 'createLongHaulOrder',
		},
		edit: {
			function: UPDATE_LONGHAUL_ORDER,
			functionInput: 'updateLongHaulOrderInput',
			functionOutput: 'updateLongHaulOrder',
		},
		cancel: {
			function: CANCEL_ORDER,
			functionInput: 'cancelOrderInput',
			functionOutput: 'cancelOrderApi',
		},
	},
	Corporates: {
		key: 'Corporates',
		atoms: {
			normal: 'corporatesAtom',
		},
		fetch: {
			queryName: GET_CORPORATES,
			queryOutput: 'corporates',
		},
		subscriptionName: SUBSCRIBE_ALL_CORPORATES,
		subscriptionOutput: 'corporateUpdated',
		create: {
			function: CREATE_CORPORATE,
			functionInput: 'createCorporateInput',
			functionOutput: 'createCorporate',
		},
		edit: {
			function: UPDATE_CORPORATE,
			functionInput: 'updateCorporateInput',
			functionOutput: 'updateCorporate',
		},
	},
	Clients: {
		key: 'Clients',
		atoms: {
			normal: 'clientsAtom',
		},
		fetch: {
			queryName: GET_CLIENTS,
			queryOutput: 'clients',
			getVariables: ({ email_NOT }) => ({
				where: {
					User: { email_NOT: email_NOT },
				},
			}),
		},
		connect: {
			function: CONNECT_CLIENT_CORPORATES,
			functionInput: 'connectClientCorporatesInput',
			functionOutput: 'connectClientCorporates',
		},
	},
	Regions: {
		key: 'Regions',
		atoms: {
			normal: 'regionsAtom',
		},
		fetch: {
			queryName: GET_ALL_REGIONS,
			queryOutput: 'regions',
		},
		create: {
			function: CREATE_REGION,
			functionInput: 'createRegionInput',
			functionOutput: 'createRegion',
		},
	},
	Locations: {
		key: 'Locations',
		create: {
			function: CREATE_LOCATION,
			functionInput: 'createLocationInput',
			functionOutput: 'createLocation',
		},
		edit: {
			function: UPDATE_LOCATION,
			functionInput: 'updateLocationInput',
			functionOutput: 'updateLocation',
		},
	},
	OrderLocations: {
		key: 'OrderLocations',
		edit: {
			function: UPDATE_ORDER_LOCATIONS,
			functionInput: 'updateOrderLocationInput',
			functionOutput: 'updateOrderLocation',
		},
	},
	EmptyTerminals: {
		key: 'EmptyTerminals',
		atoms: {
			normal: 'terminalsAtom',
		},
		fetch: {
			queryName: GET_ALL_LOCATIONS_BY_TYPE,
			queryOutput: 'locations',
			getVariables: ({ type }) => ({
				where: {
					type: type,
				},
			}),
		},
	},
	Ports: {
		key: 'Ports',
		atoms: {
			normal: 'portsAtom',
		},
		fetch: {
			queryName: GET_ALL_LOCATIONS_BY_TYPE,
			queryOutput: 'locations',
			getVariables: ({ type }) => ({
				where: {
					type: type,
				},
			}),
		},
	},
	ParentRegions: {
		key: 'ParentRegions',
		atoms: {
			normal: 'parentRegionAtom',
		},
		fetch: {
			queryName: GET_ALL_PARENT_REGIONS,
			queryOutput: 'regions',
		},
		create: {
			function: CREATE_REGION,
			functionInput: 'createRegionInput',
			functionOutput: 'createRegion',
		},
	},
	CorporateLocations: {
		key: 'CorporateLocations',
		atoms: {
			normal: 'corporateLocationAtom',
		},
		fetch: {
			queryName: GET_ALL_CORPORATE_LOCATIONS,
			queryOutput: 'corporateLocations',
		},
	},
}

export const SegmentNameEnum = {
    import: {
        name: 'Import',
    },
    export: {
        name: 'Export',
    },
    longhaul: {
        name: 'LongHaul',
    },
}

export const SegmentsEnum = {
	Import: {
		key: 'Import',
		icon: '#ImportOrders',
		text: 'Import',
		long: 'Import Orders',
		initial: 'I',
		atomName: 'unassignedOrdersAtom',
		collectionName: 'Import',
	},
	Export: {
		key: 'Export',
		icon: '#ExportOrders',
		text: 'Export',
		long: 'Export Orders',
		initial: 'E',
		atomName: 'unassignedOrdersAtom',
		collectionName: 'Export',
	},
	LongHaul: {
		key: 'LongHaul',
		icon: '#LongHaulOrders',
		text: 'Bulk',
		long: 'Bulk Orders',
		initial: 'L',
		atomName: 'bulkOrdersAtom',
		collectionName: 'LongHaul',
	},
}

export const VehicleTypeEnum = {
	'Flatbed-Large': { key: 'Flatbed-Large', text: '45ft Flatbed', long: '45 Feet Flatbed', icon: '#other' },
	'Flatbed-Medium': { key: 'Flatbed-Medium', text: '43ft Flatbed', long: '43 Feet Flatbed', icon: '#other' },
	'Flatbed-Small': { key: 'Flatbed-Small', text: 'Small Flatbed', long: 'Small Flatbed', icon: '#other' },
	'Lowbed-Standard': { key: 'Lowbed-Standard', text: 'Lowbed', icon: '' },
	'Lowbed-MultiAxle': { key: 'Lowbed-MultiAxle', text: 'Multi-Axle Lowbed', icon: '' },
	'Trolly-xl20ft': { key: 'Trolly-xl20ft', text: '20 Feet Trolly', long: '20 Feet Trolly', icon: '#xl20ft' },
	'Trolly-xl40ft': { key: 'Trolly-xl40ft', text: '40 Feet Trolly', long: '40 Feet Trolly', icon: '#xl40ft' },
}

export const VehicleTypeEnumLongHaul = {
	flatbed: { key: 'flatbed', text: 'Flatbed', long: 'Flatbed', icon: '#flatbed' },
	dumper: { key: 'dumper', text: 'Dumper', long: 'Flatbed', icon: '#dumper' },
	halfBody: { key: 'halfBody', text: 'Half Body', long: 'Flatbed', icon: '#halfBody' },
	other: { key: 'other', text: 'Other', long: 'Flatbed', icon: '' },
}

export const ContainerDimensionsEnum = (segment) => ({
	xl20ft: { key: 'xl20ft', text: '20 Ft', long: '20 Feet', icon: '#xl20ft' },
	xl40ft: { key: 'xl40ft', text: '40 Ft', long: '40 Feet', icon: '#xl40ft' },
	xl40ftHC: { key: 'xl40ftHC', text: '40 Ft High Cube', long: '40 Feet - High Cube', icon: '#xl40ftHC' },
	xl40ftOT: { key: 'xl40ftOT', text: '40 Ft Open Top', long: '40 Feet - Open Top', icon: '#xl40ftOT' },
	...(segment === 'LongHaul'
		? {
				flatbed: { key: 'flatbed', text: 'Flatbed', long: 'Flatbed', icon: '' },
				dumper: { key: 'dumper', text: 'Dumper', long: 'Dumper', icon: '' },
				halfBody: { key: 'halfBody', text: 'Half Body', long: 'Half Body', icon: '' },
		  }
		: {}),

	other: { key: 'other', text: 'Other', long: 'Other', icon: '#other' },
})

export const OrderCategoryEnum = {
	Import: { key: 'Import', text: 'Direct Seal', category: 'Import' },
	ImportShortHaul: { key: 'ImportShortHaul', text: 'Local Movement', category: 'ImportShortHaul' },
	Shifting: { key: 'Shifting', text: 'Shifting', category: 'Shifting' },
	Destuffing: { key: 'Destuffing', text: 'Destuffing', category: 'Destuffing' },
}

export const OrderTypeEnum = {
	Import: {
		terminal: 'emptyContainer',
		document: 'blDocument',
		documentNumber: 'blNumber',
	},
	Export: {
		terminal: 'releaseTerminal',
		document: 'croDocument',
		documentNumber: 'croNumber',
	},
}

export const PakistanPoints = [
	{ lat: 35.4582433, lng: 80.3632033 }, //East Lng
	{ lat: 29.8583772, lng: 60.8725798 }, //West Lng
	{ lat: 37.0435343, lng: 74.6750703 }, //North Lat
	{ lat: 23.7250199, lng: 68.0817303 }, //South Lat
]

export const PakistanBoundingBox = { south: 23.6919650335, north: 37.1330309108, west: 60.8742484882, east: 77.8374507995 }

export const ClearingAgentCompanies = [
	'A.K Enterprises',
	'AR Trading',
	'Abdul Aziz Savul',
	'Accurate Logistics',
	'Agility',
	'Al Rehmat Trading',
	'Ali Bilal',
	'Ali Haider Kamran',
	'Allied Logistic',
	'Amir International',
	'Arbi International',
	'Baba Agencies',
	'Cargo Tracker',
	'Churra And CO',
	'Creek Agencies',
	'Dynamics Logistic',
	'Ejaz & Sons',
	'Fair and Fast International',
	'Five M International',
	'HR Brothers',
	'Ijaz & Sons',
	'Javaid Umar Enterprise',
	'Kallar Kahar',
	'Khurram & Co',
	'M Adeel Associate',
	'M/S Seaways Enterprises',
	'Marine (MICT)',
	'Muhammad Adeel Associate',
	'N.I. Trading',
	'Nabeel Brothers',
	'Omalsons Corporation',
	'Pervaiz Umar Enterprise',
	'Pioneer Trading',
	'R.I.L Logistics',
	'Rayan Agencies',
	'Ryan Clearing Agency',
	'SAS Pak Cargo',
	'Sea Land',
	'Sethi Commercial Agencies',
	'Shahid Brothers',
	'Shams Shahzad',
	'Sndal Agency',
	'Tracon Services',
	'UF Logistics',
	'Unique Freight',
	'Venus Pakistan',
]

export const keyAccountManagerEnum = [
	'Arsalan Yousaf',
	'Aun Jafri',
	'Hamza Mahmood',
	'Madhat Ali Khan',
	'Omer Humayun',
	'Shahzeb Aziz',
	'Sibghat Ali Khan',
	'Taimoor Qadir',
]

export const PageSizes = {
	10: { frontend: 10, backend: 50 },
	20: { frontend: 20, backend: 100 },
	50: { frontend: 50, backend: 250 },
	100: { frontend: 100, backend: 500 },
}

export const MetricsEnums = {
	ImportDriverTracking: {
		key: 'DriverTrackingSheet',
		segment: 'Import',
		title: 'Import Driver Tracking',
		description: 'Get driver tracking history of orders between specific dates. The sheet will be sent to the given email address',
	},
	ExportDriverTracking: {
		key: 'DriverTrackingSheet',
		segment: 'Export',
		title: 'Export Driver Tracking',
		description: 'Get driver tracking history of orders between specific dates. The sheet will be sent to the given email address',
	},
	LongHaulDriverTracking: {
		key: 'DriverTrackingSheet',
		segment: 'LongHaul',
		title: 'Bulk Driver Tracking',
		description: 'Get driver tracking history of orders between specific dates. The sheet will be sent to the given email address',
	},
	ConsolidatedSheet: {
		key: 'ConsolidatedDataSheet',
		title: 'Consolidated Sheet',
		description: 'Get consolidated sheet of import, export and bulk orders between specific dates. The sheet will be sent to the given email address',
	},
	ClientOrders: {
		key: 'ClientOrders',
		title: 'Client Orders',
		description: 'Get daily status sheet of client orders. The sheet will be sent to the given email address',
	},
	CustomHeaderSheet: {
		key: 'CustomHeaderSheet', // Change this to custom header sheet moving forward.
		title: 'Custom Header Sheet',
		description: 'Select the headers that you require in the sheet. The sheet will be sent to the given email address',
	},
	BiltySheet: {
		key: 'BiltySheet', // Change this to custom header sheet moving forward.
		title: 'BiltySheet',
		description: 'Get Bilty Sheet. The sheet will be sent to the given email address',
	},
	EirTrackingSheet: {
		key: 'EirTrackingSheet', // Change this to custom header sheet moving forward.
		title: 'EIR Tracking Sheet',
		description: 'Get EIR Tracking Sheet. The sheet will be sent to the given email address',
	},
	InvoiceSheet: {
		key: 'InvoiceSheet', // Change this to custom header sheet moving forward.
		title: 'Invoice Sheet',
		description: 'Get Invoice Sheet. The sheet will be sent to the given email address',
	},
}

export const CustomHeaders = [
	{ key: 'jobNumber', title: 'Job Number' },
	{ key: 'orderNumber', title: 'Order Number' },
	{ key: 'createdAt', title: 'Order Created At' },
	{ key: 'completionDate', title: 'Order Completion Date' },
	{ key: 'driverReachedContainerTerminal', title: 'Reached Container Terminal Date' },
	{ key: 'driverMovingToPickup', title: 'Moving To Pickup Date' },
	{ key: 'driverReachedPickup', title: 'Reached Pickup Date' },
	{ key: 'pickupLoadingDate', title: 'Pickup Loading Start Date' },
	{ key: 'pickupLoadingCompletionDate', title: 'Pickup Loading Completion Date' },
	{ key: 'driverReachedDropoff', title: 'Reached Dropoff Date' },
	{ key: 'unloadingDate', title: 'Unloading Start Date' },
	{ key: 'unloadingCompletionDate', title: 'Unloading Completion Date' },
	{ key: 'containerUnloadingDate', title: 'Container Unloading Start Date' },
	{ key: 'containerUnloadingCompletionDate', title: 'Container Unloading Completion Date' },

	{ key: 'createdBy', title: 'Created By' },
	{ key: 'clientName', title: 'Client Name' },
	{ key: 'businessCode', title: 'Business Code' },
	{ key: 'refNumber', title: 'Reference Number' },

	{ key: 'baseRateAmount', title: 'Base Rate Amount' },
	{ key: 'baseRateSource', title: 'Base Rate Source' },

	{ key: 'biltyNumber', title: 'Bilty Number' },
	{ key: 'biltyUploaded', title: 'Bilty Uploaded' },
	{ key: 'halfDoneBiltyUploadedAt', title: 'Half Done Bilty Uploaded At' },
	{ key: 'halfDoneBiltyUploadedBy', title: 'Half Bilty Uploaded By' },
	{ key: 'biltyUploadedAt', title: 'Final Bilty Uploaded At' },
	{ key: 'biltyUploadedBy', title: 'Final Bilty Uploaded By' },
	{ key: 'biltyReceivedAt', title: 'Bilty Received At' },

	{ key: 'initialKantaWeight', title: 'Initial Kanta Weight' },
	{ key: 'finalKantaWeight', title: 'Final Kanta Weight' },

	{ key: 'type', title: 'Order Type' },

	{ key: 'documentNumber', title: 'BL/CRO Number' },
	{ key: 'containerDimensions', title: 'Container Dimensions' },
	{ key: 'containerNumber', title: 'Container Number' },
	{ key: 'freightType', title: 'Cargo Type' },
	{ key: 'freightWeight', title: 'Cargo Weight (Tons)' },

	{ key: 'driverName', title: 'Driver Name' },
	{ key: 'driverPhone', title: 'Driver Contact' },
	{ key: 'driverVehicleRegistration', title: 'Vehicle Number' },

	{ key: 'finalizedRate', title: 'Finalized Rate' },
	{ key: 'finalizedRateUnit', title: 'Finalized Rate Unit' },

	{ key: 'orderStatus', title: 'Order Status' },
	{ key: 'loadingDate', title: 'Loading Date' },
	{ key: 'shippingLine', title: 'Shipping Line' },
	{ key: 'dropoff', title: 'Drop Off Locations' },

	{ key: 'brokerName', title: 'Broker' },
	{ key: 'supplierName', title: 'Supplier Name' },
	{ key: 'pickup', title: 'Pick Up' },
	{ key: 'releaseTerminal', title: 'Release Terminal' },
	{ key: 'cost', title: 'Cost' },

	{ key: 'lane', title: 'Lane Name' },

	{ key: 'vehicleAssignedBy', title: 'Vehicle Assigned By' },
	{ key: 'vehicleAssignedAt', title: 'Vehicle Assigned At' },
	{ key: 'vehicleMatchedBy', title: 'Vehicle Matched By' },
	{ key: 'vehicleMatchedAt', title: 'Vehicle Matched At' },
	{ key: 'complaintsRaised', title: 'Complaints Raised' },
	{ key: 'complaintsRaisedAt', title: 'Complaints Raised At' },
	{ key: 'complaintsResolutionAt', title: 'Complaints Resolution At' },
	{ key: 'complaintComments', title: 'Complaint Comments' },
	{ key: 'doStatus', title: 'All DO Statuses' },
	{ key: 'doStatusTime', title: 'All DO Statuses Time' },

	{ key: 'doIssuance', title: 'DO Issuance Time' },
	{ key: 'gatepassIssuance', title: 'Gatepass Issuance Time ' },
	{ key: 'rtmTS', title: 'Ready to Move Time' },

	{ key: 'doIssuanceHandledBy', title: 'DO Issuance Handled By' },

	{ key: 'emptyLocation', title: 'Empty Container Dropoff' },
	{ key: 'returnDT', title: 'Empty Conatiner Return Date' },
	{ key: 'vesselArrivalDT', title: 'Vessel Arrival Date' },

	{ key: 'eirReceivedAt', title: 'EIR Received At' },
	{ key: 'eirSubmittedAt', title: 'EIR Submitted At' },

	{ key: 'additionalChargesDeductions', title: 'Additional Charges(Deductions)' },
	{ key: 'additionalChargesDetentions', title: 'Additional Charges(Detentions)' },
	{ key: 'additionalChargesDropoffChange', title: 'Additional Charges(Dropoff Change)' },
	{ key: 'additionalChargesExtraWeight', title: 'Additional Charges(Extra Weight)' },
	{ key: 'additionalChargesInaam', title: 'Additional Charges(Inaam)' },
	{ key: 'additionalChargesKantaCharges', title: 'Additional Charges(Kanta Charges)' },
	{ key: 'additionalChargesLoloCharges', title: 'Additional Charges(LOLO Charges)' },
	{ key: 'additionalChargesOtherCharges', title: 'Additional Charges(Other Charges)' },
]
