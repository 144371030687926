import { Form, Input, Checkbox, Divider } from 'antd'
import { mdiMinusCircle, mdiPlusCircle } from '@mdi/js'
import Icon from '@mdi/react'
import ContainerCosts from './ContainerCosts'
import Sprite from '../../assets/icons/Sprite.svg'

const ContainerDetails = (props) => {
	const { segment, form, count, action, container, setContainerCounter } = props

	const doIssuance = form?.getFieldValue(['specialRequests', 'doIssuance'])
	const orderCategory = form?.getFieldValue(['specialRequests', 'orderCategory'])
	const doubleTwenty = form?.getFieldValue(['specialRequests', 'doubleTwenty'])

	const FormItem = (props) => (
		<Form.Item
			key={props.name}
			name={props.name}
			label={props.label}
			className={props.className}
			initialValue={props.initialValue}
			style={props?.style}
			rules={[
				{
					required: props.required,
					validator: (_, value = '') => {
						if (value?.toString().length > 0) {
							if (value < 0) {
								return Promise.reject(new Error(`This value cannot be negative.`))
							} else if (parseInt(value) < props.minValue) {
								return Promise.reject(new Error(`This value cannot be less than ${props.minValue}`))
							} else if (parseInt(value) > props.maxValue) {
								return Promise.reject(new Error(`This value cannot be greater than ${props.maxValue}`))
							} else {
								return Promise.resolve()
							}
						} else if (!props.required) {
							return Promise.resolve(value)
						} else {
							return Promise.reject(new Error(props.message))
						}
					},
				},
			]}
			shouldUpdate={(prevValues, currentValues) => prevValues?.specialRequests?.doubleTwenty !== currentValues?.specialRequests?.doubleTwenty}
		>
			<Input
				className='InputField'
				disabled={props?.disabled}
				hidden={props?.hidden}
				type={props?.type || 'number'}
				placeholder={props.placeholder}
				onWheel={(event) => event.currentTarget.blur()}
				addonBefore={props?.addonBefore}
				addonAfter={props?.addonAfter}
			/>
		</Form.Item>
	)

	return (
		<div className={`${segment !== 'LongHaul' ? 'ml-5' : ''}`}>
			<div className='flex flex-row my-3 items-start'>
				<div className=''>
					<div className='w-full flex flex-row relative justify-center'>
						{action === 'create' && (
							<Icon
								path={mdiMinusCircle}
								size={1}
								className={`${!count ? 'GrayColor' : 'PrimaryColor CursorPointer'} LeftCounterButton`}
								onClick={() => {
									return count && setContainerCounter((prevState) => ({ ...prevState, [container.key]: prevState[container.key] - 1 }))
								}}
							/>
						)}
						<Form.Item
							key={segment === 'LongHaul' ? ['specialRequests', 'numberVehicles'] : ['containersSpecifics', container?.key, 'quantity']}
							name={segment === 'LongHaul' ? ['specialRequests', 'numberVehicles'] : ['containersSpecifics', container?.key, 'quantity']}
							className='FullWidth NoMargin'
							rules={[
								{
									required: true,
									validator: (_, value = '') => {
										if (value?.toString().length > 0) {
											if (value < 0) return Promise.reject(new Error(`This value cannot be negative.`))
											else return Promise.resolve()
										} else return Promise.reject(new Error('Quantity is required.'))
									},
								},
							]}
							initialValue={count}
						>
							{
								<Input
									className='InputField NarrowField'
									type='number'
									onInput={(e) => (e.target.value = e.target.value.slice(0, 3))}
									onChange={(e) => setContainerCounter((prevState) => ({ ...prevState, [container.key]: e.target.valueAsNumber }))}
									onWheel={(event) => event.currentTarget.blur()}
									disabled={action === 'edit'}
								/>
							}
						</Form.Item>
						{action === 'create' && (
							<Icon
								path={mdiPlusCircle}
								size={1}
								className={`${count === 999 ? 'GrayColor' : 'PrimaryColor CursorPointer'} RightCounterButton`}
								onClick={() => count !== 999 && setContainerCounter((prevState) => ({ ...prevState, [container.key]: prevState[container.key] + 1 }))}
							/>
						)}
					</div>
				</div>
				<div className='w-3/4 flex flex-row h-10 mb-6 items-center'>
					<p className='w-1/4 font-bold text-sm text-center'>x</p>
					<p className='w-full font-bold text-sm'>{container.long}</p>
					<svg style={{ height: 32 }}>
						<use href={Sprite + container.icon} />
					</svg>
				</div>
			</div>
			<div className='flex flex-col ml-5'>
				{container?.key === 'xl20ft' && count > 1 && !['Shifting', 'Destuffing', 'ImportShortHaul']?.includes?.(orderCategory) && segment !== 'LongHaul' && (
					<div className='flex w-full flex-row'>
						<Form.Item key={['specialRequests', 'doubleTwenty']} name={['specialRequests', 'doubleTwenty']} initialValue={false} valuePropName='checked'>
							<Checkbox defaultChecked={false} className='outline-teal-50' disabled={action === 'edit'}>
								<p className='ml-2 font-semibold'>Move 20 Feet Containers as Double Twenty</p>
							</Checkbox>
						</Form.Item>
					</div>
				)}
				{count > 0 &&
					action === 'create' &&
					FormItem({
						name: segment === 'LongHaul' ? ['shipmentInfo', 'freightWeight'] : ['containersSpecifics', container?.key, 'freightWeight'],
						label: 'Weight Per Container',
						className: 'w-1/3',
						addonAfter: 'Tons',
						required: true,
						placeholder: 'Weight Per Container',
						message: 'Weight is Required',
					})}
				{count > 0 && (
					<div className='mt-1 flex flex-col justify-between'>
						{doubleTwenty && container?.key === 'xl20ft' ? (
							<>
								<div className='mb-4 text-lg font-semibold text-primary-800'>Vehicle Information & Costs</div>
								<div className='flex flex-row'>
									<div className='flex flex-col'>
										<div className='mt-2 mb-4 text-sm font-semibold text-gray-500'>Vehicle Type</div>
										<div className='text-base font-semibold'>Double Twenty Truck</div>
									</div>
									{FormItem({
										name: ['containersSpecifics', 'xl2020ft', 'quantity'],
										label: 'No. of Vehicles',
										message: 'No. of Vehicles is Required',
										disabled: action === 'edit',
										className: 'ml-10',
										maxValue: Math.floor(form?.getFieldValue(['containersSpecifics', container?.key, 'quantity']) / 2),
										minValue: 1,
										required: true,
									})}
								</div>
								<ContainerCosts
									form={form}
									segment={segment}
									action={action}
									doubleTwenty={doubleTwenty}
									containerKey={'xl2020ft'}
									orderCategory={orderCategory}
									doIssuance={doIssuance}
								/>
								{form?.getFieldValue(['containersSpecifics', container?.key, 'quantity']) - form?.getFieldValue(['containersSpecifics', 'xl2020ft', 'quantity']) * 2 >
									0 && (
									<>
										<Divider></Divider>
										<div className='mb-8 flex flex-row'>
											<div className='flex flex-col'>
												<div className='mt-2 mb-4 text-sm font-semibold text-gray-500'>Vehicle Type</div>
												<div className='text-base font-semibold'>Regular 20ft Vehicle</div>
											</div>
											<div className='ml-12 flex flex-col'>
												<div className='mt-2 mb-4 text-sm font-semibold text-gray-500'>No. of Vehicles</div>
												<div className='text-sm font-semibold'>
													{form?.getFieldValue(['containersSpecifics', container?.key, 'quantity']) -
														form?.getFieldValue(['containersSpecifics', 'xl2020ft', 'quantity']) * 2 || 0}
												</div>
											</div>
										</div>
										<ContainerCosts
											form={form}
											segment={segment}
											action={action}
											doubleTwenty={doubleTwenty}
											containerKey={container?.key}
											orderCategory={orderCategory}
											doIssuance={doIssuance}
										/>
									</>
								)}
							</>
						) : (
							doubleTwenty &&
							container?.key !== 'xl20ft' && (
								<ContainerCosts
									form={form}
									segment={segment}
									action={action}
									doubleTwenty={doubleTwenty}
									containerKey={container?.key}
									orderCategory={orderCategory}
									doIssuance={doIssuance}
								/>
							)
						)}
						{!doubleTwenty && (
							<ContainerCosts
								form={form}
								segment={segment}
								action={action}
								doubleTwenty={doubleTwenty}
								containerKey={container?.key}
								orderCategory={orderCategory}
								doIssuance={doIssuance}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export default ContainerDetails
