import { gql } from '@apollo/client'

export const CREATE_CORPORATE = gql`
	mutation CreateCorporate($createCorporateInput: CreateCorporateInput!) {
		createCorporate(createCorporateInput: $createCorporateInput) {
			_id
		}
	}
`

export const UPDATE_CORPORATE = gql`
	mutation UpdateCorporate($updateCorporateInput: UpdateCorporateInput!) {
		updateCorporate(updateCorporateInput: $updateCorporateInput) {
			_id
		}
	}
`

export const CREATE_LOCATION = gql`
	mutation CreateLocation($createLocationInput: CreateLocationInput) {
		createLocation(createLocationInput: $createLocationInput) {
			_id
		}
	}
`

export const UPDATE_LOCATION = gql`
	mutation UpdateLocation($updateLocationInput: UpdateLocationInput) {
		updateLocation(updateLocationInput: $updateLocationInput) {
			_id
		}
	}
`

export const CREATE_IMPORT_ORDER = gql`
	mutation CreateImportOrder($createImportOrderInput: CreateImportOrderInput) {
		createImportOrder(createImportOrderInput: $createImportOrderInput) {
			_id
			ParentOrder {
				ParentImport {
					orderNumber
					SubImports {
						OrderLocations {
							_id
							locationType
						}
					}
				}
			}
		}
	}
`

export const CREATE_EXPORT_ORDER = gql`
	mutation CreateExportOrder($createExportOrderInput: CreateExportOrderInput!) {
		createExportOrder(createExportOrderInput: $createExportOrderInput) {
			_id
			ParentOrder {
				ParentExport {
					orderNumber
				}
			}
		}
	}
`

export const CREATE_BULK_ORDER = gql`
	mutation CreateLongHaulOrder($createLongHaulOrderInput: CreateLongHaulOrderInput!) {
		createLongHaulOrder(createLongHaulOrderInput: $createLongHaulOrderInput) {
			_id
			ParentOrder {
				ParentLongHaul {
					orderNumber
				}
			}
		}
	}
`

export const UPDATE_IMPORT_ORDER = gql`
	mutation UpdateImportOrder($updateImportOrderInput: UpdateImportOrderInput) {
		updateImportOrder(updateImportOrderInput: $updateImportOrderInput) {
			_id
			ParentOrder {
				ParentImport {
					orderNumber
				}
			}
		}
	}
`

export const UPDATE_EXPORT_ORDER = gql`
	mutation UpdateExportOrder($updateExportOrderInput: UpdateExportOrderInput) {
		updateExportOrder(updateExportOrderInput: $updateExportOrderInput) {
			_id
			ParentOrder {
				ParentExport {
					orderNumber
				}
			}
		}
	}
`

export const UPDATE_LONGHAUL_ORDER = gql`
	mutation UpdateLongHaulOrder($updateLongHaulOrderInput: UpdateLongHaulOrderInput!) {
		updateLongHaulOrder(updateLongHaulOrderInput: $updateLongHaulOrderInput) {
			_id
			ParentOrder {
				ParentLongHaul {
					orderNumber
				}
			}
		}
	}
`

export const UPDATE_ORDER_LOCATIONS = gql`
	mutation UpdateOrderLocation($updateOrderLocationInput: UpdateOrderLocationInput!) {
		updateOrderLocation(updateOrderLocationInput: $updateOrderLocationInput) {
			_id
		}
	}
`

export const CREATE_REGION = gql`
	mutation CreateRegion($createRegionInput: CreateRegionInput) {
		createRegion(createRegionInput: $createRegionInput) {
			_id
			name
			ParentRegion {
				_id
				name
			}
		}
	}
`

export const CANCEL_ORDER = gql`
	mutation CancelOrderApi($cancelOrderInput: CancelOrderInput!) {
		cancelOrderApi(cancelOrderInput: $cancelOrderInput) {
			_id
			type
			ParentOrder {
				ParentImport {
					orderNumber
				}
				ParentExport {
					orderNumber
				}
				ParentLongHaul {
					orderNumber
				}
			}
		}
	}
`

export const CONNECT_CLIENT_CORPORATES = gql`
	mutation ConnectClientCorporates($connectClientCorporatesInput: ConnectClientCorporatesInput) {
		connectClientCorporates(connectClientCorporatesInput: $connectClientCorporatesInput) {
			_id
			isKAM
			User {
				_id
				email
				firstName
				lastName
			}
			Corporate {
				_id
				businessCode
			}
		}
	}
`
