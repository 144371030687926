// REACT
import React, { useState, useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

// DESIGN
import Icon from "@mdi/react"
import { Layout, Dropdown, Button } from 'antd'
import { mdiAccountCircleOutline, mdiChevronDown } from '@mdi/js'
import BridgelinxIcon from '../icon.png'

// UTILS
import { PagesEnum, ProjectID } from '../utils/Enums'
import { pathLocation } from '../utils/Functions'

// COMPONENTS
import Sidebar from '../components/Sidebar'

// RECOIL
import { userAtom } from '../recoil/Atoms'
import { useRecoilState } from 'recoil'

// FIREBASE
import { auth } from "../firebase/firebaseConfig"

const HomePage = () => {
    // INITIALIZING VARIABLES
    const {pathname} = useLocation()
	const currentPage = useMemo(() => pathLocation(pathname, 2), [pathname])
    const [user, setUser] = useRecoilState(userAtom)
    const [modalData, setModalData] = useState({ visible: false })
    
    // FUNCTION TO LOGOUT THE USER
    const Logout = () => {
        auth.signOut()
        sessionStorage.setItem('user', JSON.stringify(null))
        localStorage.setItem('user', JSON.stringify(null))
        sessionStorage.setItem('token', JSON.stringify(null))
        localStorage.setItem('token', JSON.stringify(null))
        setUser({})
        window.location.href = '/'
    }

    // ITEMS FOR PROFILE MENU
    const items = [
		{
			label: 'Profile Info',
			key: '1',
		},
		{
			label: 'Sign Out',
			key: '2',
			danger: true,
			onClick: Logout,
		},
	]

    // COMPONENT TO RENDER
    return (
        <Layout>
            <Layout.Header className='PrimaryHeader FlexRowSpace BorderBottom' style={{ padding: '0px 16px' }}>
                <div className='FlexRow'>
                    <img src={BridgelinxIcon} alt='' style={{ width: 24 }} />
                    <p className='BoldFont FontSize16 PrimaryColor MarginLeft'>{PagesEnum[currentPage]?.long}</p>
                    {ProjectID === 'bridgelinx-dev' && (
                        <div className='HalfMargin BorderRadius PBackground'>
                            <p className='FontBold Italic  WhiteColor' style={{ fontSize: '1.125rem', lineHeight: '1.75rem', marginLeft: '0.5rem', marginRight: '0.75rem' }}>
                                dev
                            </p>
                        </div>
                    )}
                </div>
                <div className='FlexRow'>
                    <Dropdown menu={{ items, className: 'ProfileMenu BoldFont NoMargin NoPadding' }} trigger={['click']} className='CursorPointer'>
                        <div className='ProfileInfo FlexRow PHBackground HalfPadding'>
                            <Icon path={mdiAccountCircleOutline} size={1} className='PrimaryColor' />
                            <p className='BoldFont HalfMarginLeftRight'>{user?.firstName + ' ' + user?.lastName}</p>
                            <Icon path={mdiChevronDown} size={1} className='PrimaryColor' />
                        </div>
                    </Dropdown>
                </div>
            </Layout.Header>
            <Layout>
                <Sidebar currentPage={currentPage} />
                <Outlet />
            </Layout>
        </Layout>
    )
}

export default HomePage