import { gql } from '@apollo/client'
import {
	ORDER_FIELDS,
	CORPORATE_FIELDS,
	CONTACT_FIELDS,
	INVOICING_DETAILS,
	CORPORATE_LOCATIONS_FIELDS,
	USER_FIELDS,
	CLIENT_FIELDS,
} from './fragments'

export const GET_CORPORATES = gql`
	${CORPORATE_FIELDS}
	${CONTACT_FIELDS}
	${INVOICING_DETAILS}
	${CORPORATE_LOCATIONS_FIELDS}
	query Corporates {
		corporates {
			...CorporateFields
			invoicingDetails {
				...InvoicingDetailsFields
			}
			clearingAgents {
				...ContactFields
			}
			CorporateLocations {
				...CorporateLocationsFields
			}
		}
	}
`

export const GET_CLIENTS = gql`
	${CLIENT_FIELDS}
	query Clients($where: ClientWhere) {
		clients(where: $where) {
			...ClientFields
		}
	}
`

export const GET_ALL_LOCATIONS_BY_TYPE = gql`
	query Locations($where: LocationWhere) {
		locations(where: $where) {
			_id
			additionalDetails
			formattedAddress
		}
	}
`

export const GET_PROFILE = gql`
	${USER_FIELDS}
	query Users($where: UserWhere) {
		users(where: $where) {
			...UserFields
		}
	}
`

export const GET_ALL_REGIONS = gql`
	query Regions {
		regions {
			_id
			name
			ParentRegion {
				_id
				name
			}
		}
	}
`

export const GET_ALL_PARENT_REGIONS = gql`
	query Regions {
		regions(where: { ParentRegion: null }) {
			_id
			name
			AssociatedRegions {
				_id
				name
			}
		}
	}
`

export const GET_ORDERS = gql`
	${ORDER_FIELDS}
	query Orders($where: OrderWhere, $options: OrderOptions, $Import: Boolean!, $Export: Boolean!, $LongHaul: Boolean!) {
		orders(where: $where, options: $options) {
			...OrderFields
		}
	}
`

export const GET_ALL_CORPORATE_LOCATIONS = gql`
	query CorporateLocations {
		corporateLocations {
			_id
			Corporate {
				businessCode
				businessName
			}
			Location {
				formattedAddress
				additionalDetails
				Region {
					_id
					name
					ParentRegion {
						_id
						name
					}
				}
			}
		}
	}
`
