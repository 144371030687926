import React, { useEffect, useMemo } from 'react'

import { Form, Radio } from 'antd'
import { RenderSchema } from '../../utils/FormRenderer'
import { VehicleInfoSchema } from '../../utils/FormSchemas'
import { ContainerDimensionsEnum } from '../../utils/Enums'
import ContainerDetails from './ContainerDetails'
import VehicleType from './VehicleType'

const VehicleInfo = (props) => {
	const { form, segment, action, lotSelectedVehicle, setLotSelectedVehicle, containerCounter, setContainerCounter, initialCounterState, lotOrder } =
		props
	const count = useMemo(() => containerCounter[lotSelectedVehicle], [containerCounter]) // eslint-disable-line

	useEffect(() => {
		if (lotSelectedVehicle && action === 'create') {
			setContainerCounter(initialCounterState)
			setContainerCounter((prevState) => ({ ...prevState, [lotSelectedVehicle]: lotSelectedVehicle ? 1 : 0 }))
			form.setFieldsValue({
				vehicleInfo: {
					vehicleType: lotSelectedVehicle,
				},
			})
		}
	}, [lotSelectedVehicle]) // eslint-disable-line

	useEffect(() => {
		form.setFieldsValue({
			specialRequests: { ...form.getFieldValue('specialRequests'), numberVehicles: containerCounter?.[lotSelectedVehicle] },
		})
	}, [containerCounter]) // eslint-disable-line

	return (
		<>
			<p className='text-gray-500 mb-3'>{action === 'create' ? 'Select Order Type and Vehicle Information' : 'Order Type'}</p>
			<div className='WBackground Padding Border BorderRadius' style={{ marginBottom: 48 }}>
				{RenderSchema(VehicleInfoSchema({ action }))}
				{lotOrder ? (
					<VehicleType />
				) : (
					<div id='shipmentInfo'>
						<p className='mb-2 px-2 text-base font-semibold text-gray-500'>Select Container Dimensions</p>
						<Form.Item
							id='hello'
							key='vehicleInfo'
							name='vehicleInfo'
							rules={[
								{
									validator: async () => {
										if (Object.values(containerCounter).some((count) => count > 0)) Promise.resolve()
										else throw new Error('Container specifics are required.')
									},
								},
							]}
						>
							<Form.Item shouldUpdate={(prevValues, currentValues) => prevValues?.specialRequests?.lotOrder !== currentValues?.specialRequests?.lotOrder} noStyle>
								{() => {
									const dimensions = Object.values(ContainerDimensionsEnum(segment))
									return dimensions?.map((container) => (
										<div>
											<Radio.Group
												id={'inheritSpan'}
												disabled={action === 'edit'}
												defaultValue={form?.getFieldValue[('vehicleInfo', 'vehicleType')]}
												defaultChecked={false}
												value={lotSelectedVehicle}
												onChange={(e) => setLotSelectedVehicle(e.target.value)}
											>
												<Radio
													value={container.key}
													className={
														lotSelectedVehicle === container?.key
															? 'h-42 w-full border-primary-800 bg-primary-50 p-6 rounded-md font-semibold'
															: 'h-16 w-full p-6 rounded-md font-semibold'
													}
												>
													{container?.text} {!['other', 'flatbed', 'dumper', 'halfBody']?.includes(container?.key) && 'Container'}
													{lotSelectedVehicle === container.key && (
														<ContainerDetails form={form} count={count} action={action} segment={segment} container={container} setContainerCounter={setContainerCounter} />
													)}
												</Radio>
											</Radio.Group>
										</div>
									))
								}}
							</Form.Item>
						</Form.Item>
					</div>
				)}
			</div>
		</>
	)
}

export default VehicleInfo
