import { RenderSchema, FormRender } from '../../utils/FormRenderer'
import { LotRateCalculationSchema } from '../../utils/FormSchemas'

const LotRateCalculationCard = (props) => {
	const { form } = props
	return (
		<>
			<div className='mb-3 text-base font-medium leading-5 text-gray-500'>Rate Calculation</div>
			<div className='mb-12 rounded-lg border bg-white'>
				<div className='m-6 flex flex-col justify-items-center'>
					{/* {RenderSchema(LotRateCalculationSchema())} */}
					<div className='FlexRow'>
						<div className='QuarterPadding'>
							{FormRender({
								type: 'number',
								label: 'Rate Per Tonne - Supply',
								name: ['pricingInfo', 'vehicleCost'],
								inputType: 'number',
								required: true,
								message: 'Supply Quote is required.',
								minValue: 1,
								addonAfter: 'PKR / Tonne',
							})}
						</div>
						<div></div>
					</div>
					<div className='FlexRow'>
						<div className='QuarterPadding'>
							{FormRender({
								type: 'number',
								label: 'Revenue Per Tonne',
								name: ['pricingInfo', 'finalizedRate'],
								inputType: 'number',
								required: true,
								message: 'Revenue is required.',
								minValue: 1,
								addonAfter: 'PKR / Tonne',
							})}
						</div>
						<div className='QuarterPadding FontSize20'>
							<span className='QuarterPadding Italic FontSize16'> Total: </span>
							<span className='BoldFont'>
								{form?.getFieldValue(['shipmentInfo', 'freightWeight']) * form?.getFieldValue(['pricingInfo', 'finalizedRate']) || 0}
							</span>{' '}
							<span className='Italic FontSize12'>PKR</span>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default LotRateCalculationCard
