// REACT
import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// DESIGN
import { Layout, Menu, Tooltip } from 'antd'
import Sprite from '../assets/icons/Sprite.svg'

// UTILS
import { pathLocation } from '../utils/Functions'
import { PagesEnum } from '../utils/Enums'

const { Sider } = Layout

const Sidebar = (props) => {
	// INITIALIZING VARIABLES
	const { collapseMenu } = props
	const { pathname } = useLocation()
	const currentPage = useMemo(() => pathLocation(pathname, 2), [pathname])
	const naviagte = useNavigate()
	const email = JSON.parse(localStorage.getItem('user'))?.email
	const allPages = PagesEnum
	if (['szaidi@bridgelinxpk.com', 'sabbas@bridgelinxpk.com', 'zshah@bridgelinxpk.com', 'asaleem@bridgelinxpk.com'].includes(email)) {
		allPages.regions = { key: 'regions', url: 'regions', icon: '#Regions', long: 'Regions' }
	}

	// COMPONENET TO RENDER
	return (
		<Sider width={52}>
			<div className='FullHeight FlexColumnSpace'>
				<Menu
					items={Object.values(allPages).map((Item) => {
						return {
							key: Item.key,
							icon: !collapseMenu && (
								<Tooltip placement='right' overlayClassName={'BoldFont FontSize12'} color='#0066CC' title={Item.long} mouseEnterDelay={0}>
									<svg className='SidebarIcon'>
										<use href={Sprite + Item.icon} />
									</svg>
								</Tooltip>
							),
							onClick: () => {
								naviagte(`/home/${Item.url}`)
							},
						}
					})}
					mode='inline'
					selectedKeys={currentPage}
				></Menu>
			</div>
		</Sider>
	)
}

export default Sidebar
