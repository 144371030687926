import Icon from '@mdi/react'
import { useState } from 'react'
import { Dropdown } from 'antd'
import { mdiChevronUp } from '@mdi/js'
import { PageSizes } from '../../utils/Enums'
import { usePagination, LeftDOTS, RightDOTS } from '../../hooks/usePagination'
import { LeftOutlined, RightOutlined, DoubleLeftOutlined, DoubleRightOutlined, EllipsisOutlined } from '@ant-design/icons'

const Pagination = (props) => {
	const { setPageInfo, setBackendPageInfo, totalCount, pageInfo } = props
	const [mouseEnter, setMouseEnter] = useState(null)

	const paginationRange = usePagination({ currentPage: pageInfo?.pageNo, totalCount, siblingCount: 1, pageSize: pageInfo?.pageSize })

	let lastPage = paginationRange[paginationRange?.length - 1]
	const firstPageIndex = 1
	return (
		<div className='FlexRowBox CursorPointer'>
			<div
				className={`PaginationItemArrow ${pageInfo.pageNo === firstPageIndex && `Disabled`}`}
				onClick={() => {
					pageInfo?.pageNo > firstPageIndex && setPageInfo((prev) => ({ ...prev, pageNo: prev?.pageNo - 1 }))
				}}
			>
				<LeftOutlined />
			</div>
			{paginationRange.map((pageNumber) => {
				// prettier-ignore
				if ([LeftDOTS].includes(pageNumber)) {
					return (
						<div
							className='PaginationItemDots'
							onMouseEnter={() => setMouseEnter(LeftDOTS)}
							onMouseLeave={() => setMouseEnter(null)}
							onClick={() => {
								setPageInfo((prev) => ({ ...prev, pageNo: pageInfo.pageNo - 5 < firstPageIndex ? firstPageIndex : pageInfo.pageNo - 5 }))
							}}
						>
							{mouseEnter === LeftDOTS ? <DoubleLeftOutlined style={{ fontSize: 'small' }} /> : <EllipsisOutlined />}
						</div>
					)
				}
				if ([RightDOTS].includes(pageNumber)) {
					return (
						<div
							className='PaginationItemDots'
							onMouseEnter={() => setMouseEnter(RightDOTS)}
							onMouseLeave={() => setMouseEnter(null)}
							onClick={() => {
								setPageInfo((prev) => ({ ...prev, pageNo: pageInfo.pageNo + 5 > lastPage ? lastPage : pageInfo.pageNo + 5 }))
							}}
						>
							{mouseEnter === RightDOTS ? <DoubleRightOutlined style={{ fontSize: 'small' }} /> : <EllipsisOutlined />}
						</div>
					)
				}
				return (
					<div
						className={`PaginationItem ${pageInfo.pageNo === pageNumber && `Selected`}`}
						onClick={() => setPageInfo((prev) => ({ ...prev, pageNo: pageNumber }))}
					>
						{pageNumber}
					</div>
				)
			})}
			<div
				className='PaginationItemArrow'
				onClick={() => {
					// prettier-ignore
					(pageInfo?.pageNo === lastPage || !lastPage) && setBackendPageInfo((prev) => ({ ...prev, pageNo: prev.pageNo + 1 }));
					pageInfo?.pageNo === lastPage
						? setPageInfo((prev) => ({ ...prev, pageNo: lastPage }))
						: !lastPage
						? setPageInfo((prev) => ({ ...prev, pageNo: 1 }))
						: setPageInfo((prev) => ({ ...prev, pageNo: prev?.pageNo + 1 }))
				}}
			>
				<RightOutlined />
			</div>
			<Dropdown
				menu={{
					items: Object.keys(PageSizes).map((size) => {
						return { label: `${size} / Page`, key: size }
					}),
					selectable: true,
					defaultSelectedKeys: [pageInfo.pageSize?.toString()],
					onClick: (event) => {
						setPageInfo({ pageNo: 1, pageSize: parseInt(event?.key) })
					},
				}}
				trigger={['click']}
				className='CursorPointer HalfMarginLeft QuarterPadding CenterAlign'
			>
				<div className='FlexRow PHBackground HalfPadding'>
					<p className='BoldFont HalfMarginLeftRight'>{pageInfo?.pageSize.toString() + ' / Page'}</p>
					<Icon path={mdiChevronUp} size={1} className='PrimaryColor' />
				</div>
			</Dropdown>
		</div>
	)
}

export default Pagination
