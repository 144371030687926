// REACT
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// DESIGN
import BridgelinxLogo from '../logo.png'
import { Form, Button, Spin, Result, Alert } from 'antd'
import { LoadingOutlined, LockTwoTone } from '@ant-design/icons'

// UTILS
import { RenderSchema } from '../utils/FormRenderer'
import { LoginFormSchema } from '../utils/FormSchemas'

// APOLLO/GRAPHQL
import { useLazyQuery } from '@apollo/client'
import { GET_PROFILE } from '../graphql/queries'

// RECOIL
import { useRecoilState, useRecoilValue } from 'recoil'
import { userAtom } from '../recoil/Atoms'
import { AuthSelector } from '../recoil/Selectors'

// FIREBASE
import { loginWithEmailPassword, sendPasswordResetLink } from '../services/FirebaseServices'

const Login = (props) => {
    // INITIALIZING VARIABLES
    const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [authorized, setAuthorized] = useState(true)
	const [forgotPassword, setForgotPassword] = useState(false)
	const [getProfile] = useLazyQuery(GET_PROFILE)
	const [alert, setAlert] = useState({ type: '', message: '' })
	const [form] = Form.useForm()
	const [userData, setUserAtom] = useRecoilState(userAtom)
    const loggedIn = useRecoilValue(AuthSelector())

    // USEEFFECT FOR CHECKING IF THE USER HAS BEEN LOGGED IN
    useEffect(() => {
        if (loggedIn) {
            navigate('/home/allOrders/import')
        }
    }, [loggedIn])

    // ON FORM SUBMISSION
	const onFinish = async (values) => {
		setLoading(true)
		const email = values.email + '@bridgelinxpk.com'
		if (!forgotPassword) {
			await loginWithEmailPassword(email, values, navigate, setUserAtom, setAlert, setAuthorized, getProfile)
		} else {
			await sendPasswordResetLink(email, setAlert)
		}
		setLoading(false)
	}

	return (
		<div className='FullWidthHeight DisplayFlex'>
			<div className='HalfWidth FullHeight PBackground'></div>
			<div className='HalfWidth FullHeight FlexColumnBox'>
				{authorized ? (
					<Form key='LoginForm' layout='vertical' className='HalfWidth' form={form} onFinish={onFinish}>
						<div className='FullWidth CenterAlign'>
							<img className='HalfWidth' src={BridgelinxLogo} alt='' style={{ marginBottom: 48 }} />
							{alert.type !== '' && (
								<Alert
									type={alert.type}
									message={alert.message}
									showIcon
									closable
									className={`BoldFont LeftAlign MarginBottom ${alert.type}`}
									afterClose={() => setAlert({ type: '', message: '' })}
								/>
							)}
						</div>
						{RenderSchema(LoginFormSchema({ forgotPassword: forgotPassword }))}
						<div className='FullWidth CenterAlign HalfPaddingTop'>
							<Form.Item>
								<Button className='Button PrimaryButton' htmlType='submit' disabled={loading} block style={{ height: 48 }}>
									{loading && <Spin className='HalfMarginRight' indicator={<LoadingOutlined style={{ color: '#808080' }} spin />} />}
									{loading ? 'Please Wait' : !forgotPassword ? 'Login' : 'Confirm'}
								</Button>
							</Form.Item>
							{!forgotPassword ? (
								<Form.Item>
									<a className='TertiaryButton' href='/#' onClick={() => setForgotPassword(true)}>
										Forgot Password?
									</a>
								</Form.Item>
							) : (
								<Form.Item>
									<a className='TertiaryButton' href='/#' onClick={() => setForgotPassword(false)}>
										Try Logging in Again?
									</a>
								</Form.Item>
							)}
						</div>
					</Form>
				) : (
					<Result
						icon={<LockTwoTone />}
						title={<p className='BoldFont NoMargin'>Not Authorized</p>}
						subTitle={
							<p className='NoMargin'>
								Sorry, your account role does not
								<br />
								allow you to access this portal.
							</p>
						}
						extra={
							<Button
								className='Button PrimaryButton'
								onClick={() => {
									form.resetFields()
									setAuthorized(true)
								}}
							>
								Go Back
							</Button>
						}
					/>
				)}
			</div>
		</div>
	)
}

export default Login
