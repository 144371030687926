// REACT
import React, { useState, useEffect } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'

// ANTD
import { Table, Layout } from 'antd'

// UTILS
import { Loader } from '../../utils/Functions'
import { PageSizes } from '../../utils/Enums'
import { ClientColumn, OrderDetailsColumn, ShipmentInfoColumn, RevenueColumn } from '../../utils/TableColumns'

// COMPONENTS
import SearchHeader from '../layout/SearchHeader'
import Pagination from '../utility/Pagination'
import FallBackUI from '../errors/FallBackUI'

// RECOIL
import { useRecoilValue } from 'recoil'
import { corporatesAtom } from '../../recoil/Atoms'
import { DynamicTableSelector } from '../../recoil/Selectors'

// HOOKS
import useFetchQuery from '../../hooks/useFetchQuery'

// ERROR BOUNDARY
import { ErrorBoundary } from 'react-error-boundary'

const { Content, Footer } = Layout

const UnassignedOrdersTable = () => {
    const { selectedTab, collection } = useOutletContext()

	const [keyword, setKeyword] = useState('')
	const [pageInfo, setPageInfo] = useState({ pageNo: 1, pageSize: 10 })
	const [searchData, setSearchData] = useState({ data: [], collection: '' })
	const [backendPageInfo, setBackendPageInfo] = useState({ pageNo: 1, pageSize: PageSizes[pageInfo.pageSize].backend })

	const corporates = useRecoilValue(corporatesAtom)
	const { totalCount, collectionData } = useRecoilValue(
		DynamicTableSelector({
			segment: selectedTab,
			pageNo: pageInfo?.pageNo,
			pageSize: pageInfo?.pageSize,
			status: 'Order Confirmed',
			state: 'unassigned',
			keyword: keyword,
			filterProperty: ['orderNumber', 'documentNumber', 'containerNumber'],
		})
	)

	useFetchQuery({ segment: 'Corporates', state: 'normal', subscription: true })
	const queryResult = useFetchQuery({
		segment: selectedTab,
		backendPageInfo,
		state: 'unassigned',
		subscription: true,
		variables: { segment: selectedTab, status: 'Order Confirmed' },
		subscriptionVariables: { status: ['Order Confirmed', 'Order Ongoing', 'Order Cancelled'], type: selectedTab },
	})

	useEffect(() => {
		setSearchData({ data: collectionData, collection: collection })
	}, [collectionData]) // eslint-disable-line

	useEffect(() => {
		setBackendPageInfo({ pageNo: 1, pageSize: PageSizes[pageInfo.pageSize].backend })
	}, [pageInfo.pageSize]) // eslint-disable-line

	useEffect(() => {
		setKeyword('')
		setPageInfo({ pageNo: 1, pageSize: 10 })
		setBackendPageInfo({ pageNo: 1, pageSize: 50 })
	}, [selectedTab, collection]) // eslint-disable-line

	return (
		<Layout className='WBackground'>
			<SearchHeader keyword={keyword} setKeyword={setKeyword} placeholder='Search by Order, BL or Container Number' selectedTab={selectedTab} />
			{queryResult?.loading ? (
				<Content className='FullHeight FlexRowBox'>
					<Loader />
				</Content>
			) : (
				<Content>
					<ErrorBoundary
						fallbackRender={({ error, resetErrorBoundary }) => <FallBackUI component={'Table'} error={error} resetErrorBoundary={resetErrorBoundary} />}
					>
						<Table
							columns={[
								OrderDetailsColumn,
								ClientColumn({ corporates }),
								...(['Import', 'Export'].includes(selectedTab) ? [ShipmentInfoColumn] : []),
								RevenueColumn,
							]}
							dataSource={searchData.data}
							rowKey={(record) => record._id}
							scroll={{ y: 'calc(100% - 35px)' }}
							pagination={false}
							className='MainTable'
							size='small'
							bordered
						/>
					</ErrorBoundary>
					<Footer className='FooterPadding'>
						<Pagination setPageInfo={setPageInfo} setBackendPageInfo={setBackendPageInfo} totalCount={totalCount} pageInfo={pageInfo}></Pagination>
					</Footer>
				</Content>
			)}
            <Outlet
                context={{
                    type: 'createOrder',
                }}
            />
		</Layout>
	)
}

export default UnassignedOrdersTable