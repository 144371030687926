import React from 'react'

import { RenderSchema } from '../../utils/FormRenderer'
import { DoIssuanceSchema, DocumentInfoSchema, DocumentUpload } from '../../utils/FormSchemas'

import Sprite from '../../assets/icons/Sprite.svg'

const ShipmentDetails = (props) => {
	const { segment, documentId, shippingLines, terminals, action, setLoading, form } = props

	return (
		<>
			<p className='GrayColor HalfMarginBottom'>Shipment Information</p>
			<div className='WBackground Border BorderRadius' style={{ marginBottom: 48 }}>
				{segment === 'Import' && (
					<div className='FlexColumn GHBackground BorderBottom' style={{ minHeight: 48, padding: '12px 24px 0px' }}>
						{RenderSchema(
							DoIssuanceSchema({ action, terminals: terminals?.map((terminal) => ({ formattedAddress: terminal?.formattedAddress, _id: terminal?._id })) })
						)}
					</div>
				)}
				<div className='BorderBottom' style={{ padding: '12px 24px 0px' }}>
					{RenderSchema(DocumentInfoSchema({ segment, shippingLines }))}
				</div>
				<div className='FlexColumnBox PaddingExceptBottom'>
					<svg className='MarginBottom' style={{ width: 100, height: 90 }}>
						<use href={Sprite + '#Upload'} />
					</svg>
					<p className='BoldFont'>Upload {segment === 'Import' ? 'Bill of Lading' : 'Container Release Order (CRO)'} Document</p>
					<p className='GrayColor MarginBottom'>
						{segment === 'Import' ? 'Bill of Lading' : 'Container Release Order'} will help us arrange requried vehicles for you
					</p>
					<div className='FullWidth FlexColumnBox Upload'>{RenderSchema(DocumentUpload({ segment, documentId, setLoading, form }))}</div>
				</div>
			</div>
		</>
	)
}

export default ShipmentDetails
