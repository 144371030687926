import React from 'react'

import { FormRender, RenderSchema } from '../../utils/FormRenderer'
import { FreightDetailsSchema, EditFreightDetailsSchema } from '../../utils/FormSchemas'

const FreightDetails = (props) => {
	const { action, lotOrder, lotAssignedWeight, form } = props

	return action === 'create' ? (
		<>
			<p className='GrayColor HalfMarginBottom'>Freight Information</p>
			<div className='WBackground Padding Border BorderRadius' style={{ marginBottom: 48 }}>
				{RenderSchema(FreightDetailsSchema({ action, lotOrder, lotAssignedWeight }))}
			</div>
		</>
	) : action === 'edit' && lotOrder ? (
		<>
			<p className='GrayColor HalfMarginBottom'>Freight Information</p>
			<div className='WBackground Padding Border BorderRadius' style={{ marginBottom: 48 }}>
				{RenderSchema(EditFreightDetailsSchema({ action, lotOrder, form }))}
				<div className='FlexRow'>
					<div className='QuarterPadding'>
						{FormRender({
							type: 'number',
							label: 'Total Cargo Weight',
							name: ['shipmentInfo', 'freightWeight'],
							inputType: 'number',
							required: true,
							message: 'Cargo weight is required.',
							minValue: lotAssignedWeight,
							addonAfter: 'Tons',
						})}
					</div>
					<div className='QuarterPadding FontSize20'>
						<span className='QuarterPadding'> - </span> <span className='BoldFont'>{lotAssignedWeight?.toFixed(2)}</span>{' '}
						<span className='Italic FontSize12'>Tons Assigned</span> <span className='QuarterPadding'> = </span>{' '}
						<span className='BoldFont'>{(form?.getFieldValue(['shipmentInfo', 'freightWeight']) - lotAssignedWeight)?.toFixed(2)}</span>{' '}
						<span className='Italic FontSize12'>Tons Remaining</span>
					</div>
				</div>
			</div>
		</>
	) : (
		action === 'edit' &&
		!lotOrder && (
			<>
				<p className='GrayColor HalfMarginBottom'>Freight Information</p>
				<div className='WBackground Padding Border BorderRadius' style={{ marginBottom: 48 }}>
					{RenderSchema(EditFreightDetailsSchema({ action, lotOrder, form }))}
					<div className='FlexRow'>
						<div className='QuarterPadding'>
							{FormRender({
								type: 'number',
								label: 'Price',
								name: ['pricingInfo', 'finalizedRate'],
								inputType: 'number',
								required: true,
								message: 'Price is required.',
								minValue: 1,
								addonAfter: 'Per Vehicle',
							})}
						</div>
						<div className='QuarterPadding FontSize20'>
							<span className='QuarterPadding Italic FontSize16'> Total: </span>
							<span className='BoldFont'>
								{form?.getFieldValue(['specialRequests', 'numberVehicles']) * form?.getFieldValue(['pricingInfo', 'finalizedRate']) || 0}
							</span>{' '}
							<span className='Italic FontSize12'>PKR</span>
						</div>
					</div>
				</div>
			</>
		)
	)
}

export default FreightDetails
