import { Layout } from 'antd'
import Icon from '@mdi/react'
import { mdiRestart, mdiAlertCircle } from '@mdi/js'

const enviorment = process.env.REACT_APP_PROJECT_ID
const { Content } = Layout

const FallBackUI = (props) => {
	const { error, resetErrorBoundary, component } = props
	const errorCode = error?.networkError?.result?.errors?.[0]?.extensions?.code
	const errorMessage = error?.networkError?.result?.errors?.[0]?.message || error?.message
	const errorTrace = error?.networkError?.result?.errors?.[0]?.extensions?.exception?.stacktrace?.join('\n') || error?.stack
	console.log('##############################################################################')
	console.log('error', { error })
	console.log('errorCode', errorCode)
	console.log('errorMessage', errorMessage)
	console.log('errorTrace', errorTrace)
	console.log('##############################################################################')
	return (
		<Content className='FlexColumnBox Padding MarginTopError'>
			<Icon path={mdiAlertCircle} size={7} color='#dd2222' className='HalfPadding' />
			{enviorment === 'bridgelinx-dev' ? (
				<>
					{errorCode && <div className='BoldFont FontSize20 HalfPadding'>{errorCode}</div>}
					<div className='BoldFont FontSize16 QuarterPadding'>An Error Occured In The {component} Component</div>
					{errorMessage && <div className='FlexColumn FontSize16 QuarterPadding'>{errorMessage}</div>}
				</>
			) : (
				<>
					<div className='BoldFont FontSize20 HalfPadding'>An Error Has Occured</div>
					<div className='BoldFont FontSize16 QuarterPadding'>Please Contact The BridgeLinx Product Team</div>
					{errorMessage && <div className='FlexColumn FontSize16 QuarterPadding'>{errorMessage}</div>}
				</>
			)}
			<div className='ErrorButton CursorPointer FlexRow HalfPadding Margin' onClick={resetErrorBoundary}>
				<Icon path={mdiRestart} size={1.5} />
				Try Again
			</div>
		</Content>
	)
}

export default FallBackUI
