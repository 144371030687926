import React from 'react'
import { Divider } from 'antd'
import { RenderSchema } from '../../utils/FormRenderer'
import { BulkVehicleSchema } from '../../utils/FormSchemas'

const BulkSubOrderDetails = (props) => {
	const { action, form, lotOrder } = props
	const vehicleSpecifics = form?.getFieldValue(['vehicleSpecifics'])
	const bulkVehicleCounter = vehicleSpecifics?.length
	return (
		<>
			<p className='GrayColor HalfMarginBottom'>Add Vehicle Details</p>
			<div className='WBackground Border BorderRadius' style={{ marginBottom: 48 }}>
				{[...Array(bulkVehicleCounter)].map((_, index, array) => (
					<div className='PaddingExceptBottom'>
						{action === 'edit' && <div className='BoldFont FontSize16 QuarterPaddingBottom'>{vehicleSpecifics?.[index]?.subOrderNumber}</div>}
						{RenderSchema(BulkVehicleSchema({ action, form, index, lotOrder }))}
						{array.length > 0 && index + 1 < array.length && <Divider className='NoMargin' dashed />}
					</div>
				))}
			</div>
		</>
	)
}

export default BulkSubOrderDetails
