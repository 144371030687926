// REACT
import React, { useState, useMemo, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

// ANTD
import { Table, Layout } from 'antd'

// UTILS
import { pathLocation, Loader } from '../../utils/Functions'
import { ClientNameColumn, ClientCodeColumn } from '../../utils/TableColumns'

// COMPONENTS
import SearchHeader from '../../components/layout/SearchHeader'
import FallBackUI from '../../components/errors/FallBackUI'

// RECOIL
import { useRecoilValue } from 'recoil'

import { DynamicTableSelector } from '../../recoil/Selectors'

// HOOKS
import useFetchQuery from '../../hooks/useFetchQuery'

// ERROR BOUNDARY
import { ErrorBoundary } from 'react-error-boundary'

const { Content } = Layout

const Corporates = (props) => {
	const {pathname} = useLocation()
	const collection = useMemo(() => pathLocation(pathname, 2), [pathname])
    const navigate = useNavigate()

	const [keyword, setKeyword] = useState('')
	const [searchData, setSearchData] = useState({ data: [], collection: '' })

	useFetchQuery({ segment: 'Corporates', state: 'normal', subscription: true })
	useFetchQuery({ segment: 'CorporateLocations', state: 'normal' })

	const queryResult = useFetchQuery({ segment: 'Regions', state: 'normal' })

	const { collectionData } = useRecoilValue(
		DynamicTableSelector({
			segment: 'Corporates',
			state: 'normal',
			keyword: keyword,
			filterProperty: ['businessName', 'businessCode'],
		})
	)

	useEffect(() => {
		setSearchData({ data: collectionData, collection: collection })
	}, [collectionData]) // eslint-disable-line

	return (
		<Layout>
			<SearchHeader setKeyword={setKeyword} placeholder='Search by Business Code or Business Name' collection={collection} {...props} />
			{queryResult?.loading ? (
				<Content className='FullHeight FlexRowBox'>
					<Loader />
				</Content>
			) : (
				<Content>
					<ErrorBoundary
						fallbackRender={({ error, resetErrorBoundary }) => <FallBackUI component={'Table'} error={error} resetErrorBoundary={resetErrorBoundary} />}
					>
						<Table
							columns={[ClientNameColumn, ClientCodeColumn]}
							dataSource={searchData.data}
							rowKey={(record) => record._id}
							onRow={(record) => ({
								onClick: () => navigate(`/home/corporates/${record.businessCode}/edit`, { state: { record, action: 'edit' } }),
							})}
							scroll={{ y: 'calc(100% - 40px)' }}
							pagination={true}
							className='FullHeight'
							size='small'
							bordered
						/>
					</ErrorBoundary>
				</Content>
			)}
		</Layout>
	)
}

export default Corporates
