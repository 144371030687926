import moment from 'moment'
import { groupByProperty, formatContacts, formatAddresses } from './Functions'

/* ============================================================================================= */
/* ============================ PREPARE INITIAL VALUES (NEW ORDER) ============================= */
/* ============================================================================================= */
export const getNewOrderInitialValues = (businessCode, segment, ports, action) => {
	const initialData = {}
	const terminal = businessCode?.terminalsCounter && Object.entries(businessCode?.terminalsCounter)?.sort(([, a], [, b]) => b - a)?.[0]?.[0]
	initialData.portAddress = {
		formattedAddress: ports[terminal]?.formattedAddress || ports?.KICT?.formattedAddress,
		additionalDetails: terminal || ports?.KICT?.additionalDetails,
		_id: ports[terminal]?._id || ports?.KICT?._id,
	}
	initialData.loadingAddresses = formatAddresses(businessCode?.CorporateLocations, action)
		?.map((corpLocation) => ({ ...corpLocation.Location, contacts: corpLocation.contacts }))
		?.sort((a, b) => b.loadingFrequency - a.loadingFrequency)
	initialData.dropoffAddresses = formatAddresses(businessCode?.CorporateLocations, action)
		?.map((corpLocation) => ({ ...corpLocation.Location, contacts: corpLocation.contacts }))
		?.sort((a, b) => b.dropoffFrequency - a.dropoffFrequency)
	initialData.clearingAgents = formatContacts(businessCode?.clearingAgents, action) || [{ name: '', phoneNumber: '' }]
	return {
		clearingAgents: initialData.clearingAgents,
		containerCounter: { xl20ft: 0, xl40ft: 0, xl40ftHC: 0, xl40ftOT: 0, other: 0 },
		loading: segment === 'Import' ? initialData.portAddress : initialData.loadingAddresses,
		dropoff: segment === 'Export' ? initialData.portAddress : initialData.dropoffAddresses,
		freightType: businessCode?.freightType?.[0],
	}
}

/* ============================================================================================= */
/* ============================ PREPARE INITIAL VALUES (OLD ORDER) ============================= */
/* ============================================================================================= */
export const getPendingOrderInitialValues = (order, action) => {
	// console.log({ selectorValues: order })
	const { specialRequests, type } = order
	const { counter, containerInfo, containersSpecifics } = preparePendingContainerInfo({
		subOrders: order?.subOrders,
		vehicleInfos: order?.vehicleInfos,
		lotVehicleInfo: order?.vehicleInfo,
		specialRequests,
		type,
	})
	const firstContainerInfo = Object.values(containerInfo)?.[0]?.[0]
	const freeDays = moment(order?.freeDays)?.diff(moment(order?.loadingDT), 'days')
	const lotFinalizedRate = Object?.values(containerInfo)?.flat()?.[0]?.finalizedRate
	const terminalContainerLocation = firstContainerInfo?.OrderLocations?.filter((value) =>
		['Empty Container Dropoff', 'Empty Container Pickup']?.includes(value?.locationType)
	)?.[0]
	const clientCommittedDeliveryDate = firstContainerInfo?.clientCommittedDeliveryDate
	const clientCommittedReturnDeadline = firstContainerInfo?.committedReturnDeadline
	const eirSubmissionDeadline = firstContainerInfo?.eir?.submissionDeadline
	return {
		...(['Import', 'Export']?.includes(type) && {
			specialRequests,
			...(type === 'Import'
				? {
						flatbedInfo: order?.flatBedInfos,
						...(specialRequests?.orderCategory === 'Destuffing' && {
							ratePerFlatbed:
								order?.flatBedInfos?.reduce((totalRate, curr) => {
									return parseFloat(curr?.finalizedRate) + totalRate
								}, 0) / order?.specialRequests?.numberFlatbeds,
						}),
						...(specialRequests?.movementType === 'ImportShifting' && {
							pricingInfo: {
								finalizedRate: order?.subOrders?.[0]?.finalizedRate,
								finalizedRateUnit: order?.subOrders?.[0]?.finalizedRateUnit,
							},
							vehicleDetails: order?.vehicleInfos?.map((vehicle) => ({
								...vehicle,
								minWeight: vehicle?.weightRange?.split('-')?.[0],
								maxWeight: vehicle?.weightRange?.split('-')?.[1],
							})),
						}),
				  }
				: { vesselCutoffDT: order?.vesselCutoffDT ? moment(order?.vesselCutoffDT) : moment() }),
			containerInfo: containerInfo,
			containersSpecifics: containersSpecifics,
			freightType: firstContainerInfo?.freightType,
			grossFreightWeight: order?.subOrders
				?.map((value) => value?.freightWeight || 0)
				?.reduce((partialSum, freightWeight) => parseFloat(partialSum) + parseFloat(freightWeight), 0),
			shipmentInfo: {
				...(order?.document && { document: [{ uid: 1, name: 'Shipment Document', status: 'done', url: order?.document }] }),
				documentNumber: order?.documentNumber,
				shippingLine: order?.shipmentInfo?.shippingLine,
			},
			terminal:
				type === 'Export'
					? [
							{
								_id: terminalContainerLocation?.Location?._id,
								contacts: terminalContainerLocation?.contacts,
							},
					  ]
					: {
							_id: terminalContainerLocation?.Location?._id,
							freeDays,
					  },
			clearingAgents: order?.clearingAgents?.map((agent) => ({ ...agent, phoneNumber: agent?.phoneNumber?.replace('+92', '') })),
		}),

		// long haul specific fields
		...(type === 'LongHaul' && {
			vehicleSpecifics: Object?.values(containerInfo)
				?.flat()
				?.map((vehicle) => ({
					_id: vehicle?._id,
					subOrderNumber: vehicle?.subOrderNumber,
					finalizedRate: vehicle?.finalizedRate,
					freightWeight: vehicle?.shipmentInfo?.freightWeight,
					freightType: vehicle?.shipmentInfo?.freightType,
					vehicleType: vehicle?.vehicleType,
					vehicleSubType: vehicle?.vehicleSubType,
				})),
			lotAssignedWeight: order?.lotAssignedWeight,
			vehicleInfo: order?.vehicleInfo,
			pricingInfo: { finalizedRate: lotFinalizedRate, vehicleCost: order?.vehicleInfos?.[0]?.quotedRate },
			shipmentInfo: {
				freightType: order?.shipmentInfo?.freightType,
				freightWeight: order?.shipmentInfo?.freightWeight,
			},
			specialRequests: {
				...specialRequests,
				numberVehicles: parseInt(specialRequests?.numberVehicles),
				movementCutoffDT: order?.expectedMovementDeadline ? moment(order?.expectedMovementDeadline) : null,
			},
		}),
		containerCounter: counter,
		supplyQuote: order?.supplyQuote,
		loadingDT: order?.loadingDT ? moment(order?.loadingDT) : moment(),
		...(type === 'Import' && {
			clientCommittedReturnDeadline: clientCommittedReturnDeadline ? moment(clientCommittedReturnDeadline) : null,
		}),
		...(type === 'Import' && {
			eirSubmissionDeadline: eirSubmissionDeadline ? moment(eirSubmissionDeadline) : null,
		}),
		...(type === 'Export' &&
			clientCommittedDeliveryDate && {
				clientCommittedDeliveryDate: moment(clientCommittedDeliveryDate),
			}),
		dropoff: type === 'Export' ? prepareLocation(order.dropoff, order?.loadingDT, action)?.[0] : prepareLocation(order.dropoff, order?.loadingDT, action),
		loading: type === 'Import' ? prepareLocation(order.loading, order?.loadingDT, action)?.[0] : prepareLocation(order.loading, order?.loadingDT, action),
	}
}

const prepareLocation = (locations, loadingDT, action) => {
	return Object.values(locations)?.map((location) => ({
		_id: location?.Location?._id,
		formattedAddress: location?.Location?.formattedAddress,
		additionalDetails: location?.Location?.additionalDetails,
		...(location?.contacts && { contacts: formatContacts(location?.contacts, action) }),
		...(loadingDT && { loadingDT: moment(loadingDT, 'x') }),
	}))
}

const preparePendingContainerInfo = ({ subOrders, vehicleInfos, lotVehicleInfo, specialRequests, type }) => {
	const groupedContainerInfos = groupByProperty(subOrders, 'dimension')
	let counter = { xl20ft: 0, xl40ft: 0, xl40ftHC: 0, xl40ftOT: 0, other: 0 }
	let containersSpecifics = {
		xl20ft: { quantity: 0 },
		xl40ft: { quantity: 0 },
		xl40ftHC: { quantity: 0 },
		xl40ftOT: { quantity: 0 },
		other: { quantity: 0 },
	}
	if (type !== 'LongHaul') {
		Object.entries(groupedContainerInfos)?.forEach(([key, value]) => (counter[key] = value.length))
		Object.entries(groupedContainerInfos)?.forEach(([key, value]) => {
			return (containersSpecifics[key] = {
				quantity: value?.length,
				doCost: value?.[0]?.quotedDoCost,
				labourCost: value?.[0]?.quotedLabourCost,
				shiftingCost: value?.[0]?.quotedShiftingCost,
			})
		})
	} else {
		counter[lotVehicleInfo?.vehicleType] = parseInt(specialRequests?.numberVehicles)
	}

	if (!['Shifting', 'Destuffing']?.includes(specialRequests?.orderCategory)) {
		let modifiedVehicleInfos = vehicleInfos?.map((vehicle) => ({
			...vehicle,
			type: vehicle?.vehicleType?.split('-')[1],
		}))
		const groupedVehicleInfos = groupByProperty(modifiedVehicleInfos, 'type')
		Object.entries(groupedVehicleInfos)?.forEach(([key, value]) => {
			return (containersSpecifics[key] = {
				...containersSpecifics[key],
				vehicleCost: value?.[0]?.quotedRate,
				...(key === 'xl2020ft' && { quantity: value?.[0]?.quantity }),
			})
		})
	}
	return { containersSpecifics, counter, containerInfo: groupedContainerInfos }
}

// const prepareDouble20Container = (containerInfo) => (
//     {
//     ...containerInfo?.[0],
//     id: containerInfo?.[0]?.id,
//     containerNumber: containerInfo?.[0]?.containerNumber,
//     dimension: 'xl2020ft',
//     freightType:  containerInfo?.[0]?.freightType,
//     freightWeight:  containerInfo?.[0]?.freightWeight || '',
//     finalizedRate:  containerInfo?.[0]?.finalizedRate,
//     containerNumber2: containerInfo?.[1]?.containerNumber,
//     freightType2: containerInfo?.[1]?.freightType,
//     freightWeight2: containerInfo?.[1]?.freightWeight || '',
//     finalizedRate2: containerInfo?.[1]?.finalizedRate,
//     id2: containerInfo?.[1]?.id
// })

// const prepareOrderCategory = (specialRequests) => {
//     if (specialRequests?.orderCategory==='normal' && !specialRequests?.localMovement) {
//         return 'directSeal'
//     } else if (specialRequests?.orderCategory==='normal' && specialRequests?.localMovement) {
//         return 'localMovement'
//     } else return specialRequests?.orderCategory
// }
