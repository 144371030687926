import React from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

// APOLLO
import { ApolloProvider } from '@apollo/client'
import { client } from './graphql/apolloConfig'

//ERROR BOUNDARY
import { ErrorBoundary } from 'react-error-boundary'
import FallBackUI from './components/errors/FallBackUI'

ReactDOM.render(
	<React.StrictMode>
		<RecoilRoot>
			<ApolloProvider client={client}>
				<ErrorBoundary
					fallbackRender={({ error, resetErrorBoundary }) => <FallBackUI component={'App'} error={error} resetErrorBoundary={resetErrorBoundary} />}
				>
					<App />
				</ErrorBoundary>
			</ApolloProvider>
		</RecoilRoot>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
