// REACT
import React, { useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useLocation, useNavigate } from 'react-router-dom'

// DESIGN
import { Layout, Input, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

// COMPONENTS
import FallBackUI from '../errors/FallBackUI'

// UTILS
import { pathLocation } from '../../utils/Functions'

const SearchHeader = (props) => {
	const { keyword, setKeyword, placeholder, selectedTab, className, inputClassName } = props
    const { pathname } = useLocation()
    const collection = useMemo(() => pathLocation(pathname, 2), [pathname])
    const segment = useMemo(() => pathLocation(pathname, 3), [pathname])

    const navigate = useNavigate()

	return (
		<Layout.Header className={`NoPadding FlexRowSpace ${className || ''}`}>
			<ErrorBoundary
				resetKeys={[selectedTab]}
				fallbackRender={({ error, resetErrorBoundary }) => <FallBackUI component={'Search'} error={error} resetErrorBoundary={resetErrorBoundary} />}
			>
				<Input
					className={`SearchField PaddingLeftRight HalfMargin ${inputClassName || 'HalfWidth'}`}
					prefix={<SearchOutlined className='HalfMarginRight' />}
					value={keyword}
					onChange={(e) => setKeyword(e.target.value)}
					placeholder={placeholder}
				/>
				{collection === 'corporates' && (
					<Button className='Button PrimaryButton MarginRight' onClick={() => navigate('/home/corporates/add', { state: { action: 'add' } })}>
						Add New Client
					</Button>
				)}
                {['pending', 'unassignedOrders', 'allOrders'].includes(collection) &&
                    <Button className='Button PrimaryButton MarginRight' onClick={() => navigate(`/home/${collection}/${segment}/newOrder`)}>
                        Create Order
                    </Button>
                }
			</ErrorBoundary>
		</Layout.Header>
	)
}

export default SearchHeader
