// REACT
import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

// RECOIL
import { userAtom } from './recoil/Atoms'
import { useRecoilState } from 'recoil'

//COMPONENTS
import Login from './pages/Login'
import UnassignedOrders from './pages/UnassignedOrders'
import NewOrder from './pages/NewOrder'
import PendingOrdersTable from './pages/PendingOrdersTable'
import Corporates from './pages/corporates/Corporates'
import Corporate from './pages/corporates/Corporate'
import DynamicModal from './components/DynamicModal'
import Metrics from './pages/Metrics'
import AllOrders from './pages/AllOrders'
import AllOrdersTable from './components/tables/AllOrdersTable'
import Regions from './pages/Regions'
import ConnectClientsCorporates from './pages/ConnectClientCorporates'
import HomePage from './pages/HomePage'
import UnassignedOrdersTable from './components/tables/UnassignedOrdersTable'

// ERROR BOUNDARY
import { ErrorBoundary } from 'react-error-boundary'
import FallBackUI from './components/errors/FallBackUI'

// DESIGN
import './App.css'
import './tw.css'

const App = () => {
	const [user, setUserAtom] = useRecoilState(userAtom)

	useEffect(() => {
		const userData = JSON.parse(localStorage.getItem('user'))
		if (userData) {
			setUserAtom(userData)
		}
	}, []) // eslint-disable-line

	return (
        <Router>
            <Routes>
                <Route path='auth' element={<Login/>} />
                <Route path='home' element={<HomePage/>}>
                    <Route path='allOrders' element={<AllOrders/>}>
                        <Route path=':segment' element={<AllOrdersTable/>}>
                            <Route path='newOrder' element={<DynamicModal />} />
                        </Route>
                    </Route>
                    <Route path='pending/import' element={<PendingOrdersTable/>}>
                        <Route path='newOrder' element={<DynamicModal />} />
                    </Route>
                    <Route path='orders/:segment/create' element={<NewOrder />} />
                    <Route path='orders/:segment/:id/edit' element={<NewOrder />} />
                    <Route path='unassignedOrders' element={<UnassignedOrders />}>
                        <Route path=':segment' element={<UnassignedOrdersTable />}>
                            <Route path='newOrder' element={<DynamicModal />} />
                        </Route>
                    </Route>
                    <Route path='corporates' element={<Corporates/>} />
                    <Route path='corporates/:businessCode/:action' element={<Corporate />} />
                    <Route path='corporates/:action' element={<Corporate />} />
                    <Route path='connectClientsCorporates' element={<ConnectClientsCorporates/>}>
                        <Route path='connect' element={<DynamicModal />} />
                    </Route>
                    <Route path='metrics' element={<Metrics/>} />
                    <Route path='regions' element={<Regions/>}>
                        <Route path='create' element={<DynamicModal />} />
                    </Route>
                </Route>
                <Route path='*' element={ <Navigate to='/auth' replace /> } />
            </Routes>
        </Router>
	)
}

export default App
