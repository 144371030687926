import { gql } from '@apollo/client'

export const SHORT_USER_FIELDS = gql`
	fragment ShortUserFields on User {
		firstName
		lastName
		email
		phoneNumber
	}
`
export const USER_FIELDS = gql`
	fragment UserFields on User {
		_id
		firstName
		lastName
		phoneNumber
		email
		cnic
		UserType {
			_id
			userType
		}
	}
`

export const CONTACT_FIELDS = gql`
	fragment ContactFields on Contacts {
        _id
		name
		phoneNumber
	}
`

export const REGION_FIELDS = gql`
	fragment RegionFields on Region {
		_id
		name
		ParentRegion {
			_id
			name
		}
	}
`

export const LOCATION_FIELDS = gql`
	fragment LocationFields on Location {
		_id
		type
		formattedAddress
		additionalDetails
	}
`

export const CORPORATE_FIELDS = gql`
	fragment CorporateFields on Corporate {
		_id
		businessCode
		businessName
		freightType
		creationEmail
	}
`
export const VEHICLE_INFOS = gql`
	fragment VehicleInfoFields on VehicleInfo {
		_id
		quantity
		quotedRate
		vehicleType
		weightRange
		quotedRateUnit
	}
`

export const INVOICING_DETAILS = gql`
	fragment InvoicingDetailsFields on InvocingDetails {
		NTN
		STRN
		billingAddress
		contactNumber
		invoicingTerms
		contactPerson
		name
		paymentTerms
		nomenclature {
			branchCode
			clientCode
		}
	}
`

export const CORPORATE_LOCATIONS_FIELDS = gql`
	${LOCATION_FIELDS}
	${REGION_FIELDS}
	${CONTACT_FIELDS}
	fragment CorporateLocationsFields on CorporateLocations {
		_id
		loadingFrequency
		dropoffFrequency
		Location {
			...LocationFields
			coordinates {
				lat
				lng
			}
			Region {
				...RegionFields
			}
		}
		contacts {
			...ContactFields
		}
	}
`

export const CLIENT_FIELDS = gql`
	${USER_FIELDS}
	${CORPORATE_FIELDS}
	fragment ClientFields on Client {
		_id
		isKAM
		User {
			...UserFields
		}
		Corporate {
			...CorporateFields
		}
	}
`

export const ORDER_LOCATION_FIELDS = gql`
	${CONTACT_FIELDS}
	${LOCATION_FIELDS}
	fragment OrderLocationFields on OrderLocation {
		_id
		steps
		locationType
		Location {
			...LocationFields
		}
		contacts {
			...ContactFields
		}
	}
`

export const SHIPMENT_INFO_FIELDS = gql`
	fragment ShipmentInfoFields on ShipmentInfo {
		freightCount
		freightType
		freightWeight
		estimatedWeight
		estimatedCount
	}
`

export const IMPORT_EXPORT_PREFERENCES_FIELDS = gql`
	fragment ImportExportPreferencesFields on SpecialRequest {
		gstInclusive
		movementType
		doIssuance
		numberFlatbeds
		otherRequests
		fastLane
		refNumber
		partialMatch
	}
`

export const LONGHAUL_PREFERENCES_FIELDS = gql`
	fragment LongHaulPreferencesFields on LongHaulSpecialRequest {
		gstInclusive
		lotOrder
		numberVehicles
		otherRequests
		refNumber
	}
`

export const SUB_IMPORT_FIELDS = gql`
	${ORDER_LOCATION_FIELDS}
	fragment SubImportFields on ChildImport {
		_id
		loadingDT
        committedReturnDeadline
		subOrderNumber
		status
		freeDays
		containerNumber
		dimension
		freightType
		freightWeight
		numberOfUnits
		finalizedRate
		finalizedRateUnit
		quotedDoCost
		quotedLabourCost
		quotedShiftingCost
		OrderLocations {
			...OrderLocationFields
		}
        eir {
            submissionDeadline
        }
	}
`
export const SUB_EXPORT_FIELDS = gql`
	${ORDER_LOCATION_FIELDS}
	fragment SubExportFields on ChildExport {
		_id
		finalizedRate
		loadingDT
        clientCommittedDeliveryDate
		subOrderNumber
		status
		containerNumber
		dimension
		freightType
		freightWeight
		finalizedRateUnit
		OrderLocations {
			...OrderLocationFields
		}
	}
`

export const SUB_LONGHAUL_FIELDS = gql`
	${ORDER_LOCATION_FIELDS}
	${SHIPMENT_INFO_FIELDS}
	fragment SubLongHaulFields on ChildLongHaul {
		_id
		loadingDT
		finalizedRate
		finalizedRateUnit
		subOrderNumber
		status
		vehicleType
		vehicleSubType
		OrderLocations {
			...OrderLocationFields
		}
		shipmentInfo {
			...ShipmentInfoFields
		}
	}
`

export const PARENT_IMPORT_FIELDS = gql`
	${CONTACT_FIELDS}
	${SUB_IMPORT_FIELDS}
	${IMPORT_EXPORT_PREFERENCES_FIELDS}
	fragment ParentImportFields on ParentImport {
		_id
		orderNumber
		status
		document
		documentNumber
		shippingLine
		flatBedInfos {
			_id
			freightType
			freightWeight
			finalizedRate
		}
		clearingAgents {
			...ContactFields
		}
		SubImports {
			...SubImportFields
		}
		preferences {
			...ImportExportPreferencesFields
		}
	}
`

export const PARENT_EXPORT_FIELDS = gql`
	${SUB_EXPORT_FIELDS}
	${IMPORT_EXPORT_PREFERENCES_FIELDS}
	fragment ParentExportFields on ParentExport {
		_id
		document
		documentNumber
		shippingLine
		orderNumber
		status
		vesselCutoffDT
		clearingAgents {
			...ContactFields
		}
		SubExports {
			...SubExportFields
		}
		preferences {
			...ImportExportPreferencesFields
		}
	}
`

export const PARENT_LONGHAUL_FIELDS = gql`
	${SUB_LONGHAUL_FIELDS}
	${LONGHAUL_PREFERENCES_FIELDS}
	${SHIPMENT_INFO_FIELDS}
	fragment ParentLongHaulFields on ParentLongHaul {
		_id
		orderNumber
		status
		expectedMovementDeadline
		shipmentInfo {
			...ShipmentInfoFields
		}
		SubLongHauls {
			...SubLongHaulFields
		}
		preferences {
			...LongHaulPreferencesFields
		}
	}
`

export const ORDER_FIELDS = gql`
	${PARENT_IMPORT_FIELDS}
	${PARENT_EXPORT_FIELDS}
	${PARENT_LONGHAUL_FIELDS}
	${CORPORATE_FIELDS}
	${VEHICLE_INFOS}
	fragment OrderFields on Order {
		_id
		type
		businessCode
		clientEmails
		createdAt
		supplyQuote {
			amount
			source
		}
		VehicleInfos {
			...VehicleInfoFields
		}
		Corporate {
			...CorporateFields
		}
		ParentOrder {
			ParentImport @include(if: $Import) {
				...ParentImportFields
			}
			ParentExport @include(if: $Export) {
				...ParentExportFields
			}
			ParentLongHaul @include(if: $LongHaul) {
				...ParentLongHaulFields
			}
		}
	}
`
