import React from 'react'
import { Form, Radio } from 'antd'

import { VehicleTypeEnumLongHaul } from '../../utils/Enums'
import Sprite from '../../assets/icons/Sprite.svg'

const VehicleType = (props) => {
	return (
		<Form.Item
			key={['vehicleInfo', 'vehicleType']}
			name={['vehicleInfo', 'vehicleType']}
			label={'Select Vehicle Type'}
			className='mb-6'
			rules={[{ required: true, message: 'Please select a vehicle type' }]}
			shouldUpdate={(prevState, currentState) => prevState?.specialRequests?.lotOrder !== currentState?.specialRequests?.lotOrder}
		>
			<Radio.Group buttonStyle='solid'>
				{Object.values(VehicleTypeEnumLongHaul)?.map((option) => (
					<Radio.Button key={option?.key} value={option?.key} className={'!h-max w-1/3 child:w-full'}>
						<div className={`flex w-full flex-col ${option?.key === 'other' ? 'items-center' : 'items-start'}`}>
							<p className='mb-2'>{option?.text}</p>
							<div className='flex h-full w-full flex-row items-center justify-center'>
								<svg className='!text-center' style={{ height: '49px', width: '132px' }} fill={'#6D6E70'}>
									<use href={Sprite + option?.icon} />
								</svg>
							</div>
						</div>
					</Radio.Button>
				))}
			</Radio.Group>
		</Form.Item>
	)
}

export default VehicleType
