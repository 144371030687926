// REACT
import { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

// DESIGN
import { Button, Layout, List } from 'antd'
import { mdiChevronRight, mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'

// COMPONENTS
import SearchHeader from '../components/layout/SearchHeader'

// GRAPHQL
import useFetchQuery from '../hooks/useFetchQuery'

// RECOIL
import { useRecoilValue } from 'recoil'
import { ClientsSelector } from '../recoil/Selectors'

const ColumnHeader = ({ title, actionTitle, showSearch, keyword, setKeyword, className }) => {
    const navigate = useNavigate()

	return (
		<Layout.Header className={`FlexColumn NoPadding ${className || ''}`} style={{ position: '' }}>
			<div className='GHBackground Height48 GrayShadeColor FontSize16 FlexRow PaddingLeft'>{title}</div>

			{actionTitle && (
				<Button className='FlexRow HalfPaddingLeft HalfPaddingRight PaddingTop PaddingBottom FullWidthButton ' onClick={() => navigate('/home/connectClientsCorporates/connect') }>
					<Icon path={mdiPlus} size={1} className='PrimaryColor HalfMarginRight' />
					<p className='FontBold PrimaryColor'>{actionTitle}</p>
				</Button>
			)}
			{showSearch && (
				<SearchHeader inputClassName='FullWidth' keyword={keyword} setKeyword={setKeyword} collection='regions' placeholder={`Search ${title}`} />
			)}
		</Layout.Header>
	)
}

const ConnectClientsCorporates = (props) => {
	const { loading: corporateLoading } = useFetchQuery({ segment: 'Corporates', state: 'normal', subscription: true })
	const { loading: clientLoading } = useFetchQuery({ segment: 'Clients', state: 'normal', variables: { email_NOT: null } })
	const [keyword, setKeyword] = useState('')
	const [currentClient, setCurrentClient] = useState('')
	const [corporateKeyword, setCorporateKeyword] = useState('')
	const [modalData, setModalData] = useState({ visible: false, type: null })

	const { clients, selectedClientCorporates } = useRecoilValue(ClientsSelector({ currentClient, keyword, corporateKeyword }))

	useEffect(() => {
		if (currentClient && clients?.filter((client) => client?._id === currentClient?._id)?.length === 0) {
			return setCurrentClient(null)
		}
	}, [keyword]) // eslint-disable-line

	return (
		<>
			<Layout className='Padding'>
				<Layout className='BorderRadiusMed WBackground'>
					<div className='FlexColumn FullHeight'>
						<SearchHeader
							className='BorderRadiusTopSmall MarginRight'
							keyword={keyword}
							setKeyword={setKeyword}
							collection='regions'
							placeholder='Search Clients'
						/>
						<Layout.Content className='FlexRow FullWidth BorderRadiusBottomMedium'>
							<div className='HalfWidth FullHeight BorderRight FlexColumn'>
								<ColumnHeader title='Clients' />
								<div className='OverflowScroll'>
									<List
										loading={corporateLoading || clientLoading}
										dataSource={clients}
										renderItem={(item) => (
											<div
												key={item._id}
												className={`HalfPadding ListButton CursorPointer FlexRowBetween ${item._id === currentClient?._id ? 'TBackground' : ''}`}
												onClick={(e) => setCurrentClient(item)}
											>
												<p className=''>{item?.User?.email}</p>
												<Icon path={mdiChevronRight} size={1} className=' HalfMarginRight' />
											</div>
										)}
									/>
								</div>
							</div>
							<div className='HalfWidth FullHeight FlexColumn'>
								<ColumnHeader
									title={
										!currentClient ? `Select a Client to View Corporates` : `Corporates Of ${currentClient?.User?.firstName + ' ' + currentClient?.User?.lastName}`
									}
									actionTitle='Connect Corporate'
									showSearch={true}
									keyword={corporateKeyword}
									setKeyword={setCorporateKeyword}
									className='BorderTop'
								/>
								<div className='OverflowScroll'>
									<List
										loading={corporateLoading || clientLoading}
										dataSource={selectedClientCorporates}
										renderItem={(item) => (
											<div key={item._id} className='HalfPadding '>
												<p className=''>{item.businessCode}</p>
											</div>
										)}
									/>
								</div>
							</div>
						</Layout.Content>
					</div>
				</Layout>
			</Layout>
            <Outlet
                context={{
                    type: 'connectClientCorporates',
                    data: { currentClient, clients, selectedClientCorporates },
                }}
            />
		</>
	)
}

export default ConnectClientsCorporates
