import { useMutation } from '@apollo/client'
import { GraphQlEnum } from '../utils/Enums'
import { useEffect } from 'react'
import * as AllAtoms from '../recoil/Atoms'
import { useSetRecoilState } from 'recoil'

const useMutate = (props) => {
	const { segment, action, querySegment, state } = props
	const mutation = GraphQlEnum[segment]?.[action]?.function
	const mutationInputFunction = GraphQlEnum[segment]?.[action]?.functionInput
	const mutationOutputFunction = GraphQlEnum[segment]?.[action]?.functionOutput

	const atomName = GraphQlEnum[querySegment]?.atoms[state]
	const segmentAtom = AllAtoms[atomName]
	const query = GraphQlEnum[querySegment]?.fetch?.queryName
	const getDataOutput = GraphQlEnum[querySegment]?.fetch?.queryOutput
	const setOrdersAtom = useSetRecoilState(segmentAtom || AllAtoms.dummyAtom)

	const [mutateFunction, { loading, data, error }] = useMutation(mutation, {
		...(query && state ? { refetchQueries: [{ query: query }] } : {}),
	})
	useEffect(() => {
		if (querySegment && state) {
			if (error) {
				throw error
			}
			// console.log({ data })
			data && setOrdersAtom(data?.[getDataOutput])
		}
	}, [data, error]) //eslint-disable-line

	return { mutateFunction, mutationInputFunction, mutationOutputFunction, loading }
}

export default useMutate
