import { OrderCategoryEnum, SegmentsEnum } from './Enums'
import { mdiTrayArrowUp } from '@mdi/js'
import moment from 'moment'

/* ============================================================================================== */
/* ========================================= LOGIN PAGE ========================================= */
/* ============================================================================================== */
export const LoginFormSchema = (props) => {
	const Schema = [
		{
			type: 'input',
			label: 'Email',
			name: 'email',
			inputType: 'text',
			required: true,
			message: 'Email is required.',
			addonAfter: '@bridgelinxpk.com',
		},
	]
	if (!props.forgotPassword) {
		Schema.push({
			type: 'password',
			label: 'Password',
			name: 'password',
			inputType: 'password',
			required: true,
			message: 'Password is required.',
		})
	}
	return Schema
}

/* ============================================================================================== */
/* ======================================== IMPORT ORDER ======================================== */
/* ============================================================================================== */
export const CreateOrderSchema = (props) => {
	return [
		{
			type: 'radioGroup',
			label: 'Order Type',
			name: 'segment',
			options: [
				{ key: 'Import', label: 'Import Order' },
				{ key: 'Export', label: 'Export Order' },
				{ key: 'LongHaul', label: 'Bulk Order' },
			],
			initialValue: 'Import',
			valueProperty: 'key',
			displayProperty: 'label',
			button: true,
			required: true,
			message: 'Order segment is required.',
		},
		{
			type: 'select',
			label: 'Select Client',
			name: 'businessCode',
			options: props?.corporates,
			valueProperty: 'businessCode',
			displayProperty: 'businessNameCode',
			required: true,
			message: 'Client is required.',
			showSearch: true,
		},
	]
}

export const PortsSchema = (props) => {
	const { type } = props
	return [
		{
			type: 'radioGroup',
			label: 'Select Port',
			name: [type, 'formattedAddress'],
			options: ['Karachi Port', 'Port Qasim'],
			initialValue: 'Karachi Port',
			button: true,
			formReference: props.form,
			clearData: { [type]: { additionalDetails: undefined } },
			required: true,
			message: 'Port is required.',
		},
		{
			type: 'input',
			name: [type, '_id'],
			hidden: true,
		},
		{
			type: 'dependency',
			independent: [type, 'formattedAddress'],
			condition: ['Karachi Port'],
			successSchema: [
				{
					type: 'select',
					label: 'Select Terminal',
					name: [type, 'additionalDetails'],
					options: ['KICT', 'PICT', 'SAPT'],
					initialValue: 'KICT',
					allowClear: true,
					required: true,
				},
			],
			failureSchema: [
				{
					type: 'select',
					label: 'Select Terminal',
					name: [type, 'additionalDetails'],
					options: ['QICT'],
					initialValue: 'QICT',
					allowClear: true,
					required: true,
				},
			],
		},
		{
			title: 'Clearing Agents',
			fields: [
				{
					type: 'dynamicFields',
					label: 'Clearing Agent',
					name: 'clearingAgents',
					initialValue: [{ name: '', phoneNumber: '' }],
					minEntries: 1,
					fields: [
						{
							type: 'input',
							name: '_id',
							hidden: true,
						},
						{
							type: 'input',
							label: 'Contact Name',
							name: 'name',
							required: true,
							message: 'Contact name is required.',
							inputType: 'text',
						},
						{
							type: 'phoneNumber',
							label: 'Phone Number',
							name: 'phoneNumber',
							required: true,
							message: 'Phone number is required.',
							addonBefore: '+92',
						},
					],
				},
			],
		},
	]
}

export const LocationsSchema = (props) => {
	const { form, type, index, options, terminals, action } = props || {}
	return [
		{
			type: 'recentSelect',
			label: 'Address',
			name: type === 'terminal' ? [type, index, '_id'] : [type, index, 'formattedAddress'],
			required: true,
			message: 'Address is required.',
			options: (type === 'terminal' ? terminals : options) || [],
			valueProperty: '_id',
			displayProperty: 'formattedAddress',
			setField: [type, index],
			form: form,
			showSearch: true,
			allowClear: true,
		},
		{
			type: 'input',
			name: [type, index, '_id'],
			hidden: true,
		},
		type !== 'terminal' && {
			type: 'input',
			label: 'Additional Details',
			name: [type, index, 'additionalDetails'],
			inputType: 'text',
			...(action === 'edit' && { disabled: true }),
		},
		{
			title: type === 'terminal' ? 'Emergency Contact Information' : 'POC (Point of Contact) Information',
			name: [type, index, 'contacts'],
			fields: [
				{
					type: 'dynamicFields',
					label: type === 'terminal' ? 'Emergency Contact' : 'Contact',
					name: [type, index, 'contacts'],
					initialValue: type !== 'terminal' && [{ name: '', phoneNumber: '' }],
					minEntries: type === 'terminal' ? 0 : 1,
					fields: [
						{
							type: 'input',
							label: 'Contact Name',
							name: 'name',
							required: true,
							message: 'Contact name is required.',
							inputType: 'text',
						},
						{
							type: 'phoneNumber',
							label: 'Phone Number',
							name: 'phoneNumber',
							required: true,
							message: 'Phone number is required.',
							addonBefore: '+92',
						},
						{
							type: 'input',
							name: '_id',
							hidden: true,
						},
					],
				},
			],
		},
	]
}

export const DoIssuanceSchema = (props) => {
	const { terminals, action } = props || {}

	return [
		{
			type: 'checkbox',
			label: 'I want Bridgelinx to handle Delivery Order (DO) Issuance.',
			name: ['specialRequests', 'doIssuance'],
			initialValue: true,
			className: 'HalfMarginBottom',
			disabled: action === 'edit',
		},
		{
			type: 'dependency',
			independent: ['specialRequests', 'doIssuance'],
			condition: [false],
			successSchema: [
				[
					{
						type: 'input',
						label: 'Free Days',
						name: ['terminal', 'freeDays'],
						inputType: 'number',
						addonAfter: 'Days',
						disabled: action === 'edit',
					},
					{
						type: 'select',
						label: 'Empty Container Terminal',
						name: ['terminal', '_id'],
						options: terminals,
						displayProperty: 'formattedAddress',
						valueProperty: '_id',
						showSearch: true,
						allowClear: true,
						disabled: action === 'edit',
					},
				],
				// {
				//     type: 'dependency',
				//     independent: ['terminal', 'formattedAddress'],
				//     condition: ['Pending'],
				//     successSchema: [
				//         {
				//             type: 'input',
				//             label: 'Terminal/Yard Name',
				//             name: ['terminal', 'additionalDetails'],
				//             required: true,
				//             message: 'Terminal name/location is required.',
				//         }
				//     ],
				//     failureSchema: []
				// }
			],
			failureSchema: [],
		},
		{
			type: 'dateTime',
			label: 'Loading Date',
			name: 'loadingDT',
			required: true,
			message: 'Loading date is required.',
			format: 'DD-MM-YYYY',
			showTime: false,
			disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
			initialValue: moment(),
		},
	]
}

export const DocumentInfoSchema = (props) => {
	const { segment, shippingLines } = props || {}
	return [
		[
			{
				type: 'input',
				label: `Enter ${segment === 'Import' ? 'BL' : 'CRO'} Number`,
				name: ['shipmentInfo', 'documentNumber'],
				required: true,
				message: `${segment === 'Import' ? 'BL' : 'CRO'} Number is required.`,
			},
			{
				type: 'select',
				label: 'Select Shipping Line',
				name: ['shipmentInfo', 'shippingLine'],
				options: shippingLines,
				showSearch: true,
				required: true,
				message: 'Shipping Line is required.',
			},
		],
		...(segment === 'Export'
			? [
					[
						{
							type: 'dateTime',
							label: 'Loading Date',
							name: 'loadingDT',
							required: true,
							message: 'Loading date is required.',
							format: 'DD-MM-YYYY',
							showTime: false,
							disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
							initialValue: moment(),
						},
						{
							type: 'dateTime',
							label: 'Vessel Cut-off Time',
							name: 'vesselCutoffDT',
							required: true,
							message: 'Vessel cutt-off time is required.',
							format: 'DD-MM-YYYY HH:mm',
							disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
							initialValue: moment(),
						},
					],
					[
						{
							type: 'dateTime',
							label: 'Client Committed Delivery Date',
							name: 'clientCommittedDeliveryDate',
							required: false,
							message: 'Delivery date is required.',
							format: 'DD-MM-YYYY',
							disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
							initialValue: null,
						},
						{},
					],
			  ]
			: segment === 'Import'
			? [
					[
						{
							type: 'dateTime',
							label: 'Client Committed Return Deadline',
							name: 'clientCommittedReturnDeadline',
							required: false,
							message: 'Return deadline is required.',
							format: 'DD-MM-YYYY',
							disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
							initialValue: null,
						},
						{
							type: 'dateTime',
							label: 'EIR Submission Deadline',
							name: 'eirSubmissionDeadline',
							required: false,
							message: 'EIR submission deadline is required.',
							format: 'DD-MM-YYYY',
							disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
							initialValue: null,
						},
					],
			  ]
			: []),
	]
}

export const DocumentUpload = (props) => {
	const { segment, documentId, setLoading } = props || {}
	return [
		{
			type: 'upload',
			name: ['shipmentInfo', 'document'],
			uploadType: 'button',
			uploadLink: documentId,
			collection: SegmentsEnum[segment]?.collectionName,
			icon: mdiTrayArrowUp,
			maxCount: 1,
			setLoading,
		},
	]
}

export const OrderTypeSchema = (props) => {
	const { action } = props || {}
	return [
		{
			type: 'radioGroup',
			label: 'Please specify order nature',
			name: ['specialRequests', 'orderCategory'],
			options: Object.values(OrderCategoryEnum),
			valueProperty: 'key',
			displayProperty: 'text',
			button: true,
			initialValue: 'Import',
			disabled: action === 'edit',
		},
	]
}

export const DestuffingOrderTypeSchema = (props) => {
	const { action, containerCounter } = props
	return [
		{
			type: 'dependency',
			independent: ['specialRequests', 'orderCategory'],
			condition: ['Destuffing'],
			successSchema: [
				[
					{
						type: 'number',
						label: 'Number of Flatbeds',
						name: ['specialRequests', 'numberFlatbeds'],
						inputType: 'number',
						required: true,
						message: 'Number of flatbeds are required.',
						minValue: 1,
						maxValue: Object?.values(containerCounter)?.reduce((prev, curr) => (curr = curr + prev), 0) - 1,
						disabled: action === 'edit',
					},
					{
						type: 'number',
						label: 'Rate Per Flatbed',
						name: 'ratePerFlatbed',
						inputType: 'number',
						minValue: 1,
					},
				],
			],
			failureSchema: [],
		},
	]
}

export const ShiftingRateCalculationSchema = () => {
	return [
		{
			type: 'radioGroup',
			name: ['pricingInfo', 'finalizedRateUnit'],
			initialValue: 'Tonne',
			options: [
				{ key: 'Tonne', label: 'Per Tonne' },
				{ key: 'Container', label: 'Per Container' },
				{ key: 'Vehicle', label: 'Per Vehicle' },
			],
			valueProperty: 'key',
			displayProperty: 'label',
			required: true,
			message: 'Rate Unit is required.',
		},
	]
}

export const VehiclesCardUnitSchema = () => {
	return [
		{
			type: 'radioGroup',
			name: ['pricingInfo', 'quotedRateUnit'],
			initialValue: 'Tonne',
			options: [
				{ key: 'Tonne', label: 'Per Tonne' },
				{ key: 'Vehicle', label: 'Per Vehicle' },
			],
			valueProperty: 'key',
			displayProperty: 'label',
			required: true,
			message: 'Rate Unit is required.',
		},
	]
}

export const LotRateCalculationSchema = () => {
	return [
		[
			{
				type: 'number',
				label: 'Rate Per Tonne - Supply',
				name: ['pricingInfo', 'vehicleCost'],
				inputType: 'number',
				required: true,
				message: 'Supply Quote is required.',
				minValue: 1,
				addonAfter: 'PKR / Tonne',
			},
			{},
		],
		// [
		// {
		// 	type: 'number',
		// 	label: 'Revenue Per Tonne',
		// 	name: ['pricingInfo', 'finalizedRate'],
		// 	inputType: 'number',
		// 	required: true,
		// 	message: 'Revenue is required.',
		// 	minValue: 1,
		// 	addonAfter: 'PKR / Tonne',
		// },
		// 	{},
		// ],
	]
}

export const CargoInfoSchema = (props) => {
	const { segment, form } = props || {}
	return [
		[
			{
				type: 'input',
				label: 'Enter Cargo Type',
				name: 'freightType',
				required: true,
				message: 'Cargo type is required.',
			},
			{
				type: 'number',
				disabled: true,
				label: 'Gross Cargo Weight',
				name: 'grossFreightWeight',
				inputType: 'number',
				required: segment === 'LongHaul',
				message: 'Gross weight is required.',
				initialValue: form?.getFieldValue(['grossFreightWeight']) ?? null,
				addonAfter: 'Tons',
			},
		],
	]
}

export const ContainerSchema = (props) => {
	const { segment, form, action, containers, dimension, index, SDorder } = props
	const schema = []
	const container = [
		{
			type: 'input',
			label: '_id',
			name: ['containerInfo', dimension, index, '_id'],
			hidden: true,
		},
		{
			type: 'input',
			label: 'subOrderNumber',
			name: ['containerInfo', dimension, index, 'subOrderNumber'],
			hidden: true,
		},
		...(containers?.length > 0
			? [
					{
						type: 'recentSelect',
						label: 'Container Number',
						name: ['containerInfo', dimension, index, 'containerNumber'],
						required: true,
						message: 'Container Number is required.',
						options: containers,
						valueProperty: 'containerNumber',
						displayProperty: 'containerNumber',
						setField: ['containerInfo', dimension, index],
						form: form,
						showSearch: true,
					},
			  ]
			: [
					{
						type: 'input',
						label: 'Container Number',
						name: ['containerInfo', dimension, index, 'containerNumber'],
						hidden: segment === 'Export' && action === 'create',
						required: segment === 'Import' && action === 'edit',
						message: 'Container Number is required.',
					},
			  ]),
		{
			type: 'number',
			label: 'Cargo Weight (Tons)',
			name: ['containerInfo', dimension, index, 'freightWeight'],
			inputType: 'number',
			minValue: 0,
			initialValue: form?.getFieldValue(['containersSpecifics', dimension, 'freightWeight']),
			required: action === 'edit',
			message: 'Freight Weight is required.',
			shouldUpdate: action === 'edit' && containers?.length > 0,
		},
		{
			type: 'input',
			name: ['containerInfo', dimension, index, 'freightType'],
			initialValue: form?.getFieldValue('freightType'),
			hidden: !(segment === 'Export' && action === 'create'),
			label: 'Cargo Type',
			message: 'Cargo type is required.',
		},
	]
	const containerDimension = [
		{
			type: 'input',
			name: ['containerInfo', dimension, index, 'dimension'],
			initialValue: dimension,
			hidden: true,
		},
	]
	const numberOfUnits = [
		{
			type: 'number',
			label: 'Number of Units',
			name: ['containerInfo', dimension, index, 'numberOfUnits'],
			inputType: 'number',
			required: action === 'edit',
			message: 'Number of units are required.',
			minValue: 1,
			initialValue: form?.getFieldValue(['containersSpecifics', dimension, 'numberOfUnits']),
		},
	]
	const pricing = [
		{
			type: 'number',
			label: 'Price',
			name: ['containerInfo', dimension, index, 'finalizedRate'],
			inputType: 'number',
			hidden: action === 'create',
			required: action === 'edit',
			message: 'Price is required.',
			minValue: 1,
			initialValue: form?.getFieldValue(['containersSpecifics', dimension, 'finalizedRate']),
		},
	]

	schema.push([...container, ...(SDorder ? numberOfUnits : pricing), ...containerDimension])
	return schema
}

export const FlatbedsSchema = (props) => {
	const { form, index, action } = props
	return [
		[
			{
				type: 'input',
				label: '_id',
				name: ['flatbedInfo', index, '_id'],
				hidden: true,
			},
			{
				type: 'number',
				label: 'Cargo Weight (Tons)',
				name: ['flatbedInfo', index, 'freightWeight'],
				inputType: 'number',
				minValue: 0,
				required: action === 'edit',
				message: 'Cargo weight is required.',
			},
			{
				type: 'input',
				label: 'Cargo Type',
				name: ['flatbedInfo', index, 'freightType'],
				required: true,
				message: 'Cargo type is required.',
				initialValue: form?.getFieldValue('freightType'),
			},
			{
				type: 'number',
				label: 'Price',
				name: ['flatbedInfo', index, 'finalizedRate'],
				inputType: 'number',
				required: true,
				message: 'Price is required.',
				minValue: 1,
				initialValue: form?.getFieldValue('ratePerFlatbed'),
			},
		],
	]
}

export const EmailSchema = (props) => {
	const { clientEmail } = props || {}
	return [
		{
			type: 'emails',
			name: 'clientEmail',
			required: true,
			message: 'Atleast 1 email is required.',
			placeholder: 'Select email addresses or type to add new',
			options: clientEmail,
			mode: 'tags',
			tokenSeparators: [',', ' '],
			initialValue: clientEmail,
		},
	]
}
export const EditFreightDetailsSchema = (props) => {
	const { lotOrder } = props
	return lotOrder
		? [
				[
					{
						type: 'radioGroup',
						label: 'Select Cargo Type',
						name: ['shipmentInfo', 'freightType'],
						options: ['Cement', 'Coal', 'Seed', 'Steel', 'Other'],
						button: true,
						required: true,
						message: 'Cargo type is required.',
					},
				],
		  ]
		: [
				[
					{
						type: 'input',
						label: 'Comodity',
						name: ['shipmentInfo', 'freightType'],
						required: true,
						message: 'Comodity is required.',
						placeholder: 'Add Comodity',
					},
					{},
				],
		  ]
}
export const FreightDetailsSchema = (props) => {
	const { action, lotOrder, lotAssignedWeight } = props
	return lotOrder
		? [
				[
					{
						type: 'radioGroup',
						label: 'Select Cargo Type',
						name: ['shipmentInfo', 'freightType'],
						options: ['Cement', 'Coal', 'Seed', 'Steel', 'Other'],
						button: true,
						required: true,
						message: 'Cargo type is required.',
					},
				],
				[
					{
						type: 'number',
						label: 'Cargo Weight',
						name: ['shipmentInfo', 'freightWeight'],
						inputType: 'number',
						required: true,
						message: 'Cargo weight is required.',
						minValue: action === 'edit' && lotOrder ? lotAssignedWeight : 1,
						addonAfter: 'Tons',
					},
					{
						type: 'dateTime',
						label: 'Expected Loading Date',
						name: 'loadingDT',
						required: true,
						message: 'Loading date is required.',
						format: 'DD-MM-YYYY',
						showTime: false,
						disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
						initialValue: moment(),
					},
				],
				[
					[
						{
							type: 'number',
							label: 'Price',
							name: ['pricingInfo', 'finalizedRate'],
							inputType: 'number',
							required: true,
							message: 'Price is required.',
							minValue: 1,
							addonAfter: 'Per Ton',
						},
						{},
					],
				],
		  ]
		: [
				[
					{
						type: 'input',
						label: 'Comodity',
						name: ['shipmentInfo', 'freightType'],
						required: true,
						message: 'Comodity is required.',
						placeholder: 'Add Comodity',
					},
					{
						type: 'dateTime',
						label: 'Expected Loading Date',
						name: 'loadingDT',
						required: true,
						message: 'Loading date is required.',
						format: 'DD-MM-YYYY',
						showTime: false,
						disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
						initialValue: moment(),
					},
				],
				[
					[
						{
							type: 'number',
							label: 'Price',
							name: ['pricingInfo', 'finalizedRate'],
							inputType: 'number',
							required: true,
							message: 'Price is required.',
							minValue: 1,
							addonAfter: 'Per Vehicle',
						},
						{},
					],
				],
		  ]
}

export const BulkVehicleSchema = (props) => {
	const { form, index, action, lotOrder } = props
	return [
		[
			{
				type: 'input',
				name: ['vehicleSpecifics', index, '_id'],
				hidden: true,
			},
			{
				type: 'number',
				label: 'Cargo Weight (Tons)',
				name: ['vehicleSpecifics', index, 'freightWeight'],
				inputType: 'number',
				disabled: lotOrder,
				minValue: 0,
				required: action === 'edit',
				message: 'Cargo weight is required.',
				// initialValue: form?.getFieldValue(['weightPerContainer', dimension])
			},
			{
				type: 'input',
				label: 'Cargo Type',
				name: ['vehicleSpecifics', index, 'freightType'],
				required: true,
				message: 'Cargo type is required.',
				initialValue: form?.getFieldValue('freightType'),
			},
			{
				type: 'input',
				label: 'Finalized Rate',
				inputType: 'number',
				name: ['vehicleSpecifics', index, 'finalizedRate'],
				hidden: !lotOrder || index === 0,
				required: true,
				message: 'Finalized Rate is required.',
				// initialValue: form?.getFieldValue('freightType'),
			},
			{
				type: 'input',
				label: 'Price',
				hidden: true,
				// required: true,
				message: 'Price is required',
				name: ['vehicleSpecifics', index, 'finalizedRate'],
				disabled: true,
			},
		],
	]
}

export const VehicleInfoSchema = (props) => {
	const { action } = props
	return [
		{
			type: 'radioGroup',
			label: 'Is this a lot order?',
			name: ['specialRequests', 'lotOrder'],
			options: [
				{ key: true, label: 'Yes' },
				{ key: false, label: 'No' },
			],
			disabled: action === 'edit',
			valueProperty: 'key',
			displayProperty: 'label',
			button: true,
			required: true,
			initialValue: true,
			message: 'Cargo type is required.',
		},
		// {
		// 	type: 'dependency',
		// 	independent: ['specialRequests', 'lotOrder'],
		// 	condition: [true],
		// 	successSchema: [
		// 		{
		// 			type: 'dateTime',
		// 			label: 'Expected Movement Deadline',
		// 			name: ['specialRequests', 'movementCutoffDT'],
		// 			required: true,
		// 			message: 'Expected movement deadline is required.',
		// 			format: 'DD-MM-YYYY',
		// 			showTime: false,
		// 			disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
		// 		},
		// 		...(action === 'create'
		// 			? [
		// 					{
		// 						type: 'number',
		// 						label: 'Price',
		// 						name: ['pricingInfo', 'finalizedRate'],
		// 						inputType: 'number',
		// 						required: true,
		// 						message: 'Price is required.',
		// 						minValue: 1,
		// 						addonAfter: 'Per Tonne',
		// 					},
		// 			  ]
		// 			: [
		// 					{
		// 						type: 'dateTime',
		// 						label: 'Expected Loading Date',
		// 						name: 'loadingDT',
		// 						required: true,
		// 						message: 'Loading date is required.',
		// 						format: 'DD-MM-YYYY',
		// 						showTime: false,
		// 						disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
		// 						initialValue: moment(),
		// 					},
		// 			  ]),
		// 	],
		// 	failureSchema: [],
		// },
		// {
		// 	type: 'dependency',
		// 	independent: ['specialRequests', 'lotOrder'],
		// 	condition: [false],
		// 	successSchema: [
		// 		{
		// 			type: 'number',
		// 			label: 'Number of Flatbeds',
		// 			name: ['specialRequests', 'numberVehicles'],
		// 			inputType: 'number',
		// 			required: true,
		// 			disabled: action === 'edit',
		// 			message: 'Number of vehicles are required.',
		// 			minValue: 1,
		// 		},
		// 		...(action === 'create'
		// 			? [
		// 					{
		// 						type: 'number',
		// 						label: 'Price',
		// 						name: ['pricingInfo', 'finalizedRate'],
		// 						inputType: 'number',
		// 						required: true,
		// 						message: 'Price is required.',
		// 						minValue: 1,
		// 						addonAfter: 'Per Vehicle',
		// 					},
		// 			  ]
		// 			: [
		// 					{
		// 						type: 'dateTime',
		// 						label: 'Expected Loading Date',
		// 						name: 'loadingDT',
		// 						required: true,
		// 						message: 'Loading date is required.',
		// 						format: 'DD-MM-YYYY',
		// 						showTime: false,
		// 						disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
		// 						initialValue: moment(),
		// 					},
		// 			  ]),
		// 	],
		// 	failureSchema: [],
		// },
		// ...(action === 'create'
		// 	? [
		// 			{
		// 				type: 'radioGroup',
		// 				label: 'Select Vehicle Category',
		// 				name: ['vehicleInfo', 'vehicleType'],
		// 				options: Object.values(VehicleTypeEnum),
		// 				valueProperty: 'key',
		// 				displayProperty: 'text',
		// 				button: true,
		// 				required: true,
		// 				message: 'Vehicle category is required.',
		// 			},
		// 			{
		// 				type: 'dependency',
		// 				independent: ['vehicleInfo', 'vehicleType'],
		// 				condition: ['xl20ft', 'xl40ft'],
		// 				successSchema: [
		// 					{
		// 						type: 'radioGroup',
		// 						label: 'Select Vehicle Sub-category',
		// 						name: ['vehicleInfo', 'vehicleSubtype'],
		// 						options: Object.values(VehicleSubtypeEnum),
		// 						valueProperty: 'key',
		// 						displayProperty: 'text',
		// 						button: true,
		// 						required: true,
		// 						message: 'Vehicle sub-category is required.',
		// 					},
		// 				],
		// 				failureSchema: [],
		// 			},
		// 	  ]
		// 	: []),
	]
}

export const MiscellaneousSchema = (props) => {
	const { form } = props
	const orderCategory = form?.getFieldValue(['specialRequests', 'orderCategory'])
	const cost = form?.getFieldValue(['supplyQuote', 'amount'])
	const source = form?.getFieldValue(['supplyQuote', 'source'])
	return [
		[
			{
				type: 'radioGroup',
				label: 'Are the finalized rates inclusive of GST?',
				name: ['specialRequests', 'gstInclusive'],
				initialValue: true,
				options: [
					{ key: true, label: 'Yes' },
					{ key: false, label: 'No' },
				],
				valueProperty: 'key',
				displayProperty: 'label',
				button: true,
				required: true,
			},
			...(orderCategory === 'Destuffing'
				? [
						{
							type: 'radioGroup',
							label: 'Would this order be partially matched?',
							name: ['specialRequests', 'partialMatch'],
							initialValue: true,
							options: [
								{ key: true, label: 'Yes' },
								{ key: false, label: 'No' },
							],
							valueProperty: 'key',
							displayProperty: 'label',
							button: true,
							required: true,
						},
				  ]
				: []),
		],
		{
			fields: [
				{
					type: 'input',
					label: "Client's Reference Number",
					name: ['specialRequests', 'refNumber'],
				},
			],
		},
		{
			title: 'Base Rate',
			description: 'Rate used to calculate cash burn.',
			fields: [
				[
					{
						type: 'number',
						label: 'Cost',
						name: ['supplyQuote', 'amount'],
						inputType: 'number',
						minValue: 1,
						required: cost || source,
						message: 'Cost is required',
					},
					{
						type: 'select',
						label: 'Source',
						name: ['supplyQuote', 'source'],
						options: [
							{ key: 'contract', value: 'Contract' },
							{ key: 'estimation', value: 'Estimation' },
							{ key: 'supply', value: 'Supply' },
							{ key: 'slack', value: 'Slack' },
							{ key: 'other', value: 'Other' },
						],
						required: cost || source,
						message: 'Source is required',
						valueProperty: 'key',
						displayProperty: 'value',
					},
				],
			],
		},
	]
}
/* ============================================================================================== */
/* ========================================= CORPORATES ========================================= */
/* ============================================================================================== */
export const AddClientLocationSchema = (props) => {
	const { parentRegions, childRegions, location } = props
	return [
		{
			type: 'autocomplete',
			label: 'Address',
			name: 'formattedAddress',
			formReference: props?.form,
			coordinates: props?.coordinates,
			setCoordinates: props?.setCoordinates,
			required: true,
			message: 'Address is required.',
			isLoaded: props?.isLoaded,
			regions: props.regions,
		},
		{
			type: 'input',
			label: 'Mill/Location Name',
			name: 'additionalDetails',
			required: true,
			message: 'Mill name is required.',
			inputType: 'text',
		},
		[
			{
				type: 'select',
				label: 'Sub-Region/District',
				name: 'subRegion',
				options: childRegions,
				initialValue: location?.Region?.name,
				// valueProperty: '_id',
				// displayProperty: 'name',
				required: true,
				message: 'Sub-region/district is required. Contact Product Team',
				showSearch: true,
				// disabled: true,
				showArrow: true,
			},
			{
				type: 'input',
				name: 'subRegionId',
				hidden: true,
			},
			{
				type: 'select',
				label: 'Lane Region',
				name: 'parentRegion',
				options: parentRegions,
				initialValue: location?.Region?.ParentRegion?.name,
				valueProperty: '_id',
				displayProperty: 'name',
				required: true,
				showSearch: true,

				message: 'Region is required. Contact Product Team',
				// disabled: true,
				showArrow: true,
			},
		],
		[
			{
				type: 'number',
				label: 'Latitude',
				name: ['coordinates', 'lat'],
				required: true,
				message: 'Latitudes are required.',
				disabled: true,
			},
			{
				type: 'number',
				label: 'Longitude',
				name: ['coordinates', 'lng'],
				required: true,
				message: 'Longitudes are required.',
				disabled: true,
			},
		],
		{
			title: 'POC (Point of Contact) Information',
			fields: [
				{
					type: 'dynamicFields',
					label: 'Contact',
					name: 'contacts',
					initialValue: [{ name: '', phoneNumber: '' }],
					minEntries: 1,
					fields: [
						{
							type: 'input',
							name: '_id',
							hidden: true,
						},
						{
							type: 'input',
							label: 'Contact Name',
							name: 'name',
							required: true,
							message: 'Contact name is required.',
							inputType: 'text',
						},
						{
							type: 'phoneNumber',
							label: 'Phone Number',
							name: 'phoneNumber',
							required: true,
							message: 'Phone number is required.',
							addonBefore: '+92',
						},
					],
				},
			],
		},
	]
}

export const BusinessCodeSchema = (props) => {
	const { action } = props || {}
	return [
		[
			{
				type: 'input',
				label: "Client's Legal Name",
				name: 'businessName',
				required: true,
				message: "Client's name is required.",
				inputType: 'text',
				disabled: action === 'edit',
			},
			// {
			//     type: 'input',
			//     label: 'Business Code',
			//     name: 'businessCode',
			//     required: true,
			//     message: 'Business code is required.',
			//     inputType: 'text',
			//     disabled: true
			// }
		],
		// ...(action==='edit' ? [
		//     {
		//         type: 'select',
		//         label: 'Associated Companies',
		//         name: 'associatedCompanies',
		//         options: corporates,
		//         valueProperty: 'businessCode',
		//         displayProperty: 'businessNameCode',
		//         mode: 'multiple',
		//     }
		// ] : [])
	]
}

export const ClearingAgentsSchema = (props) => {
	return [
		// {
		//     type: 'select',
		//     label: 'Clearing Agent Companies',
		//     name: 'clearingAgentCompanies',
		//     options: ClearingAgentCompanies,
		//     mode: 'multiple',
		// },
		{
			title: 'Clearing Agents',
			fields: [
				{
					type: 'dynamicFields',
					label: 'Clearing Agent',
					name: 'clearingAgents',
					minEntries: 0,
					fields: [
						{
							type: 'input',
							label: 'Contact Name',
							name: 'name',
							required: true,
							message: 'Contact name is required.',
							inputType: 'text',
						},
						{
							type: 'phoneNumber',
							label: 'Phone Number',
							name: 'phoneNumber',
							required: true,
							message: 'Phone number is required.',
							addonBefore: '+92',
						},
					],
				},
			],
		},
	]
}

export const FinancialSchema = (props) => {
	return [
		{
			type: 'input',
			label: 'Invoiced To (Name)',
			name: ['invoicingDetails', 'name'],
			inputType: 'text',
		},
		{
			type: 'input',
			label: 'Billing Address',
			name: ['invoicingDetails', 'billingAddress'],
			inputType: 'textArea',
		},
		[
			{
				type: 'input',
				label: 'Contact Name (On Invoice)',
				name: ['invoicingDetails', 'contactPerson'],
				inputType: 'text',
			},
			{
				type: 'phoneNumber',
				label: 'Contact Number',
				name: ['invoicingDetails', 'contactNumber'],
				addonBefore: '+92',
			},
		],
		{
			title: 'Tax Registration Numbers',
			fields: [
				[
					{
						type: 'input',
						label: 'NTN',
						name: ['invoicingDetails', 'NTN'],
						inputType: 'text',
					},
					{
						type: 'input',
						label: 'STRN',
						name: ['invoicingDetails', 'STRN'],
						inputType: 'text',
					},
				],
			],
		},
		{
			title: 'Terms',
			fields: [
				[
					{
						type: 'input',
						label: 'Invoicing Term',
						name: ['invoicingDetails', 'invoicingTerms'],
						inputType: 'text',
					},
					{
						type: 'input',
						label: 'Payment Terms',
						name: ['invoicingDetails', 'paymentTerms'],
						inputType: 'text',
					},
				],
			],
		},
		{
			title: 'Nomenclature',
			description: "Following fields will be used in invoice nomenclature e.g 'I-22-STYL-LHR-01' where STYL is client-code and LHR is branch-code.",
			fields: [
				[
					{
						type: 'restrictedInput',
						label: 'Client Code',
						name: ['invoicingDetails', 'nomenclature', 'clientCode'],
						placeholder: '4 alphabets only e.g STYL',
						inputType: 'text',
						maxLength: 4,
						showCount: true,
						toUpperCase: true,
						charactersOnly: true,
					},
					{
						type: 'restrictedInput',
						label: 'Branch Code',
						name: ['invoicingDetails', 'nomenclature', 'branchCode'],
						placeholder: '3 alphabets only e.g LHR',
						inputType: 'text',
						maxLength: 3,
						toUpperCase: true,
						charactersOnly: true,
					},
				],
			],
		},
	]
}

export const CreateRegionSchema = (props) => {
	const { regionType, parentRegion, allParentRegions } = props
	return [
		regionType === 'child'
			? {
					type: 'select',
					label: 'Parent Region',
					name: 'parentRegion',
					required: true,
					options: allParentRegions,
					valueProperty: '_id',
					displayProperty: 'name',
					initialValue: parentRegion?._id,
			  }
			: [],
		{
			type: 'input',
			label: 'Region Name',
			name: 'name',
			required: true,
			message: 'Region name is required.',
			inputType: 'text',
		},
	]
}

export const ConnectClientsCorporatesSchema = (props) => {
	const { form, client, allClients, allCorporates } = props
	const slectedClientCorporates = allClients?.find((client) => client?._id === form?.getFieldValue(['client']))?.Corporate
	const filteredCorporates = allCorporates?.filter(
		(corporate) => !slectedClientCorporates?.find((selectedCorporate) => selectedCorporate?._id === corporate?._id)
	)
	return [
		{
			type: 'select',
			label: 'Client',
			name: 'client',
			required: true,
			options: allClients,
			valueProperty: '_id',
			displayProperty: 'email',
			message: 'Please select a Client',
			initialValue: client?._id,
			showSearch: true,
		},
		{
			type: 'select',
			label: 'Business Code',
			name: 'businessCode',
			required: true,
			options: filteredCorporates,
			valueProperty: '_id',
			displayProperty: 'businessCode',
			message: 'Please select a Business Code',
			mode: 'tags',
			showSearch: true,
			allowClear: true,
		},
	]
}
