const StatsCard = (props) => {
	const { key, title, collection, value } = props
	return (
		<div
			className='FlexRow HalfMarginRight WBackground Border BorderRadius CursorPointer'
			style={{ width: '20%', height: 60, borderColor: key === collection ? '#99CCFF' : '#E5E5E5' }}
		>
			<p
				className={`QuarterWidth FullHeight FlexColumnBox HalfPadding WBackground BoldFont ${value === 0 ? 'SuccessColor' : 'ErrorColor'}`}
				style={{ fontSize: 32, borderRadius: '5px 0px 0px 5px' }}
			>
				{value}
			</p>
			<p
				className='FullWidthHeight BoldFont FlexRow PrimaryShadeColor HalfPaddingLeft'
				style={{ borderRadius: '0px 5px 5px 0px', backgroundColor: key === collection ? '#F5FAFF' : '#FFFFFF' }}
			>
				{title}
			</p>
		</div>
	)
}

export default StatsCard
